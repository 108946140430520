// import React from "react";
// import {
//   Box,
//   Divider,
//   Grid,
//   GridItem,
//   Heading,
//   Text,
//   useColorModeValue,
//   VStack,
//   Center,
// } from "@chakra-ui/react";

// const SurahDetails = ({ audioSurahData }) => {
//   // Use the same color configurations as the App component's theme
//   const titleColor = useColorModeValue("gold", "yellow.600");
//   const textColor = useColorModeValue("black", "white"); // Corresponding to body text colors
//   const bgColor = useColorModeValue("white", "black"); // Corresponding to body background colors

//   return (
//     <Box>
//       {audioSurahData && (
//         <Box
//           p={5}
//           shadow="md"
//           borderWidth={2}
//           borderColor="gold"
//           bg={bgColor} // Use the bgColor variable here
//           mb={5}
//           rounded="md"
//         >
//           <Heading
//             as="h4"
//             size="md"
//             color={titleColor}
//             fontFamily="Courier New"
//             textAlign="center"
//             mb={4}
//             textShadow="2px 2px 4px #000000"
//           >
//             {audioSurahData.name}
//           </Heading>
//           <Divider borderColor={titleColor} />
//           <Grid
//             templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
//             gap={6}
//             justifyContent="center"
//             alignItems="center"
//             mt={4}
//           >
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Number</Text>
//                 <Text color={textColor}>{audioSurahData.number}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>English Name</Text>
//                 <Text color={textColor}>{audioSurahData.englishName}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Revelation Type</Text>
//                 <Text color={textColor}>{audioSurahData.revelationType}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>English Name Translation</Text>
//                 <Text color={textColor}>
//                   {audioSurahData.englishNameTranslation}
//                 </Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Number of Ayahs</Text>
//                 <Text color={textColor}>{audioSurahData.numberOfAyahs}</Text>
//               </VStack>
//             </GridItem>
//           </Grid>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default SurahDetails;
// import React from "react";
// import {
//   Box,
//   Divider,
//   Grid,
//   GridItem,
//   Heading,
//   Text,
//   useColorModeValue,
//   VStack,
// } from "@chakra-ui/react";

// const SurahDetails = ({ audioSurahData }) => {
//   const titleColor = useColorModeValue("gold", "yellow.600");
//   const textColor = useColorModeValue("black", "white");
//   const bgColor = useColorModeValue("white", "black");

//   return (
//     <Box>
//       {audioSurahData && (
//         <Box
//           p={5}
//           shadow="md"
//           borderWidth={2}
//           borderColor="gold"
//           bg={bgColor}
//           mb={5}
//           rounded="md"
//         >
//           <Heading
//             as="h4"
//             size="md"
//             color={titleColor}
//             fontFamily="Courier New"
//             textAlign="center"
//             mb={4}
//             textShadow="2px 2px 4px #000000"
//           >
//             {audioSurahData.name}
//           </Heading>
//           <Divider borderColor={titleColor} />
//           <Grid
//             templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
//             gap={6}
//             justifyContent="center"
//             alignItems="center"
//             mt={4}
//           >
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Number</Text>
//                 <Text color={textColor}>{audioSurahData.number}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>English Name</Text>
//                 <Text color={textColor}>{audioSurahData.englishName}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Revelation Type</Text>
//                 <Text color={textColor}>{audioSurahData.revelationType}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>English Name Translation</Text>
//                 <Text color={textColor}>
//                   {audioSurahData.englishNameTranslation}
//                 </Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Number of Ayahs</Text>
//                 <Text color={textColor}>{audioSurahData.numberOfAyahs}</Text>
//               </VStack>
//             </GridItem>
//           </Grid>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default React.memo(SurahDetails);

// import React from "react";
// import {
//   Box,
//   Divider,
//   Grid,
//   GridItem,
//   Heading,
//   Text,
//   useColorModeValue,
//   VStack,
// } from "@chakra-ui/react";

// const SurahDetails = ({ audioSurahData }) => {
//   const titleColor = useColorModeValue("gold", "yellow.600");
//   const textColor = useColorModeValue("black", "white");
//   const bgColor = useColorModeValue("gray.800", "black");

//   return (
//     <Box>
//       {audioSurahData && (
//         <Box
//           p={4}
//           shadow="md"
//           borderWidth={2}
//           borderColor="gold"
//           bg={bgColor}
//           mb={5}
//           rounded="md"
//         >
//           <Heading
//             as="h4"
//             size="md"
//             color={titleColor}
//             fontFamily="Courier New"
//             textAlign="center"
//             mb={4}
//             textShadow="2px 2px 4px #000000"
//           >
//             {audioSurahData.name}
//           </Heading>
//           <Divider borderColor={titleColor} />
//           <Grid
//             templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
//             gap={4}
//             justifyContent="center"
//             alignItems="center"
//             mt={3}
//           >
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Number</Text>
//                 <Text color={textColor}>{audioSurahData.number}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>English Name</Text>
//                 <Text color={textColor}>{audioSurahData.englishName}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Revelation Type</Text>
//                 <Text color={textColor}>{audioSurahData.revelationType}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>English Name Translation</Text>
//                 <Text color={textColor}>
//                   {audioSurahData.englishNameTranslation}
//                 </Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={textColor}>Number of Ayahs</Text>
//                 <Text color={textColor}>{audioSurahData.numberOfAyahs}</Text>
//               </VStack>
//             </GridItem>
//           </Grid>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default React.memo(SurahDetails);

// import React from "react";
// import {
//   Box,
//   Divider,
//   Grid,
//   GridItem,
//   Heading,
//   Text,
//   useColorModeValue,
//   VStack,
// } from "@chakra-ui/react";

// const SurahDetails = ({ audioSurahData }) => {
//   const titleColor = useColorModeValue("gold", "yellow.600");
//   const bgColor = useColorModeValue("gray.800", "black");
//   const whiteColor = useColorModeValue("white", "white");
//   const goldenColor = useColorModeValue("gold", "yellow.400");

//   return (
//     <Box>
//       {audioSurahData && (
//         <Box
//           p={4}
//           shadow="md"
//           borderWidth={2}
//           borderColor="gold"
//           bg={bgColor}
//           mb={5}
//           rounded="md"
//         >
//           <Heading
//             as="h4"
//             size="md"
//             color={titleColor}
//             fontFamily="Courier New"
//             textAlign="center"
//             mb={4}
//             textShadow="2px 2px 4px #000000"
//           >
//             {audioSurahData.name}
//           </Heading>
//           <Divider borderColor={titleColor} />
//           <Grid
//             templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
//             gap={4}
//             justifyContent="center"
//             alignItems="center"
//             mt={3}
//           >
//             <GridItem>
//               <VStack align="center">
//                 <Text color={goldenColor}>Number</Text>
//                 <Text color={whiteColor}>{audioSurahData.number}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={goldenColor}>English Name</Text>
//                 <Text color={whiteColor}>{audioSurahData.englishName}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={goldenColor}>Revelation Type</Text>
//                 <Text color={whiteColor}>{audioSurahData.revelationType}</Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={goldenColor}>English Name Translation</Text>
//                 <Text color={whiteColor}>
//                   {audioSurahData.englishNameTranslation}
//                 </Text>
//               </VStack>
//             </GridItem>
//             <GridItem>
//               <VStack align="center">
//                 <Text color={goldenColor}>Number of Ayahs</Text>
//                 <Text color={whiteColor}>{audioSurahData.numberOfAyahs}</Text>
//               </VStack>
//             </GridItem>
//           </Grid>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default React.memo(SurahDetails);
import React from "react";
import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

const SurahDetails = ({ audioSurahData }) => {
  const titleColor = useColorModeValue("gold", "yellow.600");
  const bgColor = useColorModeValue("gray.800", "black");
  const whiteColor = useColorModeValue("white", "white");
  const goldenColor = useColorModeValue("gold", "yellow.400");

  return (
    <Box
      position="sticky"
      top="120px"
      zIndex="9"
      maxHeight="90vh"
      overflowY="auto"
    >
      {audioSurahData && (
        <Box
          p={4}
          shadow="md"
          borderWidth={2}
          borderColor="gold"
          bg={bgColor}
          mb={5}
          rounded="md"
        >
          <Heading
            as="h4"
            size="md"
            color={titleColor}
            fontFamily="Courier New"
            textAlign="center"
            mb={4}
            textShadow="2px 2px 4px #000000"
          >
            {audioSurahData.name}
          </Heading>
          <Divider borderColor={titleColor} />
          <Grid
            templateColumns={{ base: "repeat(5, 1fr)", md: "repeat(5, 1fr)" }}
            gap={0}
            justifyContent="center"
            alignItems="center"
            mt={0}
          >
            <GridItem>
              <VStack align="center">
                <Text color={goldenColor}>Number</Text>
                <Text color={whiteColor}>{audioSurahData.number}</Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack align="center">
                <Text color={goldenColor}>English Name</Text>
                <Text color={whiteColor}>{audioSurahData.englishName}</Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack align="center">
                <Text color={goldenColor}>Revelation Type</Text>
                <Text color={whiteColor}>{audioSurahData.revelationType}</Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack align="center">
                <Text color={goldenColor}>English Name Translation</Text>
                <Text color={whiteColor}>
                  {audioSurahData.englishNameTranslation}
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack align="center">
                <Text color={goldenColor}>Number of Ayahs</Text>
                <Text color={whiteColor}>{audioSurahData.numberOfAyahs}</Text>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(SurahDetails);
