const languageCodeToName = [
  { az: "أذربيجاني Azerbaijani" },
  { bn: "البنغالية Bengali" },
  { cs: "التشيكية Czech" },
  { de: "الألمانية German" },
  { dv: "ديفيهي Dhivehi" },
  { en: "الانجليزية English" },
  { fa: "فارسية Persian" },
  { fr: "فرنسي French" },
  { ha: "الهوسا Hausa" },
  { hi: "الهندية Hindi" },
  { id: "إندونيسي Indonesian" },
  { it: "الإيطالية Italian" },
  { ja: "اليابانية Japanese" },
  { ko: "الكورية Korean" },
  { ku: "كردي Kurdish" },
  { ml: "مالايالامية Malayalam" },
  { nl: "الهولندية Dutch" },
  { no: "النرويجية Norwegian" },
  { pl: "بولندية Polish" },
  { pt: "البرتغالية Portuguese" },
  { ro: "الرومانية Romanian" },
  { ru: "الروسية Russian" },
  { sd: "السندية Sindhi" },
  { so: "الصومالية Somali" },
  { sq: "ألباني Albanian" },
  { sv: "اللغة السويدية Swedish" },
  { sw: "السواحيلية Swahili" },
  { ta: "التاميلية Tamil" },
  { tg: "طاجيكية Tajik" },
  { th: "التايلاندية Thai" },
  { tr: "التركية Turkish" },
  { tt: "التتارية Tatar" },
  { ug: "أويغوري Uyghur" },
  { ur: "الأردية Urdu" },
  { uz: "الأوزبكية Uzbek" },
  { es: "الإسبانية Spanish" },
  { bg: "البلغارية Bulgarian" },
  { bs: "البوسنية Bosnian" },
  { ms: "لغة الملايو Malay" },
  { zh: "الصينية Chinese" },
  { ar: "العربية Arabic" },
  { si: "السنهالية Sinhala" },
  { ba: "باشكير Bashkir" },
  { my: "البورمية Burmese" },
  { am: "الأمهرية Amharic" },
  { ber: "بربر Berber" },
  { ps: "الباشتو Pashto" },
];

export default languageCodeToName;
