// import React, { useState } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, Heading, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";

// const Recorder = ({ ayah, selectedNumber }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }

//     return matrix[b.length][a.length];
//   };
//   console.log("Selected Number:", selectedNumber);

//   const startRecording = () => {
//     setIsRecording(true);
//     const mp3Recorder = new MicRecorder({ bitRate: 128 });
//     mp3Recorder
//       .start()
//       .then(() => {
//         setStatus("Started recording...");
//         setRecorder(mp3Recorder);
//       })
//       .catch((e) => console.error(e));
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder
//         .stop()
//         .getMp3()
//         .then(([buffer, blob]) => {
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           setIsRecording(false);
//           setStatus(
//             "Stopped recording. Click Save to upload or Play to listen."
//           );
//         })
//         .catch((e) => console.error(e));
//       setRecorder(null);
//     } else {
//       console.error("Tried to stop recording but it was not ready.");
//     }
//   };

//   const handleRecordingButton = () => {
//     if (isRecording) {
//       stopRecording();
//     } else if (audioURL && status === "The audio you uploaded is not Ayah.") {
//       setAudioURL("");
//       setStatus("");
//       startRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const uploadToFirebase = async () => {
//     if (!audioURL) {
//       setStatus("No audio to upload");
//       return;
//     }

//     const responseBlob = await fetch(audioURL);
//     const blob = await responseBlob.blob();
//     const formData = new FormData();
//     formData.append("audio", blob);
//     const response = await fetch(
//       `https://quran-backend-e50f6b54cccc.herokuapp.com/transcribe`,
//       {
//         method: "POST",
//         body: formData,
//       }
//     );
//     const transcriptionResult = await response.json();
//     const transcribedText = normalizeText(transcriptionResult.text);
//     const expectedText = normalizeText(ayah.text);

//     console.log(transcribedText);
//     console.log(expectedText);
//     const distance = levenshtein(transcribedText, expectedText);
//     console.log(distance);

//     const isSimilar = distance <= 10;
//     // if (!user) {
//     //   console.error("User is not authenticated or not available.");
//     //   return;
//     // }

//     if (isSimilar) {
//       const storageRef = ref(
//         storage,
//         `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//       );
//       await uploadBytes(storageRef, blob);
//       const downloadURL = await getDownloadURL(storageRef);
//       setStatus("Ayah was uploaded successfully.");

//       const docRef = doc(
//         firestore,
//         `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//       );
//       await setDoc(docRef, {
//         uid: user.uid,
//         surahNumber: selectedNumber, // Include the surah number
//         ayahNumberInSurah: ayah.numberInSurah,
//         audioURL: downloadURL,
//       });
//       setAudioURL("");
//     } else {
//       setStatus("The audio you uploaded is not Ayah.");
//     }
//   };

//   return (
//     <Center>
//       <Box p={5}>
//         <Heading as="h2" size="lg" textAlign="center">
//           Recorder
//         </Heading>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text>{status}</Text>
//           <Button
//             colorScheme={isRecording ? "red" : "teal"}
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {isRecording
//               ? "Stop"
//               : audioURL && status === "The audio you uploaded is not Ayah."
//               ? "Retry"
//               : "Start"}
//           </Button>
//           <Button
//             colorScheme="blue"
//             onClick={uploadToFirebase}
//             disabled={!audioURL}
//             style={buttonStyles}
//           >
//             Save
//           </Button>
//           {audioURL && (
//             <Box>
//               <audio controls>
//                 <source src={audioURL} type="audio/mpeg" />
//                 Your browser does not support the audio element.
//               </audio>
//             </Box>
//           )}
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;

// import React, { useState, useCallback } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, Heading, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({ ayah, selectedNumber }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };

//   const [recordingTimer, setRecordingTimer] = useState(null);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }

//     return matrix[b.length][a.length];
//   };

//   console.log("Selected Number:", selectedNumber);

//   const startRecording = async () => {
//     const hasMicrophone = await checkMicrophone();

//     if (!hasMicrophone) {
//       setStatus(
//         "No microphone detected. Please connect a microphone and try again."
//       );
//       return;
//     }

//     setIsRecording(true);
//     const mp3Recorder = new MicRecorder({ bitRate: 128 });
//     mp3Recorder
//       .start()
//       .then(() => {
//         setStatus("Started recording...");
//         setRecorder(mp3Recorder);
//       })
//       .catch((e) => console.error(e));
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder
//         .stop()
//         .getMp3()
//         .then(([buffer, blob]) => {
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           setIsRecording(false);
//           setStatus(
//             "Stopped recording. Click Save to upload or Play to listen."
//           );
//         })
//         .catch((e) => console.error(e));
//       setRecorder(null);
//     } else {
//       console.error("Tried to stop recording but it was not ready.");
//     }
//   };

//   const handleRecordingButton = () => {
//     if (isRecording) {
//       stopRecording();
//     } else if (audioURL && status === "The audio you uploaded is not Ayah.") {
//       setAudioURL("");
//       setStatus("");
//       startRecording();
//     } else {
//       startRecording();
//     }
//   };
//   const checkMicrophone = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       return !!stream;
//     } catch (err) {
//       return false;
//     }
//   };

//   const uploadToFirebase = useCallback(async () => {
//     if (!audioURL) {
//       setStatus("No audio to upload");
//       return;
//     }

//     const responseBlob = await fetch(audioURL);
//     const blob = await responseBlob.blob();
//     const formData = new FormData();
//     formData.append("audio", blob);
//     try {
//       const response = await axios.post(
//         `https://quran-backend-e50f6b54cccc.herokuapp.com/transcribe`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       const transcriptionResult = response.data;
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);

//       console.log(transcribedText);
//       console.log(expectedText);
//       const distance = levenshtein(transcribedText, expectedText);
//       console.log(distance);

//       const isSimilar = distance <= 10;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);
//         setStatus("Ayah was uploaded successfully.");

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });
//         setAudioURL("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//     }
//   }, [
//     audioURL,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   return (
//     <Center>
//       <Box p={5}>
//         <Heading as="h2" size="lg" textAlign="center">
//           Recorder
//         </Heading>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text>{status}</Text>
//           <Button
//             colorScheme={isRecording ? "red" : "teal"}
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {isRecording
//               ? "Stop"
//               : audioURL && status === "The audio you uploaded is not Ayah."
//               ? "Retry"
//               : "Start"}
//           </Button>
//           <Button
//             colorScheme="blue"
//             onClick={uploadToFirebase}
//             disabled={!audioURL}
//             style={buttonStyles}
//           >
//             Save
//           </Button>
//           {audioURL && (
//             <Box>
//               <audio controls>
//                 <source src={audioURL} type="audio/mpeg" />
//                 Your browser does not support the audio element.
//               </audio>
//             </Box>
//           )}
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;
// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, Heading, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({ ayah, selectedNumber, originalAudioDuration }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };
//   const recordingTimerRef = useRef(null);

// // this version of this code working fine but it has a warning
// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, Heading, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({ ayah, selectedNumber, originalAudioDuration }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const [audioDuration, setAudioDuration] = useState(0); // Track current audio duration
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };
//   const recordingTimerRef = useRef(null);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   const startRecording = async () => {
//     const hasMicrophone = await checkMicrophone();

//     if (!hasMicrophone) {
//       setStatus(
//         "No microphone detected. Please connect a microphone and try again."
//       );
//       return;
//     }

//     setIsRecording(true);
//     const mp3Recorder = new MicRecorder({ bitRate: 128 });
//     mp3Recorder
//       .start()
//       .then(() => {
//         setStatus("Started recording...");
//         setRecorder(mp3Recorder);

//         if (recordingTimerRef.current) {
//           clearTimeout(recordingTimerRef.current);
//         }

//         recordingTimerRef.current = setTimeout(() => {
//           if (isRecording) {
//             stopRecording();
//             setStatus("Recording limit reached.");
//           }
//         }, originalAudioDuration * 1000); // Convert seconds to milliseconds
//       })
//       .catch((e) => console.error(e));
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder
//         .stop()
//         .getMp3()
//         .then(([buffer, blob]) => {
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           setIsRecording(false);
//           setStatus(
//             "Stopped recording. Click Save to upload or Play to listen."
//           );

//           if (recordingTimerRef.current) {
//             clearTimeout(recordingTimerRef.current);
//             recordingTimerRef.current = null;
//           }
//         })
//         .catch((e) => console.error(e));
//       setRecorder(null);
//     } else {
//       console.error("Tried to stop recording but it was not ready.");
//     }
//   };

//   const handleRecordingButton = () => {
//     if (isRecording) {
//       stopRecording();
//     } else if (audioURL && status === "The audio you uploaded is not Ayah.") {
//       setAudioURL("");
//       setStatus("");
//       startRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const checkMicrophone = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       return !!stream;
//     } catch (err) {
//       return false;
//     }
//   };
//   const uploadToFirebase = useCallback(async () => {
//     if (!audioURL) {
//       setStatus("No audio to upload");
//       return;
//     }
//     // if (recordingDuration > 3) {
//     //   setStatus("The audio is too long. Maximum duration is 3 seconds.");
//     //   return;
//     // }

//     const responseBlob = await fetch(audioURL);
//     const blob = await responseBlob.blob();
//     const formData = new FormData();
//     formData.append("audio", blob);
//     try {
//       const response = await axios.post(
//         `http://localhost:3000/transcribe`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       const transcriptionResult = response.data;
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);

//       const isSimilar = distance <= 10;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);
//         setStatus("Ayah was uploaded successfully.");

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });
//         setAudioURL("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//     }
//   }, [
//     audioURL,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   // return (
//   //   <Center>
//   //     <Box p={5}>
//   //       <Heading as="h2" size="lg" textAlign="center">
//   //         Recorder
//   //       </Heading>
//   //       <VStack spacing={4} align="center" mt={5}>
//   //         <Text>{status}</Text>
//   //         <Button
//   //           colorScheme={isRecording ? "red" : "teal"}
//   //           onClick={handleRecordingButton}
//   //           style={buttonStyles}
//   //         >
//   //           {isRecording
//   //             ? "Stop"
//   //             : audioURL && status === "The audio you uploaded is not Ayah."
//   //             ? "Retry"
//   //             : "Start"}
//   //         </Button>
//   //         <audio src={audioURL} controls></audio>
//   //         <Button
//   //           colorScheme="green"
//   //           onClick={uploadToFirebase}
//   //           style={buttonStyles}
//   //           isDisabled={!audioURL}
//   //         >
//   //           Save
//   //         </Button>
//   //       </VStack>
//   //     </Box>
//   //   </Center>
//   // );
//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "The audio was uploaded successfully."
//                 ? "green"
//                 : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={isRecording ? "red" : "teal"}
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {isRecording
//               ? "Stop"
//               : audioURL && status === "The audio you uploaded is not Ayah."
//               ? "Retry"
//               : "Start"}
//           </Button>
//           <audio
//             src={audioURL}
//             controls
//             onLoadedMetadata={(e) => setAudioDuration(e.target.duration)}
//           ></audio>
//           {audioDuration > originalAudioDuration && (
//             <Text color="red">
//               The audio is too long. Maximum duration is {originalAudioDuration}{" "}
//               seconds.
//             </Text>
//           )}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioURL || audioDuration > originalAudioDuration}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;

// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({ ayah, selectedNumber, originalAudioDuration }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const [audioDuration, setAudioDuration] = useState(0);
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };
//   const recordingTimerRef = useRef(null);

//   const startRecording = async () => {
//     const hasMicrophone = await checkMicrophone();

//     if (!hasMicrophone) {
//       setStatus(
//         "No microphone detected. Please connect a microphone and try again."
//       );
//       return;
//     }

//     setIsRecording(true);
//     const mp3Recorder = new MicRecorder({ bitRate: 128 });
//     mp3Recorder
//       .start()
//       .then(() => {
//         setStatus("Started recording...");
//         setRecorder(mp3Recorder);

//         if (recordingTimerRef.current) {
//           clearTimeout(recordingTimerRef.current);
//         }

//         recordingTimerRef.current = setTimeout(() => {
//           if (isRecording) {
//             stopRecording();
//             setStatus("Recording limit reached.");
//           }
//         }, originalAudioDuration * 1000); // Convert seconds to milliseconds
//       })
//       .catch((e) => console.error(e));
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder
//         .stop()
//         .getMp3()
//         .then(([buffer, blob]) => {
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           setIsRecording(false);
//           setStatus(
//             "Stopped recording. Click Save to upload or Play to listen."
//           );

//           if (recordingTimerRef.current) {
//             clearTimeout(recordingTimerRef.current);
//             recordingTimerRef.current = null;
//           }
//         })
//         .catch((e) => console.error(e));
//       setRecorder(null);
//     } else {
//       console.error("Tried to stop recording but it was not ready.");
//     }
//   };

//   const handleRecordingButton = () => {
//     if (isRecording) {
//       stopRecording();
//     } else if (audioURL && status === "The audio you uploaded is not Ayah.") {
//       setAudioURL("");
//       setStatus("");
//       startRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const checkMicrophone = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       return !!stream;
//     } catch (err) {
//       return false;
//     }
//   };

//   const uploadToFirebase = useCallback(async () => {
//     try {
//       if (!audioURL) {
//         setStatus("No audio to upload");
//         return;
//       }

//       const responseBlob = await fetch(audioURL);
//       const blob = await responseBlob.blob();
//       const formData = new FormData();
//       formData.append("audio", blob);

//       const response = await axios.post(
//         `http://localhost:3000/transcribe`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       const transcriptionResult = response.data;
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);
//       const isSimilar = distance <= 10;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });

//         setStatus("Ayah was uploaded successfully.");
//         setAudioURL("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//       setStatus("Error uploading audio. Please try again.");
//     }
//   }, [
//     audioURL,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "The audio was uploaded successfully."
//                 ? "green"
//                 : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={isRecording ? "red" : "teal"}
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {isRecording
//               ? "Stop"
//               : audioURL && status === "The audio you uploaded is not Ayah."
//               ? "Retry"
//               : "Start"}
//           </Button>
//           <audio
//             src={audioURL}
//             controls
//             onLoadedMetadata={(e) => setAudioDuration(e.target.duration)}
//           ></audio>
//           {audioDuration > originalAudioDuration && (
//             <Text color="red">
//               The audio is too long. Maximum duration is {originalAudioDuration}{" "}
//               seconds.
//             </Text>
//           )}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioURL || audioDuration > originalAudioDuration}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;

// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";

// const Recorder = ({ ayah, selectedNumber, originalAudioDuration }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const [audioDuration, setAudioDuration] = useState(0);
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };
//   const recordingTimerRef = useRef(null);

//   const startRecording = async () => {
//     const hasMicrophone = await checkMicrophone();

//     if (!hasMicrophone) {
//       setStatus(
//         "No microphone detected. Please connect a microphone and try again."
//       );
//       return;
//     }

//     setIsRecording(true);
//     const mp3Recorder = new MicRecorder({ bitRate: 128 });
//     mp3Recorder
//       .start()
//       .then(() => {
//         setStatus("Started recording...");
//         setRecorder(mp3Recorder);

//         if (recordingTimerRef.current) {
//           clearTimeout(recordingTimerRef.current);
//         }

//         recordingTimerRef.current = setTimeout(() => {
//           if (isRecording) {
//             stopRecording();
//             setStatus("Recording limit reached.");
//           }
//         }, originalAudioDuration * 1000); // Convert seconds to milliseconds
//       })
//       .catch((e) => console.error(e));
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder
//         .stop()
//         .getMp3()
//         .then(([buffer, blob]) => {
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           setIsRecording(false);
//           setStatus(
//             "Stopped recording. Click Save to upload or Play to listen."
//           );

//           if (recordingTimerRef.current) {
//             clearTimeout(recordingTimerRef.current);
//             recordingTimerRef.current = null;
//           }
//         })
//         .catch((e) => console.error(e));
//       setRecorder(null);
//     } else {
//       console.error("Tried to stop recording but it was not ready.");
//     }
//   };

//   const handleRecordingButton = () => {
//     if (isRecording) {
//       stopRecording();
//     } else if (audioURL && status === "The audio you uploaded is not Ayah.") {
//       setAudioURL("");
//       setStatus("");
//       startRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const checkMicrophone = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       return !!stream;
//     } catch (err) {
//       return false;
//     }
//   };

//   const uploadToFirebase = useCallback(async () => {
//     try {
//       if (!audioURL) {
//         setStatus("No audio to upload");
//         return;
//       }

//       const responseBlob = await fetch(audioURL);
//       const blob = await responseBlob.blob();
//       const formData = new FormData();
//       formData.append("audio", blob);

//       const response = await fetch(
//         "https://api-inference.huggingface.co/models/Salama1429/tarteel-ai-whisper-tiny-ar-quran",
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
//             "Content-Type": "audio/flac",
//           },
//           method: "POST",
//           body: blob,
//         }
//       );

//       const transcriptionResult = await response.json();
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);
//       const isSimilar = distance <= 10;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });

//         setStatus("Ayah was uploaded successfully.");
//         setAudioURL("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//       setStatus("Error uploading audio. Please try again.");
//     }
//   }, [
//     audioURL,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "The audio was uploaded successfully."
//                 ? "green"
//                 : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={isRecording ? "red" : "teal"}
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {isRecording
//               ? "Stop"
//               : audioURL && status === "The audio you uploaded is not Ayah."
//               ? "Retry"
//               : "Start"}
//           </Button>
//           <audio
//             src={audioURL}
//             controls
//             onLoadedMetadata={(e) => setAudioDuration(e.target.duration)}
//           ></audio>
//           {audioDuration > originalAudioDuration && (
//             <Text color="red">
//               The audio is too long. Maximum duration is {originalAudioDuration}{" "}
//               seconds.
//             </Text>
//           )}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioURL || audioDuration > originalAudioDuration}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;

// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, VStack, Text, Center } from "@chakra-ui/react";
// import MicRecorder from "mic-recorder-to-mp3";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";

// const Recorder = ({ ayah, selectedNumber, originalAudioDuration }) => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [status, setStatus] = useState("");
//   const [recorder, setRecorder] = useState(null);
//   const [audioURL, setAudioURL] = useState("");
//   const [audioDuration, setAudioDuration] = useState(0);
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };
//   const recordingTimerRef = useRef(null);

//   const startRecording = async () => {
//     const hasMicrophone = await checkMicrophone();

//     if (!hasMicrophone) {
//       setStatus(
//         "No microphone detected. Please connect a microphone and try again."
//       );
//       return;
//     }

//     setIsRecording(true);
//     const mp3Recorder = new MicRecorder({ bitRate: 128 });
//     mp3Recorder
//       .start()
//       .then(() => {
//         setStatus("Started recording...");
//         setRecorder(mp3Recorder);

//         if (recordingTimerRef.current) {
//           clearTimeout(recordingTimerRef.current);
//         }

//         recordingTimerRef.current = setTimeout(() => {
//           if (isRecording) {
//             stopRecording();
//             setStatus("Recording limit reached.");
//           }
//         }, originalAudioDuration * 1000); // Convert seconds to milliseconds
//       })
//       .catch((e) => console.error(e));
//   };

//   const stopRecording = () => {
//     if (recorder) {
//       recorder
//         .stop()
//         .getMp3()
//         .then(([buffer, blob]) => {
//           const url = URL.createObjectURL(blob);
//           setAudioURL(url);
//           setIsRecording(false);
//           setStatus(
//             "Stopped recording. Click Save to upload or Play to listen."
//           );

//           if (recordingTimerRef.current) {
//             clearTimeout(recordingTimerRef.current);
//             recordingTimerRef.current = null;
//           }
//         })
//         .catch((e) => console.error(e));
//       setRecorder(null);
//     } else {
//       console.error("Tried to stop recording but it was not ready.");
//     }
//   };

//   const handleRecordingButton = () => {
//     if (isRecording) {
//       stopRecording();
//     } else if (audioURL && status === "The audio you uploaded is not Ayah.") {
//       setAudioURL("");
//       setStatus("");
//       startRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const checkMicrophone = useCallback(async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       return !!stream;
//     } catch (err) {
//       return false;
//     }
//   }, []);

//   const uploadToFirebase = useCallback(async () => {
//     try {
//       if (!audioURL) {
//         setStatus("No audio to upload");
//         return;
//       }

//       const responseBlob = await fetch(audioURL);
//       const blob = await responseBlob.blob();
//       const formData = new FormData();
//       formData.append("audio", blob);

//       const response = await fetch(
//         "https://api-inference.huggingface.co/models/Salama1429/tarteel-ai-whisper-tiny-ar-quran",
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
//             "Content-Type": "audio/flac",
//           },
//           method: "POST",
//           body: blob,
//         }
//       );

//       const transcriptionResult = await response.json();
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);
//       const isSimilar = distance <= 10;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });

//         setStatus("Ayah was uploaded successfully.");
//         setAudioURL("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//       setStatus("Error uploading audio. Please try again.");
//     }
//   }, [
//     audioURL,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "The audio was uploaded successfully."
//                 ? "green"
//                 : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={isRecording ? "red" : "teal"}
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {isRecording
//               ? "Stop"
//               : audioURL && status === "The audio you uploaded is not Ayah."
//               ? "Retry"
//               : "Start"}
//           </Button>
//           <audio
//             src={audioURL}
//             controls
//             onLoadedMetadata={(e) => setAudioDuration(e.target.duration)}
//           ></audio>
//           {audioDuration > originalAudioDuration && (
//             <Text color="red">
//               The audio is too long. Maximum duration is {originalAudioDuration}{" "}
//               seconds.
//             </Text>
//           )}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioURL || audioDuration > originalAudioDuration}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };
// export default Recorder;

// import React, { useState } from "react";
// import axios from "axios";

// const App = () => {
//   const [recordingStatus, setRecordingStatus] = useState("");

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording"
//       );
//       setRecordingStatus(response.data);
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Audio Recorder</h1>
//       <div>Status: {recordingStatus}</div>
//       {recordingStatus !== "Recording started" && (
//         <button onClick={startRecording}>Start Recording</button>
//       )}
//       {recordingStatus === "Recording started" && (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//     </div>
//   );
// };

// export default App;
// import React, { useState } from "react";
// import axios from "axios";

// const Recorder = (ayah) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording"
//       );
//       setRecordingStatus(response.data);
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data);
//       setAudioFilePath(response.data.audio_file_path);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Audio Recorder</h1>
//       <div>Status: {recordingStatus}</div>
//       {recordingStatus !== "Recording started" && (
//         <button onClick={startRecording}>Start Recording</button>
//       )}
//       {recordingStatus === "Recording started" && (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       {audioFilePath && (
//         <div>
//           <h2>Recorded Audio</h2>
//           <audio controls>
//             <source
//               src={`http://localhost:5000/${audioFilePath}`}
//               type="audio/wav"
//             />
//             Your browser does not support the audio element.
//           </audio>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Recorder;
// import React, { useState } from "react";
// import axios from "axios";

// const Recorder = ({ singleTextAyah }) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording",
//         singleTextAyah // Pass Ayah data as part of the request body
//       );
//       setRecordingStatus(response.data);
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };
//   console.log(singleTextAyah);
//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data);
//       setAudioFilePath(response.data.audio_file_path);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Audio Recorder</h1>
//       <div>Status: {recordingStatus}</div>
//       {recordingStatus !== "Recording started" && (
//         <button onClick={startRecording}>Start Recording</button>
//       )}
//       {recordingStatus === "Recording started" && (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       {audioFilePath && (
//         <div>
//           <h2>Recorded Audio</h2>
//           <audio controls>
//             <source
//               src={`http://localhost:5000/${audioFilePath}`}
//               type="audio/wav"
//             />
//             Your browser does not support the audio element.
//           </audio>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Recorder;
// import React, { useState } from "react";
// import axios from "axios";

// const Recorder = ({ singleTextAyah }) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording",
//         singleTextAyah // Pass Ayah data as part of the request body
//       );
//       setRecordingStatus(response.data);
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data.message);
//       setAudioFilePath(response.data.audio_file_path);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Audio Recorder</h1>
//       <div>Status: {recordingStatus}</div>
//       {recordingStatus !== "Recording started" && (
//         <button onClick={startRecording}>Start Recording</button>
//       )}
//       {recordingStatus === "Recording started" && (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       {audioFilePath && (
//         <div>
//           <h2>Recorded Audio</h2>
//           <audio controls>
//             <source
//               src={`http://localhost:5000/${audioFilePath}`}
//               type="audio/wav"
//             />
//             Your browser does not support the audio element.
//           </audio>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Recorder;
// import React, { useState } from "react";
// import axios from "axios";

// const Recorder = ({ singleTextAyah }) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");
//   const [audioSrc, setAudioSrc] = useState(""); // Separate state for the audio src

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording",
//         singleTextAyah // Pass Ayah data as part of the request body
//       );
//       setRecordingStatus(response.data);
//       setAudioFilePath(""); // Clear the audio file path when a new recording starts
//       setAudioSrc(""); // Clear the audio src when a new recording starts
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data.message);
//       const newAudioFilePath = response.data.audio_file_path;
//       setAudioFilePath(newAudioFilePath);
//       setAudioSrc(`http://localhost:5000/${newAudioFilePath}?t=${Date.now()}`); // Append timestamp to force update
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Audio Recorder</h1>
//       <div>Status: {recordingStatus}</div>
//       {recordingStatus !== "Recording started" && (
//         <button onClick={startRecording}>Start Recording</button>
//       )}
//       {recordingStatus === "Recording started" && (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       {audioSrc && (
//         <div>
//           <h2>Recorded Audio</h2>
//           <audio controls key={audioSrc}>
//             <source src={audioSrc} type="audio/wav" />
//             Your browser does not support the audio element.
//           </audio>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Recorder;
// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, VStack, Text, Center } from "@chakra-ui/react";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({
//   ayah,
//   selectedNumber,
//   originalAudioDuration,
//   singleTextAyah,
// }) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");
//   const [audioSrc, setAudioSrc] = useState("");
//   const [status, setStatus] = useState("");
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording",
//         { ayah: singleTextAyah }
//       );
//       setRecordingStatus(response.data);
//       setAudioFilePath("");
//       setAudioSrc("");
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data.message);
//       const newAudioFilePath = response.data.audio_file_path;
//       setAudioFilePath(newAudioFilePath);
//       setAudioSrc(`http://localhost:5000/${newAudioFilePath}?t=${Date.now()}`);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   const handleRecordingButton = () => {
//     if (recordingStatus === "Recording started") {
//       stopRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const uploadToFirebase = useCallback(async () => {
//     try {
//       if (!audioFilePath) {
//         setStatus("No audio to upload");
//         return;
//       }

//       const response = await fetch(`http://localhost:5000/${audioFilePath}`);
//       const blob = await response.blob();

//       const formData = new FormData();
//       formData.append("audio", blob);

//       const apiResponse = await fetch(
//         "https://api-inference.huggingface.co/models/Salama1429/tarteel-ai-whisper-tiny-ar-quran",
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
//             "Content-Type": "audio/flac",
//           },
//           method: "POST",
//           body: blob,
//         }
//       );

//       const transcriptionResult = await apiResponse.json();
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);
//       const isSimilar = distance <= 10;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });

//         setStatus("Ayah was uploaded successfully.");
//         setAudioFilePath("");
//         setAudioSrc("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//       setStatus("Error uploading audio. Please try again.");
//     }
//   }, [
//     audioFilePath,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "Ayah was uploaded successfully." ? "green" : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={
//               recordingStatus === "Recording started" ? "red" : "teal"
//             }
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {recordingStatus === "Recording started" ? "Stop" : "Start"}
//           </Button>
//           {audioSrc && <audio src={audioSrc} controls></audio>}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioFilePath}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;
// import React, { useState, useCallback, useRef } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, VStack, Text, Center } from "@chakra-ui/react";
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({
//   ayah,
//   selectedNumber,
//   originalAudioDuration,
//   singleTextAyah,
// }) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");
//   const [audioSrc, setAudioSrc] = useState("");
//   const [status, setStatus] = useState("");
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording",
//         { ayah: singleTextAyah }
//       );
//       setRecordingStatus(response.data);
//       setAudioFilePath("");
//       setAudioSrc("");
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data.message);
//       const newAudioFilePath = response.data.audio_file_path;
//       setAudioFilePath(newAudioFilePath);
//       setAudioSrc(`http://localhost:5000/${newAudioFilePath}?t=${Date.now()}`);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   const handleRecordingButton = () => {
//     if (recordingStatus === "Recording started") {
//       stopRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const uploadToFirebase = useCallback(async () => {
//     try {
//       if (!audioFilePath) {
//         setStatus("No audio to upload");
//         return;
//       }

//       const response = await fetch(`http://localhost:5000/${audioFilePath}`);
//       const blob = await response.blob();

//       const formData = new FormData();
//       formData.append("audio", blob);

//       const apiResponse = await fetch(
//         "https://api-inference.huggingface.co/models/Salama1429/tarteel-ai-whisper-tiny-ar-quran",
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
//             "Content-Type": "audio/flac",
//           },
//           method: "POST",
//           body: blob,
//         }
//       );

//       const transcriptionResult = await apiResponse.json();
//       const transcribedText = normalizeText(transcriptionResult.text);
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);
//       const isSimilar = distance <= 12;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });

//         setStatus("Ayah was uploaded successfully.");
//         setAudioFilePath("");
//         setAudioSrc("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//       setStatus("Error uploading audio. Please try again.");
//     }
//   }, [
//     audioFilePath,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "Ayah was uploaded successfully." ? "green" : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={
//               recordingStatus === "Recording started" ? "red" : "teal"
//             }
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {recordingStatus === "Recording started" ? "Stop" : "Start"}
//           </Button>
//           {audioSrc && <audio src={audioSrc} controls></audio>}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioFilePath}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;

// import React, { useState, useCallback } from "react";
// import { getAuth } from "firebase/auth";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { Box, Button, VStack, Text, Center } from "@chakra-ui/react"; // Assuming you're still using Chakra UI components
// import app from "../firebase";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import axios from "axios";

// const Recorder = ({
//   ayah,
//   selectedNumber,
//   originalAudioDuration,
//   singleTextAyah,
// }) => {
//   const [recordingStatus, setRecordingStatus] = useState("");
//   const [audioFilePath, setAudioFilePath] = useState("");
//   const [audioSrc, setAudioSrc] = useState("");
//   const [status, setStatus] = useState("");
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const storage = getStorage(app);
//   const firestore = getFirestore(app);
//   const buttonStyles = { width: "200px" };

//   // Moved normalizeText function definition here
//   const normalizeText = (text) => {
//     if (!text) return "";
//     return text
//       .normalize("NFKD")
//       .replace(/[\u064B-\u065F]/g, "")
//       .replace(/ٱ/g, "ا")
//       .replace(/ٰ/g, "")
//       .trim();
//   };

//   const levenshtein = (a, b) => {
//     const matrix = [];
//     let i, j;

//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     for (i = 0; i <= b.length; i++) {
//       matrix[i] = [i];
//     }
//     for (j = 0; j <= a.length; j++) {
//       matrix[0][j] = j;
//     }
//     for (i = 1; i <= b.length; i++) {
//       for (j = 1; j <= a.length; j++) {
//         if (b.charAt(i - 1) === a.charAt(j - 1)) {
//           matrix[i][j] = matrix[i - 1][j - 1];
//         } else {
//           matrix[i][j] = Math.min(
//             matrix[i - 1][j - 1] + 1,
//             Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
//           );
//         }
//       }
//     }
//     return matrix[b.length][a.length];
//   };

//   const startRecording = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/start-recording",
//         { ayah: singleTextAyah }
//       );
//       setRecordingStatus(response.data);
//       setAudioFilePath("");
//       setAudioSrc("");
//     } catch (error) {
//       console.error("Error starting recording:", error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await axios.post("http://localhost:5000/stop-recording");
//       setRecordingStatus(response.data.message);
//       const newAudioFilePath = response.data.audio_file_path;
//       setAudioFilePath(newAudioFilePath);
//       setAudioSrc(`http://localhost:5000/${newAudioFilePath}?t=${Date.now()}`);
//     } catch (error) {
//       console.error("Error stopping recording:", error);
//     }
//   };

//   const handleRecordingButton = () => {
//     if (recordingStatus === "Recording started") {
//       stopRecording();
//     } else {
//       startRecording();
//     }
//   };

//   const uploadToFirebase = useCallback(async () => {
//     try {
//       if (!audioFilePath) {
//         setStatus("No audio to upload");
//         return;
//       }

//       const response = await fetch(`http://localhost:5000/${audioFilePath}`);
//       const blob = await response.blob();

//       const formData = new FormData();
//       formData.append("audio", blob);

//       const apiResponse = await fetch(
//         "https://api-inference.huggingface.co/models/Salama1429/tarteel-ai-whisper-tiny-ar-quran",
//         {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
//             "Content-Type": "audio/flac",
//           },
//           method: "POST",
//           body: blob,
//         }
//       );

//       const transcriptionResult = await apiResponse.json();
//       const transcribedText = normalizeText(transcriptionResult.text); // Now this line should work fine
//       const expectedText = normalizeText(ayah.text);
//       const distance = levenshtein(transcribedText, expectedText);
//       const isSimilar = distance <= 12;

//       if (isSimilar) {
//         const storageRef = ref(
//           storage,
//           `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
//         );
//         await uploadBytes(storageRef, blob);
//         const downloadURL = await getDownloadURL(storageRef);

//         const docRef = doc(
//           firestore,
//           `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
//         );
//         await setDoc(docRef, {
//           uid: user.uid,
//           surahNumber: selectedNumber,
//           ayahNumberInSurah: ayah.numberInSurah,
//           audioURL: downloadURL,
//         });

//         setStatus("Ayah was uploaded successfully.");
//         setAudioFilePath("");
//         setAudioSrc("");
//       } else {
//         setStatus("The audio you uploaded is not Ayah.");
//       }
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//       setStatus("Error uploading audio. Please try again.");
//     }
//   }, [
//     audioFilePath,
//     ayah,
//     selectedNumber,
//     normalizeText,
//     levenshtein,
//     user,
//     storage,
//     firestore,
//   ]);

//   return (
//     <Center>
//       <Box p={5}>
//         <Text
//           as="p1"
//           size="lg"
//           textAlign="center"
//           color="black"
//           borderRadius="10px"
//           bg="red.200"
//           p={2}
//         >
//           Recorder
//         </Text>
//         <VStack spacing={4} align="center" mt={5}>
//           <Text
//             color={
//               status === "Ayah was uploaded successfully." ? "green" : "red"
//             }
//           >
//             {status}
//           </Text>
//           <Button
//             colorScheme={
//               recordingStatus === "Recording started" ? "red" : "teal"
//             }
//             onClick={handleRecordingButton}
//             style={buttonStyles}
//           >
//             {recordingStatus === "Recording started" ? "Stop" : "Start"}
//           </Button>
//           {audioSrc && <audio src={audioSrc} controls></audio>}
//           <Button
//             colorScheme="green"
//             onClick={uploadToFirebase}
//             style={buttonStyles}
//             isDisabled={!audioFilePath}
//           >
//             Save
//           </Button>
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default Recorder;
import React, { useState, useCallback } from "react";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Box, Button, VStack, Text, Center } from "@chakra-ui/react"; // Assuming you're still using Chakra UI components
import app from "../firebase";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import axios from "axios";

const Recorder = ({
  ayah,
  selectedNumber,
  originalAudioDuration,
  singleTextAyah,
}) => {
  const [recordingStatus, setRecordingStatus] = useState("");
  const [audioFilePath, setAudioFilePath] = useState("");
  const [audioSrc, setAudioSrc] = useState("");
  const [status, setStatus] = useState("");
  const auth = getAuth();
  const user = auth.currentUser;
  const storage = getStorage(app);
  const firestore = getFirestore(app);
  const buttonStyles = { width: "200px" };

  // Moved normalizeText function definition here
  const normalizeText = (text) => {
    if (!text) return "";
    return text
      .normalize("NFKD")
      .replace(/[\u064B-\u065F]/g, "")
      .replace(/ٱ/g, "ا")
      .replace(/ٰ/g, "")
      .trim();
  };

  const levenshtein = (a, b) => {
    const matrix = [];
    let i, j;

    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
          );
        }
      }
    }
    return matrix[b.length][a.length];
  };

  const startRecording = async () => {
    try {
      const response = await axios.post(
        "http://localhost:5000/start-recording",
        { ayah: singleTextAyah }
      );
      setRecordingStatus(response.data);
      setAudioFilePath("");
      setAudioSrc("");
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    try {
      const response = await axios.post("http://localhost:5000/stop-recording");
      setRecordingStatus(response.data.message);
      const newAudioFilePath = response.data.audio_file_path;
      setAudioFilePath(newAudioFilePath);
      setAudioSrc(`http://localhost:5000/${newAudioFilePath}?t=${Date.now()}`);
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  const handleRecordingButton = () => {
    if (recordingStatus === "Recording started") {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const uploadToFirebase = useCallback(async () => {
    try {
      if (!audioFilePath) {
        setStatus("No audio to upload");
        return;
      }

      const response = await fetch(`http://localhost:5000/${audioFilePath}`);
      const blob = await response.blob();

      const formData = new FormData();
      formData.append("audio", blob);

      const apiResponse = await fetch(
        "https://api-inference.huggingface.co/models/Salama1429/tarteel-ai-whisper-tiny-ar-quran",
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
            "Content-Type": "audio/flac",
          },
          method: "POST",
          body: blob,
        }
      );

      const transcriptionResult = await apiResponse.json();
      const transcribedText = normalizeText(transcriptionResult.text); // Now this line should work fine
      const expectedText = normalizeText(ayah.text);
      const distance = levenshtein(transcribedText, expectedText);
      const isSimilar = distance <= 12;

      if (isSimilar) {
        const storageRef = ref(
          storage,
          `audio/${user.uid}/${selectedNumber}_${ayah.numberInSurah}.mp3`
        );
        await uploadBytes(storageRef, blob);
        const downloadURL = await getDownloadURL(storageRef);

        const docRef = doc(
          firestore,
          `recordings/${user.uid}_${selectedNumber}_${ayah.numberInSurah}`
        );
        await setDoc(docRef, {
          uid: user.uid,
          surahNumber: selectedNumber,
          ayahNumberInSurah: ayah.numberInSurah,
          audioURL: downloadURL,
        });

        setStatus("Ayah was uploaded successfully.");
        setAudioFilePath("");
        setAudioSrc("");
      } else {
        setStatus("The audio you uploaded is not Ayah.");
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
      setStatus("Error uploading audio. Please try again.");
    }
  }, [
    audioFilePath,
    ayah,
    selectedNumber,
    normalizeText,
    levenshtein,
    user,
    storage,
    firestore,
  ]);

  return (
    <Center>
      <Box p={5}>
        <Text
          as="p1"
          size="lg"
          textAlign="center"
          color="black"
          borderRadius="10px"
          bg="red.200"
          p={2}
        >
          Recorder
        </Text>
        <VStack spacing={4} align="center" mt={5}>
          <Text
            color={
              status === "Ayah was uploaded successfully." ? "green" : "red"
            }
          >
            {status}
          </Text>
          <Button
            colorScheme={
              recordingStatus === "Recording started" ? "red" : "teal"
            }
            onClick={handleRecordingButton}
            style={buttonStyles}
          >
            {recordingStatus === "Recording started" ? "Stop" : "Start"}
          </Button>
          {audioSrc && <audio src={audioSrc} controls></audio>}
          <Button
            colorScheme="green"
            onClick={uploadToFirebase}
            style={buttonStyles}
            isDisabled={!audioFilePath}
            // Ancient button style
            bg="#dedede"
            border="1px solid #999"
            color="#666"
            _hover={{
              bg: "#ccc",
              color: "#444",
            }}
          >
            Save
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default Recorder;
