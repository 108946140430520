// // export default ImageSlider;
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Cookies from "js-cookie"; // Importing Cookies for storing the selected translation
// import "./ImageSlider.css"; // Import CSS file for styling
// import languageCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import videos from "./videos";
// const VideoSlider = () => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(
//     getRandomVideoIndex()
//   );
//   const [ayahs, setAyahs] = useState([]); // State to hold the Quranic verses
//   const [reciter, setReciter] = useState(
//     () => Cookies.get("selectedReciter") || "ar.alafasy"
//   ); // Default reciter
//   const [currentAyahIndex, setCurrentAyahIndex] = useState(0); // State to keep track of current ayah
//   const [audioUrl, setAudioUrl] = useState(""); // State to hold the audio URL
//   const audioRef = useRef(null); // Ref to access the audio element
//   const [editionsWithAudio, setEditionsWithAudio] = useState([]); // State for editions with audio
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]); // State for editions with translation
//   const [translationAyah, setTranslationAyah] = useState(""); // State to hold the translation text
//   const [selectedLanguage, setSelectedLanguage] = useState(
//     () => Cookies.get("selectedLanguage") || "en"
//   ); // Default language set to English
//   const [selectedEdition, setSelectedEdition] = useState(
//     () => Cookies.get("selectedEdition") || "en.sahih"
//   ); // Default edition set to English translation
//   const [startAyah, setStartAyah] = useState(
//     () => parseInt(Cookies.get("startAyah")) || 1
//   ); // State to hold the starting ayah
//   const [verseLimit, setVerseLimit] = useState(
//     () => parseInt(Cookies.get("verseLimit")) || 200
//   ); // State to hold the verse limit
//   const [autoPlayNextAyah, setAutoPlayNextAyah] = useState(
//     () => Cookies.get("autoPlayNextAyah") === "true"
//   ); // State to hold the auto play next ayah option

//   // Function to get a random index from the videos array
//   function getRandomVideoIndex() {
//     return Math.floor(Math.random() * videos.length);
//   }

//   // Fetch editions with audio and translation
//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEditions();
//   }, [ayahs, currentAyahIndex]);

//   // Video slider logic
//   useEffect(() => {
//     const videoInterval = setInterval(() => {
//       setCurrentVideoIndex(getRandomVideoIndex());
//     }, 5000); // Adjust the interval for video sliding to 5 seconds

//     return () => clearInterval(videoInterval);
//   }, []);

//   // Fetch ayahs on reciter change
//   useEffect(() => {
//     const fetchAyahs = async () => {
//       try {
//         const ayahPromises = [];
//         const endAyah = startAyah + verseLimit - 1; // Calculate the end ayah

//         // Fetch consecutive ayahs based on startAyah and endAyah
//         for (let i = startAyah; i <= endAyah; i++) {
//           ayahPromises.push(
//             axios.get(`http://api.alquran.cloud/v1/ayah/${i}/${reciter}`)
//           );
//         }

//         const responses = await Promise.all(ayahPromises);
//         const fetchedAyahs = responses.map((response) => response.data.data);

//         setAyahs(fetchedAyahs);
//         setCurrentAyahIndex(0); // Reset to the first ayah
//         if (fetchedAyahs.length > 0) {
//           setAudioUrl(fetchedAyahs[0].audio); // Set the initial audio URL
//         }
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//       }
//     };

//     fetchAyahs();
//   }, [reciter, startAyah, verseLimit]);

//   // Update audio URL and translation when current ayah changes
//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[currentAyahIndex].audio); // Update the audio URL
//     }

//     if (ayahs.length > 0 && selectedLanguage && selectedEdition) {
//       axios
//         .get(
//           `http://api.alquran.cloud/v1/ayah/${
//             startAyah + currentAyahIndex
//           }/${selectedEdition}`
//         )
//         .then((response) => {
//           setTranslationAyah(response.data.data.text); // Update translation
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [currentAyahIndex, ayahs, selectedLanguage, selectedEdition, startAyah]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlayNextAyah) {
//         setCurrentAyahIndex((prevIndex) =>
//           prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, currentAyahIndex, autoPlayNextAyah]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selectedReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelectedLanguage(selectedLanguage);
//     Cookies.set("selectedLanguage", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelectedEdition(selectedEdition);
//     Cookies.set("selectedEdition", selectedEdition, { expires: 7 });
//   };
//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlayNextAyah(autoPlay);
//     Cookies.set("autoPlayNextAyah", autoPlay ? "true" : "false", {
//       expires: 7,
//     });
//   };

//   const handleVerseLimitChange = (e) => {
//     const verseLimit = parseInt(e.target.value);
//     setVerseLimit(verseLimit);
//     Cookies.set("verseLimit", verseLimit, { expires: 7 });
//   };

//   return (
//     <>
//       <div id="slider-container" className="slider-container">
//         <div className="slider-content">
//           {ayahs.length > 0 && (
//             <>
//               <h1 className="ayah original">{ayahs[currentAyahIndex].text}</h1>
//               <h1 className="ayah translation">{translationAyah}</h1>
//             </>
//           )}
//         </div>
//         <img src={DoneFrame} alt="DoneFrame image" className="DoneFrame " />
//         {videos.length > 0 && (
//           <div className="video-container" style={{ overflow: "hidden" }}>
//             <video
//               src={videos[currentVideoIndex]}
//               autoPlay
//               muted
//               loop
//               className="slider-video"
//               style={{
//                 objectFit: "cover",
//               }}
//             />
//           </div>
//         )}

//         <div className="audio-controls">
//           {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//           <div className="reciter-selector">
//             <label htmlFor="reciter">Choose a reciter:</label>
//             <select id="reciter" value={reciter} onChange={handleReciterChange}>
//               {editionsWithAudio.map((edition) => (
//                 <option key={edition.identifier} value={edition.identifier}>
//                   {edition.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="language-selector">
//             <label htmlFor="language">Choose a language: </label>
//             <select
//               id="language"
//               value={selectedLanguage}
//               onChange={handleLanguageChange}
//             >
//               <option value="">Select Language</option>
//               {languageCodeToName.map((language) => (
//                 <option
//                   key={Object.keys(language)[0]}
//                   value={Object.keys(language)[0]}
//                 >
//                   {Object.values(language)[0]}
//                 </option>
//               ))}
//             </select>
//           </div>
//           {selectedLanguage && (
//             <div className="edition-selector">
//               <label htmlFor="edition">Choose an edition: </label>
//               <select
//                 id="edition"
//                 value={selectedEdition}
//                 onChange={handleEditionChange}
//               >
//                 <option value="">Select Edition</option>
//                 {editionsWithTranslation
//                   .filter((edition) => edition.language === selectedLanguage)
//                   .map((edition) => (
//                     <option key={edition.identifier} value={edition.identifier}>
//                       {edition.name}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           )}
//           <div className="start-ayah-selector">
//             <label htmlFor="startAyah">Start Ayah: </label>
//             <input
//               type="number"
//               id="startAyah"
//               value={startAyah}
//               onChange={handleStartAyahChange}
//             />
//           </div>
//           <div className="verse-limit-selector">
//             <label htmlFor="verseLimit">Verse Limit: </label>
//             <input
//               type="number"
//               id="verseLimit"
//               value={verseLimit}
//               onChange={handleVerseLimitChange}
//               max={200} // Set the maximum value
//             />
//           </div>
//           <div className="autoplay-next-ayah-selector">
//             <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//             <input
//               type="checkbox"
//               id="autoPlayNextAyah"
//               checked={autoPlayNextAyah}
//               onChange={handleAutoPlayNextAyahChange}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default VideoSlider;
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import languageCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import videos from "./videos";

// const VideoSlider = () => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(getRandomVideoIndex());
//   const [ayahs, setAyahs] = useState([]);
//   const [reciter, setReciter] = useState(() => Cookies.get("selectedReciter") || "ar.alafasy");
//   const [currentAyahIndex, setCurrentAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [translationAyah, setTranslationAyah] = useState("");
//   const [selectedLanguage, setSelectedLanguage] = useState(() => Cookies.get("selectedLanguage") || "en");
//   const [selectedEdition, setSelectedEdition] = useState(() => Cookies.get("selectedEdition") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(() => parseInt(Cookies.get("startAyah")) || 1);
//   const [verseLimit, setVerseLimit] = useState(() => parseInt(Cookies.get("verseLimit")) || 200);
//   const [autoPlayNextAyah, setAutoPlayNextAyah] = useState(() => Cookies.get("autoPlayNextAyah") === "true");

//   function getRandomVideoIndex() {
//     return Math.floor(Math.random() * videos.length);
//   }

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const videoInterval = setInterval(() => {
//       setCurrentVideoIndex(getRandomVideoIndex());
//     }, 5000);

//     return () => clearInterval(videoInterval);
//   }, []);

//   useEffect(() => {
//     const fetchQuran = async () => {
//       try {
//         const response = await axios.get(`http://api.alquran.cloud/v1/quran/${reciter}`);
//         const fetchedAyahs = response.data.data.surahs.flatMap((surah) => surah.ayahs);
//         setAyahs(fetchedAyahs);
//         setCurrentAyahIndex(0);
//         if (fetchedAyahs.length > 0) {
//           setAudioUrl(fetchedAyahs[0].audio);
//         }
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//       }
//     };

//     fetchQuran();
//   }, [reciter]);

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[currentAyahIndex].audio);
//     }

//     if (ayahs.length > 0 && selectedLanguage && selectedEdition) {
//       axios
//         .get(`http://api.alquran.cloud/v1/ayah/${ayahs[currentAyahIndex].number}/${selectedEdition}`)
//         .then((response) => {
//           setTranslationAyah(response.data.data.text);
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [currentAyahIndex, ayahs, selectedLanguage, selectedEdition]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlayNextAyah) {
//         setCurrentAyahIndex((prevIndex) => (prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1));
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, currentAyahIndex, autoPlayNextAyah]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selectedReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelectedLanguage(selectedLanguage);
//     Cookies.set("selectedLanguage", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelectedEdition(selectedEdition);
//     Cookies.set("selectedEdition", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlayNextAyah(autoPlay);
//     Cookies.set("autoPlayNextAyah", autoPlay ? "true" : "false", {
//       expires: 7,
//     });
//   };

//   const handleVerseLimitChange = (e) => {
//     const verseLimit = parseInt(e.target.value);
//     setVerseLimit(verseLimit);
//     Cookies.set("verseLimit", verseLimit, { expires: 7 });
//   };

//   return (
//     <>
//       <div id="slider-container" className="slider-container">
//         <div className="slider-content">
//           {ayahs.length > 0 && (
//             <>
//               <h1 className="ayah original">{ayahs[currentAyahIndex].text}</h1>
//               <h1 className="ayah translation">{translationAyah}</h1>
//             </>
//           )}
//         </div>
//         <img src={DoneFrame} alt="DoneFrame image" className="DoneFrame " />
//         {videos.length > 0 && (
//           <div className="video-container" style={{ overflow: "hidden" }}>
//             <video
//               src={videos[currentVideoIndex]}
//               autoPlay
//               muted
//               loop
//               className="slider-video"
//               style={{
//                 objectFit: "cover",
//               }}
//             />
//           </div>
//         )}

//         <div className="audio-controls">
//           {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//           <div className="reciter-selector">
//             <label htmlFor="reciter">Choose a reciter:</label>
//             <select id="reciter" value={reciter} onChange={handleReciterChange}>
//               {editionsWithAudio.map((edition) => (
//                 <option key={edition.identifier} value={edition.identifier}>
//                   {edition.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="language-selector">
//             <label htmlFor="language">Choose a language: </label>
//             <select
//               id="language"
//               value={selectedLanguage}
//               onChange={handleLanguageChange}
//             >
//               <option value="">Select Language</option>
//               {languageCodeToName.map((language) => (
//                 <option
//                   key={Object.keys(language)[0]}
//                   value={Object.keys(language)[0]}
//                 >
//                   {Object.values(language)[0]}
//                 </option>
//               ))}
//             </select>
//           </div>
//           {selectedLanguage && (
//             <div className="edition-selector">
//               <label htmlFor="edition">Choose an edition: </label>
//               <select
//                 id="edition"
//                 value={selectedEdition}
//                 onChange={handleEditionChange}
//               >
//                 <option value="">Select Edition</option>
//                 {editionsWithTranslation
//                   .filter((edition) => edition.language === selectedLanguage)
//                   .map((edition) => (
//                     <option key={edition.identifier} value={edition.identifier}>
//                       {edition.name}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           )}
//           <div className="start-ayah-selector">
//             <label htmlFor="startAyah">Start Ayah: </label>
//             <input
//               type="number"
//               id="startAyah"
//               value={startAyah}
//               onChange={handleStartAyahChange}
//             />
//           </div>
//           <div className="verse-limit-selector">
//             <label htmlFor="verseLimit">Verse Limit: </label>
//             <input
//               type="number"
//               id="verseLimit"
//               value={verseLimit}
//               onChange={handleVerseLimitChange}
//               max={200}
//             />
//           </div>
//           <div className="autoplay-next-ayah-selector">
//             <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//             <input
//               type="checkbox"
//               id="autoPlayNextAyah"
//               checked={autoPlayNextAyah}
//               onChange={handleAutoPlayNextAyahChange}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// // export default VideoSlider;
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import languageCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import videos from "./videos";

// const VideoSlider = () => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(getRandomVideoIndex());
//   const [ayahs, setAyahs] = useState([]);
//   const [reciter, setReciter] = useState(() => Cookies.get("selectedReciter") || "ar.alafasy");
//   const [currentAyahIndex, setCurrentAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [translationAyah, setTranslationAyah] = useState("");
//   const [selectedLanguage, setSelectedLanguage] = useState(() => Cookies.get("selectedLanguage") || "en");
//   const [selectedEdition, setSelectedEdition] = useState(() => Cookies.get("selectedEdition") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(() => parseInt(Cookies.get("startAyah")) || 1);
//   const [autoPlayNextAyah, setAutoPlayNextAyah] = useState(() => Cookies.get("autoPlayNextAyah") === "true");

//   function getRandomVideoIndex() {
//     return Math.floor(Math.random() * videos.length);
//   }

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const videoInterval = setInterval(() => {
//       setCurrentVideoIndex(getRandomVideoIndex());
//     }, 15000);

//     return () => clearInterval(videoInterval);
//   }, []);

//   useEffect(() => {
//     const fetchQuran = async () => {
//       try {
//         const response = await axios.get(`https://api.alquran.cloud/v1/quran/${reciter}`);
//         const quranData = response.data.data;
//         const allAyahs = quranData.surahs.flatMap((surah) => surah.ayahs);

//         // Filter the ayahs starting from the selected ayah index
//         const selectedAyahs = allAyahs.filter((ayah) => ayah.number >= startAyah);

//         setAyahs(selectedAyahs);
//         setCurrentAyahIndex(0);
//         setAudioUrl(selectedAyahs[0].audio);
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//       }
//     };

//     fetchQuran();
//   }, [reciter, startAyah]);

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[currentAyahIndex].audio);
//     }

//     if (ayahs.length > 0 && selectedLanguage && selectedEdition) {
//       axios
//         .get(`https://api.alquran.cloud/v1/ayah/${ayahs[currentAyahIndex].number}/${selectedEdition}`)
//         .then((response) => {
//           setTranslationAyah(response.data.data.text);
//           console.log(response.data.data)
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [currentAyahIndex, ayahs, selectedLanguage, selectedEdition]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlayNextAyah) {
//         setCurrentAyahIndex((prevIndex) => (prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1));
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, currentAyahIndex, autoPlayNextAyah]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selectedReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelectedLanguage(selectedLanguage);
//     Cookies.set("selectedLanguage", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelectedEdition(selectedEdition);
//     Cookies.set("selectedEdition", selectedEdition, { expires: 7 });
//   };
//   const handleStartAyahChange = (e) => {
//     const inputValue = e.target.value.trim();
//     if (inputValue === "" || isNaN(inputValue)) {
//       // Handle invalid input value (e.g., show an error message)
//       // For now, you can set a default value or do nothing
//       // setStartAyah(1); // Example of setting a default value
//       return; // Exit early if input is empty or not a number
//     }

//     const startAyah = parseInt(inputValue);
//     if (startAyah < 1 || startAyah > ayahs.length) {
//       // Handle out-of-range input values (e.g., show an error message)
//       // For now, you can set a default value or do nothing
//       // setStartAyah(1); // Example of setting a default value
//       return; // Exit early if input is out of range
//     }

//     // Input value is valid, update state and cookies
//     setStartAyah(startAyah);
//     setCurrentAyahIndex(startAyah - 1);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlayNextAyah(autoPlay);
//     Cookies.set("autoPlayNextAyah", autoPlay ? "true" : "false", {
//       expires: 7,
//     });
//   };

//   return (
//     <>
//       <div id="slider-container" className="slider-container">
//         <div className="slider-content">
//           {ayahs.length > 0 && (
//             <>
//               <h1 className="ayah original">{ayahs[currentAyahIndex]?.text}</h1>
//               <h1 className="ayah translation">{translationAyah}</h1>
//             </>
//           )}
//         </div>
//         <img src={DoneFrame} alt="DoneFrame image" className="DoneFrame " />
//         {videos.length > 0 && (
//           <div className="video-container" style={{ overflow: "hidden" }}>
//             <video
//               src={videos[currentVideoIndex]}
//               autoPlay
//               muted
//               loop
//               className="slider-video"
//               style={{
//                 objectFit: "cover",
//               }}
//             />
//           </div>
//         )}

//         <div className="audio-controls">
//           {ayahs?.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//           <div className="reciter-selector">
//             <label htmlFor="reciter">Choose a reciter:</label>
//             <select id="reciter" value={reciter} onChange={handleReciterChange}>
//               {editionsWithAudio?.map((edition) => (
//                 <option key={edition.identifier} value={edition.identifier}>
//                   {edition.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="language-selector">
//             <label htmlFor="language">Choose a language: </label>
//             <select
//               id="language"
//               value={selectedLanguage}
//               onChange={handleLanguageChange}
//             >
//               <option value="">Select Language</option>
//               {languageCodeToName.map((language) => (
//                 <option
//                   key={Object.keys(language)[0]}
//                   value={Object.keys(language)[0]}
//                 >
//                   {Object.values(language)[0]}
//                 </option>
//               ))}
//             </select>
//           </div>
//           {selectedLanguage && (
//             <div className="edition-selector">
//               <label htmlFor="edition">Choose an edition: </label>
//               <select
//                 id="edition"
//                 value={selectedEdition}
//                 onChange={handleEditionChange}
//               >
//                 <option value="">Select Edition</option>
//                 {editionsWithTranslation
//                   .filter((edition) => edition.language === selectedLanguage)
//                   .map((edition) => (
//                     <option key={edition.identifier} value={edition.identifier}>
//                       {edition.name}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           )}
//           <div className="start-ayah-selector">
//             <label htmlFor="startAyah">Start Ayah: </label>
//             <input
//               type="number"
//               id="startAyah"
//               value={startAyah}
//               onChange={handleStartAyahChange}
//             />
//           </div>
//           <div className="autoplay-next-ayah-selector">
//             <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//             <input
//               type="checkbox"
//               id="autoPlayNextAyah"
//               checked={autoPlayNextAyah}
//               onChange={handleAutoPlayNextAyahChange}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// // export default VideoSlider;
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Cookies from "js-cookie"; // Importing Cookies for storing the selected translation
// import "./ImageSlider.css"; // Import CSS file for styling
// import languageCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import videos from "./videos";

// const VideoSlider = () => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(
//     getRandomVideoIndex()
//   );
//   const [ayahs, setAyahs] = useState([]); // State to hold the Quranic verses
//   const [reciter, setReciter] = useState(
//     () => Cookies.get("selectedReciter") || "ar.alafasy"
//   ); // Default reciter
//   const [currentAyahIndex, setCurrentAyahIndex] = useState(0); // State to keep track of current ayah
//   const [audioUrl, setAudioUrl] = useState(""); // State to hold the audio URL
//   const audioRef = useRef(null); // Ref to access the audio element
//   const [editionsWithAudio, setEditionsWithAudio] = useState([]); // State for editions with audio
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]); // State for editions with translation
//   const [translationAyah, setTranslationAyah] = useState(""); // State to hold the translation text
//   const [selectedLanguage, setSelectedLanguage] = useState(
//     () => Cookies.get("selectedLanguage") || "en"
//   ); // Default language set to English
//   const [selectedEdition, setSelectedEdition] = useState(
//     () => Cookies.get("selectedEdition") || "en.sahih"
//   ); // Default edition set to English translation
//   const [startAyah, setStartAyah] = useState(
//     () => parseInt(Cookies.get("startAyah")) || 1
//   ); // State to hold the starting ayah
//   const [autoPlayNextAyah, setAutoPlayNextAyah] = useState(
//     () => Cookies.get("autoPlayNextAyah") === "true"
//   ); // State to hold the auto play next ayah option

//   // Function to get a random index from the videos array
//   function getRandomVideoIndex() {
//     return Math.floor(Math.random() * videos.length);
//   }

//   // Fetch ayahs function
//   const fetchAyahs = async (start, limit) => {
//     try {
//       const ayahPromises = [];
//       const endAyah = start + limit - 1; // Calculate the end ayah

//       // Fetch consecutive ayahs based on start and limit
//       for (let i = start; i <= endAyah; i++) {
//         ayahPromises.push(
//           axios.get(`http://api.alquran.cloud/v1/ayah/${i}/${reciter}`)
//         );
//       }

//       const responses = await Promise.all(ayahPromises);
//       const fetchedAyahs = responses.map((response) => response.data.data);

//       return fetchedAyahs;
//     } catch (error) {
//       console.error("Error fetching Quranic verses:", error);
//     }
//   };

//   // Fetch editions with audio and translation
//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEditions();
//   }, []);

//   // Video slider logic
//   useEffect(() => {
//     const videoInterval = setInterval(() => {
//       setCurrentVideoIndex(getRandomVideoIndex());
//     }, 5000); // Adjust the interval for video sliding to 5 seconds

//     return () => clearInterval(videoInterval);
//   }, []);

//   // Fetch ayahs on reciter change or when startAyah changes
//   useEffect(() => {
//     const loadInitialAyahs = async () => {
//       const initialAyahs = await fetchAyahs(startAyah, 10);
//       setAyahs(initialAyahs);
//       setCurrentAyahIndex(0); // Reset to the first ayah
//       if (initialAyahs.length > 0) {
//         setAudioUrl(initialAyahs[0].audio); // Set the initial audio URL
//       }
//     };

//     loadInitialAyahs();
//   }, [reciter, startAyah]);

//   // Update audio URL and translation when current ayah changes
//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[currentAyahIndex].audio); // Update the audio URL
//     }

//     if (ayahs.length > 0 && selectedLanguage && selectedEdition) {
//       axios
//         .get(
//           `http://api.alquran.cloud/v1/ayah/${ayahs[currentAyahIndex]?.number}/${selectedEdition}`
//         )
//         .then((response) => {
//           setTranslationAyah(response.data.data.text); // Update translation
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [currentAyahIndex, ayahs, selectedLanguage, selectedEdition]);

//   // Fetch next set of ayahs when nearing the end of the current set
//   useEffect(() => {
//     const fetchMoreAyahs = async () => {
//       const additionalAyahs = await fetchAyahs(
//         ayahs[ayahs.length - 1]?.number + 1,
//         10
//       );
//       setAyahs((prevAyahs) => [...prevAyahs, ...additionalAyahs]);
//     };

//     if (currentAyahIndex >= ayahs.length - 2) {
//       fetchMoreAyahs();
//     }
//   }, [currentAyahIndex, ayahs, reciter]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlayNextAyah) {
//         setCurrentAyahIndex((prevIndex) =>
//           prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, currentAyahIndex, autoPlayNextAyah]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selectedReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelectedLanguage(selectedLanguage);
//     Cookies.set("selectedLanguage", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelectedEdition(selectedEdition);
//     Cookies.set("selectedEdition", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlayNextAyah(autoPlay);
//     Cookies.set("autoPlayNextAyah", autoPlay ? "true" : "false", {
//       expires: 7,
//     });
//   };
//   const convertToArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };
//   return (
//     <>
//       <div id="slider-container" className="slider-container">
//         <div className="slider-content">
//           {ayahs.length > 0 && (
//             <>
//               <h1 className="ayah original">
//                 {ayahs[currentAyahIndex].text} (
//                 {convertToArabicNumerals(
//                   ayahs[currentAyahIndex]?.numberInSurah
//                 )}
//                 )
//                 {ayahs[currentAyahIndex].number}
//               </h1>
//               <h1 className="ayah translation">{translationAyah}</h1>
//             </>
//           )}
//         </div>
//         <img src={DoneFrame} alt="DoneFrame image" className="DoneFrame " />
//         {videos.length > 0 && (
//           <div className="video-container" style={{ overflow: "hidden" }}>
//             <video
//               src={videos[currentVideoIndex]}
//               autoPlay
//               muted
//               loop
//               className="slider-video"
//               style={{
//                 objectFit: "cover",
//               }}
//             />
//           </div>
//         )}

//         <div className="audio-controls">
//           {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//           <div className="reciter-selector">
//             <label htmlFor="reciter">Choose a reciter:</label>
//             <select id="reciter" value={reciter} onChange={handleReciterChange}>
//               {editionsWithAudio.map((edition) => (
//                 <option key={edition.identifier} value={edition.identifier}>
//                   {edition.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="language-selector">
//             <label htmlFor="language">Choose a language: </label>
//             <select
//               id="language"
//               value={selectedLanguage}
//               onChange={handleLanguageChange}
//             >
//               <option value="">Select Language</option>
//               {languageCodeToName.map((language) => (
//                 <option
//                   key={Object.keys(language)[0]}
//                   value={Object.keys(language)[0]}
//                 >
//                   {Object.values(language)[0]}
//                 </option>
//               ))}
//             </select>
//           </div>
//           {selectedLanguage && (
//             <div className="edition-selector">
//               <label htmlFor="edition">Choose an edition: </label>
//               <select
//                 id="edition"
//                 value={selectedEdition}
//                 onChange={handleEditionChange}
//               >
//                 <option value="">Select Edition</option>
//                 {editionsWithTranslation
//                   .filter((edition) => edition.language === selectedLanguage)
//                   .map((edition) => (
//                     <option key={edition.identifier} value={edition.identifier}>
//                       {edition.name}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           )}
//           <div className="start-ayah-selector">
//             <label htmlFor="startAyah">Start Ayah: </label>
//             <input
//               type="number"
//               id="startAyah"
//               value={startAyah}
//               onChange={handleStartAyahChange}
//             />
//           </div>
//           <div className="autoplay-next-ayah-selector">
//             <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//             <input
//               type="checkbox"
//               id="autoPlayNextAyah"
//               checked={autoPlayNextAyah}
//               onChange={handleAutoPlayNextAyahChange}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import videos from "./videos";

// const VideoSlider = () => {
//   const [curVidIndex, setCurVidIndex] = useState(getRandomVidIndex());
//   const [ayahs, setAyahs] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(
//     parseInt(Cookies.get("startAyah")) || 1
//   );
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   function getRandomVidIndex() {
//     return Math.floor(Math.random() * videos.length);
//   }

//   const fetchAyahs = useCallback(
//     async (start, limit) => {
//       try {
//         const endAyah = start + limit - 1;
//         const responses = await Promise.all(
//           Array.from({ length: limit }, (_, i) =>
//             axios.get(
//               `http://api.alquran.cloud/v1/ayah/${start + i}/${reciter}`
//             )
//           )
//         );
//         return responses.map((response) => response.data.data);
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//         return [];
//       }
//     },
//     [reciter]
//   );

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const vidInterval = setInterval(() => {
//       setCurVidIndex(getRandomVidIndex());
//     }, 5000);
//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadInitAyahs = async () => {
//       const initialAyahs = await fetchAyahs(startAyah, 10);
//       setAyahs(initialAyahs);
//       setCurAyahIndex(0);
//       if (initialAyahs.length > 0) {
//         setAudioUrl(initialAyahs[0].audio);
//       }
//     };
//     loadInitAyahs();
//   }, [reciter, startAyah, fetchAyahs]);

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[curAyahIndex].audio);
//     }
//     if (ayahs.length > 0 && selLang && selEd) {
//       axios
//         .get(
//           `http://api.alquran.cloud/v1/ayah/${ayahs[curAyahIndex]?.number}/${selEd}`
//         )
//         .then((response) => {
//           setTransAyah(response.data.data.text);
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [curAyahIndex, ayahs, selLang, selEd]);

//   useEffect(() => {
//     const fetchMoreAyahs = async () => {
//       const additionalAyahs = await fetchAyahs(
//         ayahs[ayahs.length - 1]?.number + 1,
//         10
//       );
//       setAyahs((prevAyahs) => [...prevAyahs, ...additionalAyahs]);
//     };
//     if (curAyahIndex >= ayahs.length - 2) {
//       fetchMoreAyahs();
//     }
//   }, [curAyahIndex, ayahs, fetchAyahs]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {ayahs.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {ayahs[curAyahIndex].text} (
//               {toArabicNumerals(ayahs[curAyahIndex]?.numberInSurah)})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {videos.length > 0 && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={videos[curVidIndex]}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={startAyah}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// // export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [ayahs, setAyahs] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(
//     parseInt(Cookies.get("startAyah")) || 1
//   );
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, 'videos/');
//     try {
//       const res = await listAll(storageRef);
//       const randomItem = res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error('Error fetching video URL:', error);
//       return "";
//     }
//   };

//   const fetchAyahs = useCallback(
//     async (start, limit) => {
//       try {
//         const endAyah = start + limit - 1;
//         const responses = await Promise.all(
//           Array.from({ length: limit }, (_, i) =>
//             axios.get(
//               `http://api.alquran.cloud/v1/ayah/${start + i}/${reciter}`
//             )
//           )
//         );
//         return responses.map((response) => response.data.data);
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//         return [];
//       }
//     },
//     [reciter]
//   );

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadInitAyahs = async () => {
//       const initialAyahs = await fetchAyahs(startAyah, 10);
//       setAyahs(initialAyahs);
//       setCurAyahIndex(0);
//       if (initialAyahs.length > 0) {
//         setAudioUrl(initialAyahs[0].audio);
//       }
//     };
//     loadInitAyahs();
//   }, [reciter, startAyah, fetchAyahs]);

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[curAyahIndex].audio);
//     }
//     if (ayahs.length > 0 && selLang && selEd) {
//       axios
//         .get(
//           `http://api.alquran.cloud/v1/ayah/${ayahs[curAyahIndex]?.number}/${selEd}`
//         )
//         .then((response) => {
//           setTransAyah(response.data.data.text);
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [curAyahIndex, ayahs, selLang, selEd]);

//   useEffect(() => {
//     const fetchMoreAyahs = async () => {
//       const additionalAyahs = await fetchAyahs(
//         ayahs[ayahs.length - 1]?.number + 1,
//         10
//       );
//       setAyahs((prevAyahs) => [...prevAyahs, ...additionalAyahs]);
//     };
//     if (curAyahIndex >= ayahs.length - 2) {
//       fetchMoreAyahs();
//     }
//   }, [curAyahIndex, ayahs, fetchAyahs]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {ayahs.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {ayahs[curAyahIndex].text} (
//               {toArabicNumerals(ayahs[curAyahIndex]?.numberInSurah)})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={startAyah}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [ayahs, setAyahs] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(
//     parseInt(Cookies.get("startAyah")) || 1
//   );
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, 'videos/');
//     try {
//       const res = await listAll(storageRef);
//       const randomItem = res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error('Error fetching video URL:', error);
//       return "";
//     }
//   };

//   const fetchAyahs = useCallback(
//     async (start, limit) => {
//       try {
//         const endAyah = start + limit - 1;
//         const responses = await Promise.all(
//           Array.from({ length: limit }, (_, i) =>
//             axios.get(
//               `http://api.alquran.cloud/v1/ayah/${start + i}/${reciter}`
//             )
//           )
//         );
//         return responses.map((response) => response.data.data);
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//         return [];
//       }
//     },
//     [reciter]
//   );

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadInitAyahs = async () => {
//       const initialAyahs = await fetchAyahs(startAyah, 10);
//       setAyahs(initialAyahs);
//       setCurAyahIndex(0);
//       if (initialAyahs.length > 0) {
//         setAudioUrl(initialAyahs[0].audio);
//       }
//     };
//     loadInitAyahs();
//   }, [reciter, startAyah, fetchAyahs]);

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[curAyahIndex].audio);
//     }
//     if (ayahs.length > 0 && selLang && selEd) {
//       axios
//         .get(
//           `http://api.alquran.cloud/v1/ayah/${ayahs[curAyahIndex]?.number}/${selEd}`
//         )
//         .then((response) => {
//           setTransAyah(response.data.data.text);
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [curAyahIndex, ayahs, selLang, selEd]);

//   useEffect(() => {
//     const fetchMoreAyahs = async () => {
//       const additionalAyahs = await fetchAyahs(
//         ayahs[ayahs.length - 1]?.number + 1,
//         10
//       );
//       setAyahs((prevAyahs) => [...prevAyahs, ...additionalAyahs]);
//     };
//     if (curAyahIndex >= ayahs.length - 2) {
//       fetchMoreAyahs();
//     }
//   }, [curAyahIndex, ayahs, fetchAyahs]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setStartAyah(ayahs[curAyahIndex].number);
//     }
//   }, [curAyahIndex, ayahs]);

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {ayahs.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {ayahs[curAyahIndex].text} (
//               {toArabicNumerals(ayahs[curAyahIndex]?.numberInSurah)} / {ayahs[curAyahIndex]?.number})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={startAyah}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [ayahs, setAyahs] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(
//     parseInt(Cookies.get("startAyah")) || 1
//   );
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, 'videos/');
//     try {
//       const res = await listAll(storageRef);
//       const randomItem = res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error('Error fetching video URL:', error);
//       return "";
//     }
//   };

//   const fetchAyahs = useCallback(
//     async (start, limit) => {
//       try {
//         const endAyah = start + limit - 1;
//         const responses = await Promise.all(
//           Array.from({ length: limit }, (_, i) =>
//             axios.get(
//               `http://api.alquran.cloud/v1/ayah/${start + i}/${reciter}`
//             )
//           )
//         );
//         return responses.map((response) => response.data.data);
//       } catch (error) {
//         console.error("Error fetching Quranic verses:", error);
//         return [];
//       }
//     },
//     [reciter]
//   );

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadInitAyahs = async () => {
//       const initialAyahs = await fetchAyahs(startAyah, 10);
//       setAyahs(initialAyahs);
//       setCurAyahIndex(0);
//       if (initialAyahs.length > 0) {
//         setAudioUrl(initialAyahs[0].audio);
//       }
//     };
//     loadInitAyahs();
//   }, [reciter, startAyah, fetchAyahs]);

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setAudioUrl(ayahs[curAyahIndex].audio);
//     }
//     if (ayahs.length > 0 && selLang && selEd) {
//       axios
//         .get(
//           `http://api.alquran.cloud/v1/ayah/${ayahs[curAyahIndex]?.number}/${selEd}`
//         )
//         .then((response) => {
//           setTransAyah(response.data.data.text);
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [curAyahIndex, ayahs, selLang, selEd]);

//   useEffect(() => {
//     const fetchMoreAyahs = async () => {
//       const additionalAyahs = await fetchAyahs(
//         ayahs[ayahs.length - 1]?.number + 1,
//         10
//       );
//       setAyahs((prevAyahs) => [...prevAyahs, ...additionalAyahs]);
//     };
//     if (curAyahIndex >= ayahs.length - 2) {
//       fetchMoreAyahs();
//     }
//   }, [curAyahIndex, ayahs, fetchAyahs]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === ayahs.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [ayahs, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   useEffect(() => {
//     if (ayahs.length > 0) {
//       setStartAyah(ayahs[curAyahIndex].number);
//       Cookies.set("startAyah", ayahs[curAyahIndex].number, { expires: 7 });
//     }
//   }, [curAyahIndex, ayahs]);

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {ayahs.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {ayahs[curAyahIndex].text} (
//               {toArabicNumerals(ayahs[curAyahIndex]?.numberInSurah)} / {ayahs[curAyahIndex]?.number})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {ayahs.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={startAyah}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// // export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [quranData, setQuranData] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(
//     parseInt(Cookies.get("startAyah")) || 1
//   );
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, 'videos/');
//     try {
//       const res = await listAll(storageRef);
//       const randomItem = res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error('Error fetching video URL:', error);
//       return "";
//     }
//   };

//   const fetchQuran = useCallback(async (identifier) => {
//     try {
//       const response = await axios.get(`https://api.alquran.cloud/v1/quran/${identifier}`);
//       return response.data.data.surahs.flatMap(surah => surah.ayahs);
//     } catch (error) {
//       console.error("Error fetching Quran:", error);
//       return [];
//     }
//   }, []);

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadQuran = async () => {
//       const quranAyahs = await fetchQuran(reciter);
//       setQuranData(quranAyahs);
//       setCurAyahIndex(0);
//       if (quranAyahs.length > 0) {
//         setAudioUrl(quranAyahs[0].audio);
//       }
//     };
//     loadQuran();
//   }, [reciter, fetchQuran]);

//   useEffect(() => {
//     if (quranData.length > 0) {
//       setAudioUrl(quranData[curAyahIndex].audio);
//     }
//     if (quranData.length > 0 && selLang && selEd) {
//       axios
//         .get(`http://api.alquran.cloud/v1/ayah/${quranData[curAyahIndex]?.number}/${selEd}`)
//         .then((response) => {
//           setTransAyah(response.data.data.text);
//         })
//         .catch((error) => {
//           console.error("Error fetching translation Ayah:", error);
//         });
//     }
//   }, [curAyahIndex, quranData, selLang, selEd]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === quranData.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [quranData, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   useEffect(() => {
//     if (quranData.length > 0) {
//       setStartAyah(quranData[curAyahIndex].number);
//       Cookies.set("startAyah", quranData[curAyahIndex].number, { expires: 7 });
//     }
//   }, [curAyahIndex, quranData]);

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {quranData.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {quranData[curAyahIndex].text} (
//               {toArabicNumerals(quranData[curAyahIndex]?.numberInSurah)} / {quranData[curAyahIndex]?.number})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {quranData.length > 0 && <audio ref={audioRef} controls src={audioUrl} />}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={startAyah}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// // export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [quranData, setQuranData] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(0);
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [startAyah, setStartAyah] = useState(
//     parseInt(Cookies.get("startAyah")) || 1
//   );
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, "videos/");
//     try {
//       const res = await listAll(storageRef);
//       const randomItem =
//         res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error("Error fetching video URL:", error);
//       return "";
//     }
//   };

//   const fetchQuran = useCallback(async (reciter, edition) => {
//     try {
//       const response = await axios.get(
//         `https://api.alquran.cloud/v1/quran/${reciter}`
//       );
//       const translationResponse = await axios.get(
//         `https://api.alquran.cloud/v1/quran/${edition}`
//       );
//       const ayahs = response.data.data.surahs.flatMap((surah) => surah.ayahs);
//       const translations = translationResponse.data.data.surahs.flatMap(
//         (surah) => surah.ayahs
//       );
//       const combinedData = ayahs.map((ayah, index) => ({
//         ...ayah,
//         translation: translations[index].text,
//       }));
//       return combinedData;
//     } catch (error) {
//       console.error("Error fetching Quran:", error);
//       return [];
//     }
//   }, []);

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadQuran = async () => {
//       const quranAyahs = await fetchQuran(reciter, selEd);
//       setQuranData(quranAyahs);
//       setCurAyahIndex(0);
//       if (quranAyahs.length > 0) {
//         setAudioUrl(quranAyahs[0].audio);
//         setTransAyah(quranAyahs[0].translation);
//       }
//     };
//     loadQuran();
//   }, [reciter, selEd, fetchQuran]);

//   useEffect(() => {
//     if (quranData.length > 0) {
//       setAudioUrl(quranData[curAyahIndex].audio);
//       setTransAyah(quranData[curAyahIndex].translation);
//     }
//   }, [curAyahIndex, quranData]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === quranData.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [quranData, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     setStartAyah(startAyah);
//     Cookies.set("startAyah", startAyah, { expires: 7 });
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   useEffect(() => {
//     if (quranData.length > 0) {
//       setStartAyah(quranData[curAyahIndex].number);
//       Cookies.set("startAyah", quranData[curAyahIndex].number, { expires: 7 });
//     }
//   }, [curAyahIndex, quranData]);

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {quranData.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {quranData[curAyahIndex].text} (
//               {toArabicNumerals(quranData[curAyahIndex]?.numberInSurah)} /{" "}
//               {quranData[curAyahIndex]?.number})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {quranData.length > 0 && (
//           <audio ref={audioRef} controls src={audioUrl} />
//         )}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={startAyah}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// // export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [quranData, setQuranData] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(
//     parseInt(Cookies.get("startAyah")) - 1 || 0
//   );
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, "videos/");
//     try {
//       const res = await listAll(storageRef);
//       const randomItem =
//         res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error("Error fetching video URL:", error);
//       return "";
//     }
//   };

//   const fetchQuran = useCallback(async (reciter, edition) => {
//     try {
//       const response = await axios.get(
//         `https://api.alquran.cloud/v1/quran/${reciter}`
//       );
//       const translationResponse = await axios.get(
//         `https://api.alquran.cloud/v1/quran/${edition}`
//       );
//       const ayahs = response.data.data.surahs.flatMap((surah) => surah.ayahs);
//       const translations = translationResponse.data.data.surahs.flatMap(
//         (surah) => surah.ayahs
//       );
//       const combinedData = ayahs.map((ayah, index) => ({
//         ...ayah,
//         translation: translations[index].text,
//       }));
//       return combinedData;
//     } catch (error) {
//       console.error("Error fetching Quran:", error);
//       return [];
//     }
//   }, []);

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadQuran = async () => {
//       const quranAyahs = await fetchQuran(reciter, selEd);
//       setQuranData(quranAyahs);
//       setCurAyahIndex(0);
//       if (quranAyahs.length > 0) {
//         setAudioUrl(quranAyahs[0].audio);
//         setTransAyah(quranAyahs[0].translation);
//       }
//     };
//     loadQuran();
//   }, [reciter, selEd, fetchQuran]);

//   useEffect(() => {
//     if (quranData.length > 0) {
//       setAudioUrl(quranData[curAyahIndex].audio);
//       setTransAyah(quranData[curAyahIndex].translation);
//     }
//   }, [curAyahIndex, quranData]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === quranData.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [quranData, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     if (!isNaN(startAyah) && startAyah > 0 && startAyah <= quranData.length) {
//       setCurAyahIndex(startAyah - 1);
//       Cookies.set("startAyah", startAyah, { expires: 7 });
//     }
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {quranData.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {quranData[curAyahIndex].text} (
//               {toArabicNumerals(quranData[curAyahIndex]?.numberInSurah)} /{" "}
//               {quranData[curAyahIndex]?.number})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {quranData.length > 0 && (
//           <audio ref={audioRef} controls src={audioUrl} />
//         )}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={curAyahIndex + 1}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoSlider;
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import "./ImageSlider.css";
// import langCodeToName from "./languageCodeToName";
// import DoneFrame from "./DoneFrame.png";
// import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
// import app from "../firebase";

// const VideoSlider = () => {
//   const [curVidUrl, setCurVidUrl] = useState("");
//   const [quranData, setQuranData] = useState([]);
//   const [reciter, setReciter] = useState(
//     Cookies.get("selReciter") || "ar.alafasy"
//   );
//   const [curAyahIndex, setCurAyahIndex] = useState(
//     parseInt(Cookies.get("startAyah")) - 1 || 0
//   );
//   const [audioUrl, setAudioUrl] = useState("");
//   const audioRef = useRef(null);
//   const [audioEds, setAudioEds] = useState([]);
//   const [transEds, setTransEds] = useState([]);
//   const [transAyah, setTransAyah] = useState("");
//   const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
//   const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
//   const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");

//   const getRandomVidUrl = async () => {
//     const storage = getStorage(app);
//     const storageRef = ref(storage, "videos/");
//     try {
//       const res = await listAll(storageRef);
//       const randomItem =
//         res.items[Math.floor(Math.random() * res.items.length)];
//       const url = await getDownloadURL(randomItem);
//       return url;
//     } catch (error) {
//       console.error("Error fetching video URL:", error);
//       return "";
//     }
//   };

//   const fetchQuran = useCallback(async (reciter, edition) => {
//     try {
//       const response = await axios.get(
//         `https://api.alquran.cloud/v1/quran/${reciter}`
//       );
//       const translationResponse = await axios.get(
//         `https://api.alquran.cloud/v1/quran/${edition}`
//       );
//       const ayahs = response.data.data.surahs.flatMap((surah) => surah.ayahs);
//       const translations = translationResponse.data.data.surahs.flatMap(
//         (surah) => surah.ayahs
//       );
//       const combinedData = ayahs.map((ayah, index) => ({
//         ...ayah,
//         translation: translations[index].text,
//       }));
//       return combinedData;
//     } catch (error) {
//       console.error("Error fetching Quran:", error);
//       return [];
//     }
//   }, []);

//   useEffect(() => {
//     const fetchEds = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setAudioEds(audioData.data.data);
//         setTransEds(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };
//     fetchEds();
//   }, []);

//   useEffect(() => {
//     const loadRandomVid = async () => {
//       const randomVidUrl = await getRandomVidUrl();
//       setCurVidUrl(randomVidUrl);
//     };
//     loadRandomVid();

//     const vidInterval = setInterval(() => {
//       loadRandomVid();
//     }, 5000);

//     return () => clearInterval(vidInterval);
//   }, []);

//   useEffect(() => {
//     const loadQuran = async () => {
//       const quranAyahs = await fetchQuran(reciter, selEd);
//       setQuranData(quranAyahs);
//       setCurAyahIndex(parseInt(Cookies.get("startAyah")) - 1 || 0);
//       if (quranAyahs.length > 0) {
//         setAudioUrl(quranAyahs[curAyahIndex].audio);
//         setTransAyah(quranAyahs[curAyahIndex].translation);
//       }
//     };
//     loadQuran();
//   }, [reciter, selEd, fetchQuran]);

//   useEffect(() => {
//     if (quranData.length > 0) {
//       setAudioUrl(quranData[curAyahIndex].audio);
//       setTransAyah(quranData[curAyahIndex].translation);
//     }
//   }, [curAyahIndex, quranData]);

//   useEffect(() => {
//     const handleAudioEnded = () => {
//       if (autoPlay) {
//         setCurAyahIndex((prevIndex) =>
//           prevIndex === quranData.length - 1 ? 0 : prevIndex + 1
//         );
//       }
//     };

//     const handleAudioPlay = () => {
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener("ended", handleAudioEnded);
//       audioElement.addEventListener("loadeddata", handleAudioPlay);

//       return () => {
//         audioElement.removeEventListener("ended", handleAudioEnded);
//         audioElement.removeEventListener("loadeddata", handleAudioPlay);
//       };
//     }
//   }, [quranData, curAyahIndex, autoPlay]);

//   const handleReciterChange = (e) => {
//     const selectedReciter = e.target.value;
//     setReciter(selectedReciter);
//     Cookies.set("selReciter", selectedReciter, { expires: 7 });
//   };

//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     setSelLang(selectedLanguage);
//     Cookies.set("selLang", selectedLanguage, { expires: 7 });
//   };

//   const handleEditionChange = (e) => {
//     const selectedEdition = e.target.value;
//     setSelEd(selectedEdition);
//     Cookies.set("selEd", selectedEdition, { expires: 7 });
//   };

//   const handleStartAyahChange = (e) => {
//     const startAyah = parseInt(e.target.value);
//     if (!isNaN(startAyah) && startAyah > 0 && startAyah <= quranData.length) {
//       setCurAyahIndex(startAyah - 1);
//       Cookies.set("startAyah", startAyah, { expires: 7 });
//     }
//   };

//   const handleAutoPlayNextAyahChange = (e) => {
//     const autoPlay = e.target.checked;
//     setAutoPlay(autoPlay);
//     Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
//   };

//   const toArabicNumerals = (number) => {
//     const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//     return number
//       .toString()
//       .split("")
//       .map((digit) => arabicNumerals[digit])
//       .join("");
//   };

//   return (
//     <div id="vid-container" className="vid-container">
//       <div className="vid-content">
//         {quranData.length > 0 && (
//           <>
//             <h1 className="ayah org">
//               {quranData[curAyahIndex].text} (
//               {toArabicNumerals(quranData[curAyahIndex]?.numberInSurah)} /{" "}
//               {quranData[curAyahIndex]?.number})
//             </h1>
//             <h1 className="ayah trans">{transAyah}</h1>
//           </>
//         )}
//       </div>
//       <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
//       {curVidUrl && (
//         <div className="vid-container" style={{ overflow: "hidden" }}>
//           <video
//             src={curVidUrl}
//             autoPlay
//             muted
//             loop
//             className="vid"
//             style={{ objectFit: "cover" }}
//           />
//         </div>
//       )}

//       <div className="aud-controls">
//         {quranData.length > 0 && (
//           <audio ref={audioRef} controls src={audioUrl} />
//         )}

//         <div className="rec-selector">
//           <label htmlFor="rec">Choose reciter:</label>
//           <select id="rec" value={reciter} onChange={handleReciterChange}>
//             {audioEds.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="lang-selector">
//           <label htmlFor="lang">Choose lang: </label>
//           <select id="lang" value={selLang} onChange={handleLanguageChange}>
//             <option value="">Select Lang</option>
//             {langCodeToName.map((language) => (
//               <option
//                 key={Object.keys(language)[0]}
//                 value={Object.keys(language)[0]}
//               >
//                 {Object.values(language)[0]}
//               </option>
//             ))}
//           </select>
//         </div>
//         {selLang && (
//           <div className="ed-selector">
//             <label htmlFor="ed">Choose ed: </label>
//             <select id="ed" value={selEd} onChange={handleEditionChange}>
//               <option value="">Select Edition</option>
//               {transEds
//                 .filter((edition) => edition.language === selLang)
//                 .map((edition) => (
//                   <option key={edition.identifier} value={edition.identifier}>
//                     {edition.name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//         <div className="start-ayah-selector">
//           <label htmlFor="startAyah">Start Ayah: </label>
//           <input
//             type="number"
//             id="startAyah"
//             value={curAyahIndex + 1}
//             onChange={handleStartAyahChange}
//           />
//         </div>
//         <div className="autoplay-next-ayah-selector">
//           <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
//           <input
//             type="checkbox"
//             id="autoPlayNextAyah"
//             checked={autoPlay}
//             onChange={handleAutoPlayNextAyahChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoSlider;
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./ImageSlider.css";
import langCodeToName from "./languageCodeToName";
import DoneFrame from "./DoneFrame.png";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import app from "../firebase";

const VideoSlider = () => {
  const [curVidUrl, setCurVidUrl] = useState("");
  const [quranData, setQuranData] = useState([]);
  const [reciter, setReciter] = useState(
    Cookies.get("selReciter") || "ar.alafasy"
  );
  const [curAyahIndex, setCurAyahIndex] = useState(
    parseInt(Cookies.get("startAyah")) - 1 || 0
  );
  const [audioUrl, setAudioUrl] = useState("");
  const audioRef = useRef(null);
  const [audioEds, setAudioEds] = useState([]);
  const [transEds, setTransEds] = useState([]);
  const [transAyah, setTransAyah] = useState("");
  const [selLang, setSelLang] = useState(Cookies.get("selLang") || "en");
  const [selEd, setSelEd] = useState(Cookies.get("selEd") || "en.sahih");
  const [autoPlay, setAutoPlay] = useState(Cookies.get("autoPlay") === "true");
  const [visible, setVisible] = useState(false); // Add state for visibility

  const getRandomVidUrl = async () => {
    const storage = getStorage(app);
    const storageRef = ref(storage, "videos/");
    try {
      const res = await listAll(storageRef);
      const randomItem =
        res.items[Math.floor(Math.random() * res.items.length)];
      const url = await getDownloadURL(randomItem);
      return url;
    } catch (error) {
      console.error("Error fetching video URL:", error);
      return "";
    }
  };

  const fetchQuran = useCallback(async (reciter, edition) => {
    try {
      const response = await axios.get(
        `https://api.alquran.cloud/v1/quran/${reciter}`
      );
      const translationResponse = await axios.get(
        `https://api.alquran.cloud/v1/quran/${edition}`
      );
      const ayahs = response.data.data.surahs.flatMap((surah) => surah.ayahs);
      const translations = translationResponse.data.data.surahs.flatMap(
        (surah) => surah.ayahs
      );
      const combinedData = ayahs.map((ayah, index) => ({
        ...ayah,
        translation: translations[index].text,
      }));
      return combinedData;
    } catch (error) {
      console.error("Error fetching Quran:", error);
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchEds = async () => {
      try {
        const [audioData, translationData] = await Promise.all([
          axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
          axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
        ]);
        setAudioEds(audioData.data.data);
        setTransEds(translationData.data.data);
      } catch (error) {
        console.error("Error fetching editions data:", error);
      }
    };
    fetchEds();
  }, []);

  useEffect(() => {
    const loadRandomVid = async () => {
      setVisible(false); // Start fade out
      setTimeout(async () => {
        const randomVidUrl = await getRandomVidUrl();
        setCurVidUrl(randomVidUrl);
        setVisible(true); // Start fade in
      }, 1000); // Adjust timing to match transition duration
    };
    loadRandomVid();

    const vidInterval = setInterval(() => {
      loadRandomVid();
    }, 10000); // Adjust the interval to match the video duration

    return () => clearInterval(vidInterval);
  }, []);

  useEffect(() => {
    const loadQuran = async () => {
      const quranAyahs = await fetchQuran(reciter, selEd);
      setQuranData(quranAyahs);
      setCurAyahIndex(parseInt(Cookies.get("startAyah")) - 1 || 0);
      if (quranAyahs.length > 0) {
        setAudioUrl(quranAyahs[curAyahIndex].audio);
        setTransAyah(quranAyahs[curAyahIndex].translation);
      }
    };
    loadQuran();
  }, [reciter, selEd, fetchQuran]);

  useEffect(() => {
    if (quranData.length > 0) {
      setAudioUrl(quranData[curAyahIndex].audio);
      setTransAyah(quranData[curAyahIndex].translation);
    }
  }, [curAyahIndex, quranData]);

  useEffect(() => {
    const handleAudioEnded = () => {
      if (autoPlay) {
        setCurAyahIndex((prevIndex) =>
          prevIndex === quranData.length - 1 ? 0 : prevIndex + 1
        );
      }
    };

    const handleAudioPlay = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    };

    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.addEventListener("ended", handleAudioEnded);
      audioElement.addEventListener("loadeddata", handleAudioPlay);

      return () => {
        audioElement.removeEventListener("ended", handleAudioEnded);
        audioElement.removeEventListener("loadeddata", handleAudioPlay);
      };
    }
  }, [quranData, curAyahIndex, autoPlay]);

  const handleReciterChange = (e) => {
    const selectedReciter = e.target.value;
    setReciter(selectedReciter);
    Cookies.set("selReciter", selectedReciter, { expires: 7 });
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setSelLang(selectedLanguage);
    Cookies.set("selLang", selectedLanguage, { expires: 7 });
  };

  const handleEditionChange = (e) => {
    const selectedEdition = e.target.value;
    setSelEd(selectedEdition);
    Cookies.set("selEd", selectedEdition, { expires: 7 });
  };

  const handleStartAyahChange = (e) => {
    const startAyah = parseInt(e.target.value);
    if (!isNaN(startAyah) && startAyah > 0 && startAyah <= quranData.length) {
      setCurAyahIndex(startAyah - 1);
      Cookies.set("startAyah", startAyah, { expires: 7 });
    }
  };

  const handleAutoPlayNextAyahChange = (e) => {
    const autoPlay = e.target.checked;
    setAutoPlay(autoPlay);
    Cookies.set("autoPlay", autoPlay ? "true" : "false", { expires: 7 });
  };

  const toArabicNumerals = (number) => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => arabicNumerals[digit])
      .join("");
  };

  return (
    <div id="vid-container" className={`vid-container visible`}>
      <div className="vid-content">
        {quranData.length > 0 && (
          <>
            <h1 className="ayah org">
              {quranData[curAyahIndex].text} (
              {toArabicNumerals(quranData[curAyahIndex]?.numberInSurah)} /{" "}
              {quranData[curAyahIndex]?.number})
            </h1>
            <h1 className="ayah trans">{transAyah}</h1>
          </>
        )}
      </div>
      <img src={DoneFrame} alt="DoneFrame img" className="DoneFrame " />
      {curVidUrl && (
        <div
          className={`vid-container ${visible ? "visible" : "hidden"}`}
          style={{ overflow: "hidden" }}
        >
          <video
            src={curVidUrl}
            autoPlay
            muted
            loop
            className="vid"
            style={{ objectFit: "cover" }}
          />
        </div>
      )}

      <div className="aud-controls">
        {quranData.length > 0 && (
          <audio ref={audioRef} controls src={audioUrl} />
        )}

        <div className="rec-selector">
          <label htmlFor="rec">Choose reciter:</label>
          <select id="rec" value={reciter} onChange={handleReciterChange}>
            {audioEds.map((edition) => (
              <option key={edition.identifier} value={edition.identifier}>
                {edition.name}
              </option>
            ))}
          </select>
        </div>
        <div className="lang-selector">
          <label htmlFor="lang">Choose lang: </label>
          <select id="lang" value={selLang} onChange={handleLanguageChange}>
            <option value="">Select Lang</option>
            {langCodeToName.map((language) => (
              <option
                key={Object.keys(language)[0]}
                value={Object.keys(language)[0]}
              >
                {Object.values(language)[0]}
              </option>
            ))}
          </select>
        </div>
        {selLang && (
          <div className="ed-selector">
            <label htmlFor="ed">Choose ed: </label>
            <select id="ed" value={selEd} onChange={handleEditionChange}>
              <option value="">Select Edition</option>
              {transEds
                .filter((edition) => edition.language === selLang)
                .map((edition) => (
                  <option key={edition.identifier} value={edition.identifier}>
                    {edition.name}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="start-ayah-selector">
          <label htmlFor="startAyah">Start Ayah: </label>
          <input
            type="number"
            id="startAyah"
            value={curAyahIndex + 1}
            onChange={handleStartAyahChange}
          />
        </div>
        <div className="autoplay-next-ayah-selector">
          <label htmlFor="autoPlayNextAyah">Auto Play Next Ayah:</label>
          <input
            type="checkbox"
            id="autoPlayNextAyah"
            checked={autoPlay}
            onChange={handleAutoPlayNextAyahChange}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
