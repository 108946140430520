// import React, { useContext, useState } from "react";
// import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
// import firebaseApp from "../firebase"; // Import the firebase.js file
// import { AuthContext } from "../AuthContext"; // Import the AuthContext
// import { Box, VStack, Heading, Input, Button } from "@chakra-ui/react";

// const Login = () => {
//   const { currentUser, setCurrentUser } = useContext(AuthContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const handleLogin = (e) => {
//     e.preventDefault();
//     const auth = getAuth(firebaseApp); // Use the initialized firebaseApp
//     signInWithEmailAndPassword(auth, email, password)
//       .then((userCredential) => {
//         // User logged in successfully
//         const user = userCredential.user;
//         console.log("Login successful!", user);
//         setCurrentUser(user); // Set the current user
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         console.error(errorCode, errorMessage);
//       });
//   };

//   const handleLogout = () => {
//     const auth = getAuth(firebaseApp);
//     signOut(auth)
//       .then(() => {
//         console.log("Logout successful!");
//         setCurrentUser(null); // Set the current user to null
//       })
//       .catch((error) => {
//         console.error("Error during logout:", error);
//       });
//   };

//   const handleSubmit = currentUser ? handleLogout : handleLogin;

//   return (
//     <Box maxWidth="400px" mx="auto" mt={5}>
//       <Heading as="h1" size="lg" textAlign="center">
//         {currentUser ? "Logout" : "Login"}
//       </Heading>
//       <VStack as="form" onSubmit={handleSubmit} spacing={4} mt={5}>
//         <Input
//           variant="outline"
//           isRequired={!currentUser}
//           id="email"
//           name="email"
//           placeholder="Email Address"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <Input
//           variant="outline"
//           isRequired={!currentUser}
//           type="password"
//           id="password"
//           name="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <Button type="submit" colorScheme="blue" width="100%" variant="solid">
//           {currentUser ? "Logout" : "Login"}
//         </Button>
//       </VStack>
//     </Box>
//   );
// };

// export default Login;

// import React, { useContext, useState } from "react";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// import firebaseApp from "../firebase";
// import { AuthContext } from "../AuthContext";
// import { Box, VStack, Heading, Input, Button } from "@chakra-ui/react";

// const Login = () => {
//   const { currentUser, setCurrentUser } = useContext(AuthContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const handleLogin = (e) => {
//     e.preventDefault();
//     const auth = getAuth(firebaseApp);
//     signInWithEmailAndPassword(auth, email, password)
//       .then((userCredential) => {
//         const user = userCredential.user;
//         console.log("Login successful!", user);
//         setCurrentUser(user);
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         console.error(errorCode, errorMessage);
//       });
//   };

//   return (
//     <Box maxWidth="400px" mx="auto" mt={5}>
//       <Heading as="h1" size="lg" textAlign="center">
//         Login
//       </Heading>
//       <VStack as="form" onSubmit={handleLogin} spacing={4} mt={5}>
//         <Input
//           variant="outline"
//           id="email"
//           name="email"
//           placeholder="Email Address"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <Input
//           variant="outline"
//           type="password"
//           id="password"
//           name="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <Button type="submit" colorScheme="blue" width="100%" variant="solid">
//           Login
//         </Button>
//       </VStack>
//     </Box>
//   );
// };

// export default Login;

// import React, { useContext, useState, memo } from "react";
// import axios from "axios"; // Import axios for making network requests
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// import firebaseApp from "../firebase";
// import { AuthContext } from "../AuthContext";
// import { Box, VStack, Heading, Input, Button } from "@chakra-ui/react";

// const Login = () => {
//   const { currentUser, setCurrentUser } = useContext(AuthContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     const auth = getAuth(firebaseApp);
//     try {
//       const userCredential = await signInWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       const user = userCredential.user;
//       console.log("Login successful!", user);
//       setCurrentUser(user);
//     } catch (error) {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       console.error(errorCode, errorMessage);
//     }
//   };

//   // Use the memoization technique and wrap the component in React.memo
//   return (
//     <Box maxWidth="400px" mx="auto" mt={5}>
//       <Heading as="h1" size="lg" textAlign="center">
//         Login
//       </Heading>
//       <VStack as="form" onSubmit={handleLogin} spacing={4} mt={5}>
//         <Input
//           variant="outline"
//           id="email"
//           name="email"
//           placeholder="Email Address"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <Input
//           variant="outline"
//           type="password"
//           id="password"
//           name="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <Button type="submit" colorScheme="blue" width="100%" variant="solid">
//           Login
//         </Button>
//       </VStack>
//     </Box>
//   );
// };

// // Use React.memo to prevent re-renders if props haven't changed
// export default memo(Login);
import React, { useContext, useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseApp from "../firebase";
import { AuthContext } from "../AuthContext";
import {
  Box,
  Heading,
  VStack,
  Input,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const inputColor = useColorModeValue("white", "white"); // Adjust the color according to your preference

  useEffect(() => {
    if (currentUser) {
      navigate("/QuranPlayer");
    }
  }, [currentUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth(firebaseApp);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // console.log("Login successful!", user);
      setCurrentUser(user);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    }
  };

  return (
    <Box maxWidth="400px" mx="auto" mt={5}>
      <Heading as="h1" size="lg" textAlign="center">
        Login
      </Heading>
      <VStack as="form" onSubmit={handleLogin} spacing={4} mt={5}>
        <Input
          variant="outline"
          id="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          color={inputColor} // Apply the custom color
        />
        <Input
          variant="outline"
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          color={inputColor} // Apply the custom color
        />
        <Button type="submit" colorScheme="blue" width="100%" variant="solid">
          Login
        </Button>
      </VStack>
    </Box>
  );
};

export default Login;
