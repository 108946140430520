// // import React, { useState } from "react";
// // import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// // import {
// //   Box,
// //   Button,
// //   Container,
// //   FormControl,
// //   FormLabel,
// //   Heading,
// //   Input,
// //   VStack,
// // } from "@chakra-ui/react";
// // import { getFirestore, doc, setDoc } from "firebase/firestore";
// // import firebase from "../firebase";

// // const SignUp = () => {
// //   const [email, setEmail] = useState("");
// //   const [password, setPassword] = useState("");

// //   const handleSignUp = (e) => {
// //     e.preventDefault();
// //     const auth = getAuth(firebase); // Pass the firebase instance when getting the auth
// //     createUserWithEmailAndPassword(auth, email, password)
// //       .then((userCredential) => {
// //         // User signed up successfully
// //         const user = userCredential.user;
// //         console.log("Sign up successful!", user);

// //         // Add code here to create a Firestore document for the user
// //         const firestore = getFirestore(firebase); // Pass the firebase instance when getting firestore
// //         const docRef = doc(firestore, "users", user.uid);
// //         return setDoc(docRef, {
// //           // Add any user data you want to store in Firestore here
// //           email: user.email,
// //           createdAt: new Date().toISOString(), // Use Firestore's server timestamp for better accuracy across devices
// //         });
// //       })
// //       .then(() => {
// //         console.log("User document created in Firestore");
// //       })
// //       .catch((error) => {
// //         const errorCode = error.code;
// //         const errorMessage = error.message;
// //         console.error(errorCode, errorMessage);
// //       });
// //   };

// //   return (
// //     <Container maxW="container.sm">
// //       <VStack spacing={4} align="stretch">
// //         <Heading as="h1" size="lg">
// //           Sign Up
// //         </Heading>
// //         <Box as="form" onSubmit={handleSignUp}>
// //           <FormControl isRequired>
// //             <FormLabel htmlFor="email">Email Address</FormLabel>
// //             <Input
// //               id="email"
// //               type="email"
// //               value={email}
// //               onChange={(e) => setEmail(e.target.value)}
// //             />
// //           </FormControl>
// //           <FormControl isRequired>
// //             <FormLabel htmlFor="password">Password</FormLabel>
// //             <Input
// //               id="password"
// //               type="password"
// //               value={password}
// //               onChange={(e) => setPassword(e.target.value)}
// //             />
// //           </FormControl>
// //           <Button mt={4} width="full" colorScheme="teal" type="submit">
// //             Sign Up
// //           </Button>
// //         </Box>
// //       </VStack>
// //     </Container>
// //   );
// // };

// // export default SignUp;
// import React, { useState } from "react";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import { Box, Button, Heading, Input, VStack } from "@chakra-ui/react";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import firebase from "../firebase";

// const SignUp = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const handleSignUp = (e) => {
//     e.preventDefault();
//     const auth = getAuth(firebase);
//     createUserWithEmailAndPassword(auth, email, password)
//       .then((userCredential) => {
//         const user = userCredential.user;
//         console.log("Sign up successful!", user);
//         const firestore = getFirestore(firebase);
//         const docRef = doc(firestore, "users", user.uid);
//         return setDoc(docRef, {
//           email: user.email,
//           createdAt: new Date().toISOString(),
//         });
//       })
//       .then(() => {
//         console.log("User document created in Firestore");
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         console.error(errorCode, errorMessage);
//       });
//   };

//   return (
//     <Box maxWidth="400px" mx="auto" mt={5}>
//       <Heading as="h1" size="lg" textAlign="center">
//         Sign Up
//       </Heading>
//       <VStack as="form" onSubmit={handleSignUp} spacing={4} mt={5}>
//         <Input
//           variant="outline"
//           id="email"
//           name="email"
//           placeholder="Email Address"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <Input
//           variant="outline"
//           type="password"
//           id="password"
//           name="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <Button type="submit" colorScheme="blue" width="100%" variant="solid">
//           Sign Up
//         </Button>
//       </VStack>
//     </Box>
//   );
// };

// export default SignUp;

// import React, { useState, useEffect, useContext } from "react";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import { Box, Button, Heading, Input, VStack } from "@chakra-ui/react";
// import { getFirestore, doc, setDoc } from "firebase/firestore";
// import firebase from "../firebase";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../AuthContext";

// const SignUp = () => {
//   const { currentUser } = useContext(AuthContext);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (currentUser) {
//       navigate("/QuranPlayer");
//     }
//   }, [currentUser, navigate]);

//   const handleSignUp = (e) => {
//     e.preventDefault();
//     const auth = getAuth(firebase);
//     createUserWithEmailAndPassword(auth, email, password)
//       .then((userCredential) => {
//         const user = userCredential.user;
//         // console.log("Sign up successful!", user);
//         const firestore = getFirestore(firebase);
//         const docRef = doc(firestore, "users", user.uid);
//         return setDoc(docRef, {
//           email: user.email,
//           createdAt: new Date().toISOString(),
//         });
//       })
//       .then(() => {
//         // console.log("User document created in Firestore");
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         console.error(errorCode, errorMessage);
//       });
//   };

//   return (
//     <Box maxWidth="400px" mx="auto" mt={5}>
//       <Heading as="h1" size="lg" textAlign="center">
//         Sign Up
//       </Heading>
//       <VStack as="form" onSubmit={handleSignUp} spacing={4} mt={5}>
//         <Input
//           variant="outline"
//           id="email"
//           name="email"
//           placeholder="Email Address"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <Input
//           variant="outline"
//           type="password"
//           id="password"
//           name="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <Button type="submit" colorScheme="blue" width="100%" variant="solid">
//           Sign Up
//         </Button>
//       </VStack>
//     </Box>
//   );
// };

// export default SignUp;

import React, { useState, useEffect, useContext } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Box, Button, Heading, Input, VStack } from "@chakra-ui/react";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import firebase from "../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const SignUp = () => {
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/QuranPlayer");
    }
  }, [currentUser, navigate]);

  const handleSignUp = (e) => {
    e.preventDefault();
    const auth = getAuth(firebase);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // console.log("Sign up successful!", user);
        const firestore = getFirestore(firebase);
        const docRef = doc(firestore, "users", user.uid);
        return setDoc(docRef, {
          email: user.email,
          createdAt: new Date().toISOString(),
        });
      })
      .then(() => {
        // console.log("User document created in Firestore");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  };

  return (
    <Box maxWidth="400px" mx="auto" mt={5}>
      <Heading as="h1" size="lg" textAlign="center">
        Sign Up
      </Heading>
      <VStack as="form" onSubmit={handleSignUp} spacing={4} mt={5}>
        <Input
          variant="outline"
          id="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          color="white" // Set text color to white
        />
        <Input
          variant="outline"
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          color="white" // Set text color to white
        />
        <Button type="submit" colorScheme="blue" width="100%" variant="solid">
          Sign Up
        </Button>
      </VStack>
    </Box>
  );
};

export default SignUp;
