// import React, { useState, useEffect, useRef } from "react";
// import {
//   Box,
//   Button,
//   Heading,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   DrawerOverlay,
//   VStack,
//   IconButton,
//   Center,
// } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import Menu from "../Menu/Menu";
// import SurahDetails from "../SurahDetails/SurahDetails";
// import Ayah from "../Ayah/Ayah";

// const QuranPlayer = () => {
//   const [state, setState] = useState({
//     selectedNumber: "1",
//     selectedAudio: "ar.alafasy",
//     selectedTranslations: ["en.sahih"],
//     selectedLanguage: "English",
//   });

//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [audioSurahData, setAudioSurahData] = useState(null);
//   const [translationSurahData, setTranslationSurahData] = useState({});

//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const [currentAyah, setCurrentAyah] = useState(0);

//   const audioRefs = useRef([]);

//   const languages = Array.from(
//     new Set(editionsWithTranslation.map((edition) => edition.language))
//   );

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleTranslationChange = (selectedIdentifier) => {
//     if (state.selectedTranslations.includes(selectedIdentifier)) {
//       setState((prevState) => ({
//         ...prevState,
//         selectedTranslations: prevState.selectedTranslations.filter(
//           (identifier) => identifier !== selectedIdentifier
//         ),
//       }));
//     } else {
//       setState((prevState) => ({
//         ...prevState,
//         selectedTranslations: [
//           ...prevState.selectedTranslations,
//           selectedIdentifier,
//         ],
//       }));
//     }
//   };

//   const handleTranslationDelete = (editionIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.filter(
//         (edition) => edition !== editionIdentifier
//       ),
//     }));
//   };

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const audioResponse = await fetch(
//           "https://api.alquran.cloud/v1/edition/format/audio"
//         );
//         const audioData = await audioResponse.json();
//         setEditionsWithAudio(audioData.data);

//         const translationResponse = await fetch(
//           "https://api.alquran.cloud/v1/edition/type/translation"
//         );
//         const translationData = await translationResponse.json();
//         setEditionsWithTranslation(translationData.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };

//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const fetchSurahData = async () => {
//       if (state.selectedNumber !== "" && state.selectedAudio !== "") {
//         try {
//           const audioResponse = await fetch(
//             `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
//           );
//           const audioData = await audioResponse.json();
//           setAudioSurahData(audioData.data);

//           const allTranslationsData = {};
//           for (const edition of state.selectedTranslations) {
//             const response = await fetch(
//               `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
//             );
//             const data = await response.json();
//             allTranslationsData[edition] = data.data;
//           }
//           setTranslationSurahData(allTranslationsData);
//         } catch (error) {
//           console.error("Error fetching surah data:", error);
//         }
//       }
//     };

//     fetchSurahData();
//   }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);

//   return (
//     <Center>
//       <VStack p={5}>
//         <IconButton
//           variant="outline"
//           colorScheme="blue"
//           aria-label="Open menu"
//           icon={<HamburgerIcon />}
//           onClick={toggleMenu}
//         />
//         <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
//           <DrawerOverlay>
//             <DrawerContent backgroundColor="#f7f7f7">
//               <DrawerBody>
//                 <Menu
//                   toggleMenu={toggleMenu}
//                   handleChange={handleChange}
//                   handleTranslationChange={handleTranslationChange}
//                   handleTranslationDelete={handleTranslationDelete}
//                   state={state}
//                   languages={languages}
//                   editionsWithAudio={editionsWithAudio}
//                   editionsWithTranslation={editionsWithTranslation}
//                 />
//               </DrawerBody>
//             </DrawerContent>
//           </DrawerOverlay>
//         </Drawer>
//         <Box
//           w="100%"
//           className={`main-content ${isMenuOpen ? "open" : "shift"}`}
//         >
//           <Heading as="h2" size="xl" textAlign="center">
//             The Holy Quran
//           </Heading>
//           <SurahDetails audioSurahData={audioSurahData} />
//           {audioSurahData &&
//             audioSurahData.ayahs &&
//             audioSurahData.ayahs.map((ayah, ayahIndex) => (
//               <Ayah
//                 key={ayahIndex}
//                 ayah={ayah}
//                 ayahIndex={ayahIndex}
//                 state={state}
//                 translationSurahData={translationSurahData}
//                 audioRefs={audioRefs}
//                 setCurrentAyah={setCurrentAyah}
//                 selectedNumber={state.selectedNumber}
//               />
//             ))}
//         </Box>
//       </VStack>
//     </Center>
//   );
// };

// // export default QuranPlayer;
// import React, { useState, useEffect, useRef } from "react";
// import {
//   Box,
//   Button,
//   Heading,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   DrawerOverlay,
//   VStack,
//   IconButton,
//   Center,
// } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import Menu from "../Menu/Menu";
// import SurahDetails from "../SurahDetails/SurahDetails";
// import Ayah from "../Ayah/Ayah";
// import axios from "axios";

// const QuranPlayer = () => {
//   const [state, setState] = useState({
//     selectedNumber: "1",
//     selectedAudio: "ar.alafasy",
//     selectedTranslations: ["en.sahih"],
//     selectedLanguage: "English",
//   });

//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [audioSurahData, setAudioSurahData] = useState(null);
//   const [translationSurahData, setTranslationSurahData] = useState({});
//   const [isLoading, setIsLoading] = useState(false);

//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const [currentAyah, setCurrentAyah] = useState(0);

//   const audioRefs = useRef([]);

//   const languages = Array.from(
//     new Set(editionsWithTranslation.map((edition) => edition.language))
//   );

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleTranslationChange = (selectedIdentifier) => {
//     if (state.selectedTranslations.includes(selectedIdentifier)) {
//       setState((prevState) => ({
//         ...prevState,
//         selectedTranslations: prevState.selectedTranslations.filter(
//           (identifier) => identifier !== selectedIdentifier
//         ),
//       }));
//     } else {
//       setState((prevState) => ({
//         ...prevState,
//         selectedTranslations: [
//           ...prevState.selectedTranslations,
//           selectedIdentifier,
//         ],
//       }));
//     }
//   };

//   const handleTranslationDelete = (editionIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.filter(
//         (edition) => edition !== editionIdentifier
//       ),
//     }));
//   };

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const audioResponse = await fetch(
//           "https://api.alquran.cloud/v1/edition/format/audio"
//         );
//         const audioData = await audioResponse.json();
//         setEditionsWithAudio(audioData.data);

//         const translationResponse = await fetch(
//           "https://api.alquran.cloud/v1/edition/type/translation"
//         );
//         const translationData = await translationResponse.json();
//         setEditionsWithTranslation(translationData.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };

//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const fetchSurahData = async () => {
//       setIsLoading(true);
//       if (state.selectedNumber !== "" && state.selectedAudio !== "") {
//         try {
//           const audioResponse = await axios.get(
//             `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
//           );
//           const audioData = await audioResponse.data;
//           setAudioSurahData(audioData.data);

//           const allTranslationsData = {};
//           for (const edition of state.selectedTranslations) {
//             const response = await axios.get(
//               `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
//             );
//             const data = await response.data;
//             allTranslationsData[edition] = data.data;
//           }
//           setTranslationSurahData(allTranslationsData);
//         } catch (error) {
//           console.error("Error fetching surah data:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       }
//     };

//     fetchSurahData();
//   }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);

//   return (
//     <Center>
//       <VStack p={5}>
//         <IconButton
//           variant="outline"
//           colorScheme="blue"
//           aria-label="Open menu"
//           icon={<HamburgerIcon />}
//           onClick={toggleMenu}
//         />
//         <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
//           <DrawerOverlay>
//             <DrawerContent backgroundColor="#f7f7f7">
//               <DrawerBody>
//                 <Menu
//                   toggleMenu={toggleMenu}
//                   handleChange={handleChange}
//                   handleTranslationChange={handleTranslationChange}
//                   handleTranslationDelete={handleTranslationDelete}
//                   state={state}
//                   languages={languages}
//                   editionsWithAudio={editionsWithAudio}
//                   editionsWithTranslation={editionsWithTranslation}
//                 />
//               </DrawerBody>
//             </DrawerContent>
//           </DrawerOverlay>
//         </Drawer>
//         <Box
//           w="100%"
//           className={`main-content ${isMenuOpen ? "open" : "shift"}`}
//         >
//           <Heading as="h2" size="xl" textAlign="center">
//             The Holy Quran
//           </Heading>
//           <SurahDetails audioSurahData={audioSurahData} />
//           {isLoading ? (
//             <div>Loading...</div>
//           ) : (
//             audioSurahData &&
//             audioSurahData.ayahs &&
//             audioSurahData.ayahs.map((ayah, ayahIndex) => (
//               <Ayah
//                 key={ayahIndex}
//                 ayah={ayah}
//                 ayahIndex={ayahIndex}
//                 state={state}
//                 translationSurahData={translationSurahData}
//                 audioRefs={audioRefs}
//                 setCurrentAyah={setCurrentAyah}
//                 selectedNumber={state.selectedNumber}
//               />
//             ))
//           )}
//         </Box>
//       </VStack>
//     </Center>
//   );
// };

// export default QuranPlayer;

// import React, { useState, useEffect, useRef } from "react";
// import {
//   Box,
//   Button,
//   Heading,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   DrawerOverlay,
//   VStack,
//   IconButton,
//   Center,
// } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import Menu from "../Menu/Menu";
// import SurahDetails from "../SurahDetails/SurahDetails";
// import Ayah from "../Ayah/Ayah";
// import axios from "axios";

// const QuranPlayer = () => {
//   const [state, setState] = useState({
//     selectedNumber: "1",
//     selectedAudio: "ar.alafasy",
//     selectedTranslations: ["en.sahih"],
//     selectedLanguage: "English",
//   });

//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [audioSurahData, setAudioSurahData] = useState(null);
//   const [translationSurahData, setTranslationSurahData] = useState({});
//   const [isLoading, setIsLoading] = useState(false);

//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const [currentAyah, setCurrentAyah] = useState(0);
//   const [isAncientMode, setIsAncientMode] = useState(false);

//   const audioRefs = useRef([]);

//   const languages = Array.from(
//     new Set(editionsWithTranslation.map((edition) => edition.language))
//   );

//   // console.log(audioRefs);
//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };
//   const handleTranslationChange = (selectedIdentifier) => {
//     if (state.selectedTranslations.includes(selectedIdentifier)) {
//       setState((prevState) => ({
//         ...prevState,
//         selectedTranslations: prevState.selectedTranslations.filter(
//           (identifier) => identifier !== selectedIdentifier
//         ),
//       }));
//     } else {
//       setState((prevState) => ({
//         ...prevState,
//         selectedTranslations: [
//           ...prevState.selectedTranslations,
//           selectedIdentifier,
//         ],
//       }));
//     }
//   };

//   const handleTranslationDelete = (editionIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.filter(
//         (edition) => edition !== editionIdentifier
//       ),
//     }));
//   };

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const audioData = await axios.get(
//           "https://api.alquran.cloud/v1/edition/format/audio"
//         );
//         setEditionsWithAudio(audioData.data.data);

//         const translationData = await axios.get(
//           "https://api.alquran.cloud/v1/edition/type/translation"
//         );
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };

//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const fetchSurahData = async () => {
//       setIsLoading(true);
//       if (state.selectedNumber !== "" && state.selectedAudio !== "") {
//         try {
//           const audioData = await axios.get(
//             `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
//           );
//           setAudioSurahData(audioData.data.data);

//           const allTranslationsData = {};
//           for (const edition of state.selectedTranslations) {
//             const data = await axios.get(
//               `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
//             );
//             allTranslationsData[edition] = data.data.data;
//           }
//           setTranslationSurahData(allTranslationsData);
//         } catch (error) {
//           console.error("Error fetching surah data:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       }
//     };

//     fetchSurahData();
//   }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);
//   const toggleAncientMode = () => {
//     setIsAncientMode((prevMode) => !prevMode);
//   };
//   return (
//     <Center>
//       <VStack p={5}>
//         <IconButton
//           variant="outline"
//           colorScheme="red"
//           aria-label="Open menu"
//           icon={<HamburgerIcon />}
//           onClick={toggleMenu}
//         />
//         <Button onClick={toggleAncientMode}>
//           {isAncientMode ? "Switch to Ancient" : "Switch to Modern"}
//         </Button>
//         <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
//           <DrawerOverlay>
//             <DrawerContent backgroundColor="gray.900">
//               <DrawerBody>
//                 <Menu
//                   toggleMenu={toggleMenu}
//                   handleChange={handleChange}
//                   handleTranslationChange={handleTranslationChange}
//                   handleTranslationDelete={handleTranslationDelete}
//                   state={state}
//                   languages={languages}
//                   editionsWithAudio={editionsWithAudio}
//                   editionsWithTranslation={editionsWithTranslation}
//                 />
//               </DrawerBody>
//             </DrawerContent>
//           </DrawerOverlay>
//         </Drawer>
//         <Box
//           w="100%"
//           // minW="800px" // Adjust the value based on your preference
//           // maxW="800px" // Adjust the value based on your preference
//           className={`main-content ${isMenuOpen ? "open" : "shift"}`}
//         >
//           <Box textAlign="center" py={8}>
//             <Heading as="h2" size="xl" mb={4} color="yellow.900">
//               القرآن الكريم
//             </Heading>
//           </Box>

//           <SurahDetails audioSurahData={audioSurahData} />
//           {isLoading ? (
//             <div>Loading...</div>
//           ) : (
//             audioSurahData &&
//             audioSurahData.ayahs &&
//             audioSurahData.ayahs.map((ayah, ayahIndex) => (
//               <div className={isAncientMode ? "Modern_page" : "ancient_page"}>
//                 <Ayah
//                   key={ayahIndex}
//                   ayah={ayah}
//                   ayahIndex={ayahIndex}
//                   state={state}
//                   translationSurahData={translationSurahData}
//                   audioRefs={audioRefs}
//                   setCurrentAyah={setCurrentAyah}
//                   selectedNumber={state.selectedNumber}
//                 />
//               </div>
//             ))
//           )}
//         </Box>
//       </VStack>
//     </Center>
//   );
// };

// export default QuranPlayer;

// import React, {
//   useState,
//   useEffect,
//   useRef,
//   useMemo,
//   useCallback,
// } from "react";
// import {
//   Box,
//   Button,
//   Heading,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   DrawerOverlay,
//   VStack,
//   IconButton,
//   Center,
// } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import Menu from "../Menu/Menu";
// import SurahDetails from "../SurahDetails/SurahDetails";
// import Ayah from "../Ayah/Ayah";
// import axios from "axios";

// const QuranPlayer = () => {
//   const [state, setState] = useState({
//     selectedNumber: "1",
//     selectedAudio: "ar.alafasy",
//     selectedTranslations: ["en.sahih"],
//     selectedLanguage: "English",
//   });

//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [audioSurahData, setAudioSurahData] = useState(null);
//   const [translationSurahData, setTranslationSurahData] = useState({});
//   const [isLoading, setIsLoading] = useState(false);

//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const [currentAyah, setCurrentAyah] = useState(0);
//   const [isAncientMode, setIsAncientMode] = useState(false);

//   const audioRefs = useRef([]);

//   const languages = useMemo(
//     () =>
//       Array.from(
//         new Set(editionsWithTranslation.map((edition) => edition.language))
//       ),
//     [editionsWithTranslation]
//   );

//   const toggleMenu = useCallback(() => {
//     setIsMenuOpen((isOpen) => !isOpen);
//   }, []);

//   const handleChange = useCallback((e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   }, []);

//   const handleTranslationChange = useCallback((selectedIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.includes(
//         selectedIdentifier
//       )
//         ? prevState.selectedTranslations.filter(
//             (identifier) => identifier !== selectedIdentifier
//           )
//         : [...prevState.selectedTranslations, selectedIdentifier],
//     }));
//   }, []);

//   const handleTranslationDelete = useCallback((editionIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.filter(
//         (edition) => edition !== editionIdentifier
//       ),
//     }));
//   }, []);

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };

//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const fetchSurahData = async () => {
//       setIsLoading(true);
//       if (state.selectedNumber !== "" && state.selectedAudio !== "") {
//         try {
//           const [audioData, ...translationData] = await Promise.all([
//             axios.get(
//               `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
//             ),
//             ...state.selectedTranslations.map((edition) =>
//               axios.get(
//                 `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
//               )
//             ),
//           ]);
//           setAudioSurahData(audioData.data.data);

//           const allTranslationsData = translationData.reduce(
//             (acc, { data }, index) => {
//               acc[state.selectedTranslations[index]] = data.data;
//               return acc;
//             },
//             {}
//           );
//           setTranslationSurahData(allTranslationsData);
//         } catch (error) {
//           console.error("Error fetching surah data:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       }
//     };

//     fetchSurahData();
//   }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);

//   const toggleAncientMode = useCallback(() => {
//     setIsAncientMode((prevMode) => !prevMode);
//   }, []);

//   return (
//     <Center>
//       <VStack p={5}>
//         <IconButton
//           variant="outline"
//           colorScheme="red"
//           aria-label="Open menu"
//           icon={<HamburgerIcon />}
//           onClick={toggleMenu}
//         />
//         <Button onClick={toggleAncientMode}>
//           {isAncientMode ? "Switch to Ancient" : "Switch to Modern"}
//         </Button>
//         <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
//           <DrawerOverlay>
//             <DrawerContent backgroundColor="gray.900">
//               <DrawerBody>
//                 <Menu
//                   toggleMenu={toggleMenu}
//                   handleChange={handleChange}
//                   handleTranslationChange={handleTranslationChange}
//                   handleTranslationDelete={handleTranslationDelete}
//                   state={state}
//                   languages={languages}
//                   editionsWithAudio={editionsWithAudio}
//                   editionsWithTranslation={editionsWithTranslation}
//                 />
//               </DrawerBody>
//             </DrawerContent>
//           </DrawerOverlay>
//         </Drawer>
//         <Box
//           w="100%"
//           className={`main-content ${isMenuOpen ? "open" : "shift"}`}
//         >
//           <Box textAlign="center" py={8}>
//             <Heading as="h2" size="xl" mb={4} color="yellow.900">
//               القرآن الكريم
//             </Heading>
//           </Box>

//           <SurahDetails audioSurahData={audioSurahData} />
//           {isLoading ? (
//             <div>Loading...</div>
//           ) : (
//             audioSurahData &&
//             audioSurahData.ayahs &&
//             audioSurahData.ayahs.map((ayah, ayahIndex) => (
//               <div className={isAncientMode ? "Modern_page" : "ancient_page"}>
//                 <Ayah
//                   key={ayahIndex}
//                   ayah={ayah}
//                   ayahIndex={ayahIndex}
//                   state={state}
//                   translationSurahData={translationSurahData}
//                   audioRefs={audioRefs}
//                   setCurrentAyah={setCurrentAyah}
//                   selectedNumber={state.selectedNumber}
//                 />
//               </div>
//             ))
//           )}
//         </Box>
//       </VStack>
//     </Center>
//   );
// };

// export default QuranPlayer;

// import React, {
//   useState,
//   useEffect,
//   useRef,
//   useMemo,
//   useCallback,
// } from "react";
// import {
//   Box,
//   Button,
//   Heading,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   DrawerOverlay,
//   VStack,
//   IconButton,
//   Center,
// } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import Menu from "../Menu/Menu";
// import SurahDetails from "../SurahDetails/SurahDetails";
// import Ayah from "../Ayah/Ayah";
// import axios from "axios";

// const QuranPlayer = () => {
//   const [state, setState] = useState({
//     selectedNumber: "1",
//     selectedAudio: "ar.alafasy",
//     selectedTranslations: ["en.sahih"],
//     selectedLanguage: "English",
//   });

//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [audioSurahData, setAudioSurahData] = useState(null);
//   const [translationSurahData, setTranslationSurahData] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [currentAyah, setCurrentAyah] = useState(0);
//   const [isAncientMode, setIsAncientMode] = useState(false);
//   const audioRefs = useRef([]);

//   const languages = useMemo(
//     () =>
//       Array.from(
//         new Set(editionsWithTranslation.map((edition) => edition.language))
//       ),
//     [editionsWithTranslation]
//   );

//   const toggleMenu = useCallback(() => {
//     setIsMenuOpen((isOpen) => !isOpen);
//   }, []);

//   const handleChange = useCallback((e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   }, []);

//   const handleTranslationChange = useCallback((selectedIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.includes(
//         selectedIdentifier
//       )
//         ? prevState.selectedTranslations.filter(
//             (identifier) => identifier !== selectedIdentifier
//           )
//         : [...prevState.selectedTranslations, selectedIdentifier],
//     }));
//   }, []);

//   const handleTranslationDelete = useCallback((editionIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.filter(
//         (edition) => edition !== editionIdentifier
//       ),
//     }));
//   }, []);

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };

//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const fetchSurahData = async () => {
//       setIsLoading(true);
//       if (state.selectedNumber !== "" && state.selectedAudio !== "") {
//         try {
//           const [audioData, ...translationData] = await Promise.all([
//             axios.get(
//               `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
//             ),
//             ...state.selectedTranslations.map((edition) =>
//               axios.get(
//                 `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
//               )
//             ),
//           ]);
//           setAudioSurahData(audioData.data.data);

//           const allTranslationsData = translationData.reduce(
//             (acc, { data }, index) => {
//               acc[state.selectedTranslations[index]] = data.data;
//               return acc;
//             },
//             {}
//           );
//           setTranslationSurahData(allTranslationsData);
//         } catch (error) {
//           console.error("Error fetching surah data:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       }
//     };

//     fetchSurahData();
//   }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);

//   const toggleAncientMode = useCallback(() => {
//     setIsAncientMode((prevMode) => !prevMode);
//   }, []);

//   return (
//     <Center>
//       <VStack p={5}>
//         <IconButton
//           variant="outline"
//           colorScheme="red"
//           aria-label="Open menu"
//           icon={<HamburgerIcon />}
//           onClick={toggleMenu}
//         />
//         <Button onClick={toggleAncientMode}>
//           {isAncientMode ? "Switch to Ancient" : "Switch to Modern"}
//         </Button>
//         <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
//           <DrawerOverlay>
//             <DrawerContent backgroundColor="gray.900">
//               <DrawerBody>
//                 <Menu
//                   toggleMenu={toggleMenu}
//                   handleChange={handleChange}
//                   handleTranslationChange={handleTranslationChange}
//                   handleTranslationDelete={handleTranslationDelete}
//                   state={state}
//                   languages={languages}
//                   editionsWithAudio={editionsWithAudio}
//                   editionsWithTranslation={editionsWithTranslation}
//                 />
//               </DrawerBody>
//             </DrawerContent>
//           </DrawerOverlay>
//         </Drawer>
//         <Box
//           w="100%"
//           className={`main-content ${isMenuOpen ? "open" : "shift"}`}
//         >
//           <Box textAlign="center" py={8}>
//             <Heading as="h2" size="xl" mb={4} color="yellow.900">
//               القرآن الكريم
//             </Heading>
//           </Box>

//           <SurahDetails audioSurahData={audioSurahData} />
//           {isLoading ? (
//             <div>Loading...</div>
//           ) : (
//             audioSurahData &&
//             audioSurahData.ayahs &&
//             audioSurahData.ayahs.map((ayah, ayahIndex) => (
//               <div
//                 className={isAncientMode ? "Modern_page" : "ancient_page"}
//                 key={ayahIndex}
//               >
//                 <Ayah
//                   ayah={ayah}
//                   ayahIndex={ayahIndex}
//                   state={state}
//                   translationSurahData={translationSurahData}
//                   audioRefs={audioRefs}
//                   setCurrentAyah={setCurrentAyah}
//                   selectedNumber={state.selectedNumber}
//                 />
//               </div>
//             ))
//           )}
//         </Box>
//       </VStack>
//     </Center>
//   );
// };

// export default QuranPlayer;
// import React, {
//   useState,
//   useEffect,
//   useRef,
//   useMemo,
//   useCallback,
// } from "react";
// import {
//   Box,
//   Button,
//   Heading,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   DrawerOverlay,
//   VStack,
//   IconButton,
//   Center,
// } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import Menu from "../Menu/Menu";
// import SurahDetails from "../SurahDetails/SurahDetails";
// import Ayah from "../Ayah/Ayah";
// import axios from "axios";

// const QuranPlayer = () => {
//   const [state, setState] = useState({
//     selectedNumber: "1",
//     selectedAudio: "ar.alafasy",
//     selectedTranslations: ["en.sahih"],
//     selectedLanguage: "English",
//   });

//   const [editionsWithAudio, setEditionsWithAudio] = useState([]);
//   const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
//   const [audioSurahData, setAudioSurahData] = useState(null);
//   const [translationSurahData, setTranslationSurahData] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [currentAyah, setCurrentAyah] = useState(0);
//   const [isAncientMode, setIsAncientMode] = useState(false);
//   const audioRefs = useRef([]);

//   const languages = useMemo(
//     () =>
//       Array.from(
//         new Set(editionsWithTranslation.map((edition) => edition.language))
//       ),
//     [editionsWithTranslation]
//   );

//   const toggleMenu = useCallback(() => {
//     setIsMenuOpen((isOpen) => !isOpen);
//   }, []);

//   const handleChange = useCallback((e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   }, []);

//   const handleTranslationChange = useCallback((selectedIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.includes(
//         selectedIdentifier
//       )
//         ? prevState.selectedTranslations.filter(
//             (identifier) => identifier !== selectedIdentifier
//           )
//         : [...prevState.selectedTranslations, selectedIdentifier],
//     }));
//   }, []);

//   const handleTranslationDelete = useCallback((editionIdentifier) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedTranslations: prevState.selectedTranslations.filter(
//         (edition) => edition !== editionIdentifier
//       ),
//     }));
//   }, []);

//   useEffect(() => {
//     const fetchEditions = async () => {
//       try {
//         const [audioData, translationData] = await Promise.all([
//           axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
//           axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
//         ]);
//         setEditionsWithAudio(audioData.data.data);
//         setEditionsWithTranslation(translationData.data.data);
//       } catch (error) {
//         console.error("Error fetching editions data:", error);
//       }
//     };

//     fetchEditions();
//   }, []);

//   useEffect(() => {
//     const fetchSurahData = async () => {
//       setIsLoading(true);
//       if (state.selectedNumber !== "" && state.selectedAudio !== "") {
//         try {
//           const [audioData, ...translationData] = await Promise.all([
//             axios.get(
//               `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
//             ),
//             ...state.selectedTranslations.map((edition) =>
//               axios.get(
//                 `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
//               )
//             ),
//           ]);
//           setAudioSurahData(audioData.data.data);

//           const allTranslationsData = translationData.reduce(
//             (acc, { data }, index) => {
//               acc[state.selectedTranslations[index]] = data.data;
//               return acc;
//             },
//             {}
//           );
//           setTranslationSurahData(allTranslationsData);
//         } catch (error) {
//           console.error("Error fetching surah data:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       }
//     };

//     fetchSurahData();
//   }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);

//   const toggleAncientMode = useCallback(() => {
//     setIsAncientMode((prevMode) => !prevMode);
//   }, []);

//   return (
//     <Center>
//       <VStack p={5}>
//         <Box position="sticky" top="100px" zIndex="10">
//           {/* Make the IconButton sticky */}
//           <IconButton
//             variant="outline"
//             colorScheme="red"
//             aria-label="Open menu"
//             icon={<HamburgerIcon />}
//             onClick={toggleMenu}
//           />
//         </Box>
//         <Button
//           position="sticky"
//           top="150px"
//           zIndex="10"
//           onClick={toggleAncientMode}
//         >
//           {isAncientMode ? "Switch to Ancient" : "Switch to Modern"}
//         </Button>
//         <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
//           <DrawerOverlay>
//             <DrawerContent backgroundColor="gray.900">
//               <DrawerBody>
//                 <Menu
//                   toggleMenu={toggleMenu}
//                   handleChange={handleChange}
//                   handleTranslationChange={handleTranslationChange}
//                   handleTranslationDelete={handleTranslationDelete}
//                   state={state}
//                   languages={languages}
//                   editionsWithAudio={editionsWithAudio}
//                   editionsWithTranslation={editionsWithTranslation}
//                 />
//               </DrawerBody>
//             </DrawerContent>
//           </DrawerOverlay>
//         </Drawer>
//         <Box
//           w="100%"
//           className={`main-content ${isMenuOpen ? "open" : "shift"}`}
//         >
//           <Box textAlign="center" py={8}>
//             <Heading as="h2" size="xl" mb={4} color="yellow.900">
//               القرآن الكريم
//             </Heading>
//           </Box>

//           <SurahDetails audioSurahData={audioSurahData} />
//           {isLoading ? (
//             <div>Loading...</div>
//           ) : (
//             audioSurahData &&
//             audioSurahData.ayahs &&
//             audioSurahData.ayahs.map((ayah, ayahIndex) => (
//               <div
//                 className={isAncientMode ? "Modern_page" : "ancient_page"}
//                 key={ayahIndex}
//               >
//                 <Ayah
//                   ayah={ayah}
//                   ayahIndex={ayahIndex}
//                   state={state}
//                   translationSurahData={translationSurahData}
//                   audioRefs={audioRefs}
//                   setCurrentAyah={setCurrentAyah}
//                   selectedNumber={state.selectedNumber}
//                 />
//               </div>
//             ))
//           )}
//         </Box>
//       </VStack>
//     </Center>
//   );
// };

// export default QuranPlayer;
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Heading,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  VStack,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Menu from "../Menu/Menu";
import SurahDetails from "../SurahDetails/SurahDetails";
import Ayah from "../Ayah/Ayah";
import axios from "axios";

const QuranPlayer = () => {
  const [state, setState] = useState({
    selectedNumber: "1",
    selectedAudio: "ar.alafasy",
    selectedTranslations: ["en.sahih"],
    selectedLanguage: "English",
  });

  const [editionsWithAudio, setEditionsWithAudio] = useState([]);
  const [editionsWithTranslation, setEditionsWithTranslation] = useState([]);
  const [audioSurahData, setAudioSurahData] = useState(null);
  const [translationSurahData, setTranslationSurahData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentAyah, setCurrentAyah] = useState(0);
  const [isAncientMode, setIsAncientMode] = useState(false);
  const audioRefs = useRef([]);

  const languages = useMemo(
    () =>
      Array.from(
        new Set(editionsWithTranslation.map((edition) => edition.language))
      ),
    [editionsWithTranslation]
  );
  console.log(languages);
  const toggleMenu = useCallback(() => {
    setIsMenuOpen((isOpen) => !isOpen);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleTranslationChange = useCallback((selectedIdentifier) => {
    setState((prevState) => ({
      ...prevState,
      selectedTranslations: prevState.selectedTranslations.includes(
        selectedIdentifier
      )
        ? prevState.selectedTranslations.filter(
            (identifier) => identifier !== selectedIdentifier
          )
        : [...prevState.selectedTranslations, selectedIdentifier],
    }));
  }, []);

  const handleTranslationDelete = useCallback((editionIdentifier) => {
    setState((prevState) => ({
      ...prevState,
      selectedTranslations: prevState.selectedTranslations.filter(
        (edition) => edition !== editionIdentifier
      ),
    }));
  }, []);

  useEffect(() => {
    const fetchEditions = async () => {
      try {
        const [audioData, translationData] = await Promise.all([
          axios.get("https://api.alquran.cloud/v1/edition/format/audio"),
          axios.get("https://api.alquran.cloud/v1/edition/type/translation"),
        ]);
        setEditionsWithAudio(audioData.data.data);
        setEditionsWithTranslation(translationData.data.data);
      } catch (error) {
        console.error("Error fetching editions data:", error);
      }
    };

    fetchEditions();
  }, []);

  useEffect(() => {
    const fetchSurahData = async () => {
      setIsLoading(true);
      if (state.selectedNumber !== "" && state.selectedAudio !== "") {
        try {
          const [audioData, ...translationData] = await Promise.all([
            axios.get(
              `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${state.selectedAudio}`
            ),
            ...state.selectedTranslations.map((edition) =>
              axios.get(
                `https://api.alquran.cloud/v1/surah/${state.selectedNumber}/${edition}`
              )
            ),
          ]);
          setAudioSurahData(audioData.data.data);

          const allTranslationsData = translationData.reduce(
            (acc, { data }, index) => {
              acc[state.selectedTranslations[index]] = data.data;
              return acc;
            },
            {}
          );
          setTranslationSurahData(allTranslationsData);
        } catch (error) {
          console.error("Error fetching surah data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchSurahData();
  }, [state.selectedNumber, state.selectedAudio, state.selectedTranslations]);

  const toggleAncientMode = useCallback(() => {
    setIsAncientMode((prevMode) => !prevMode);
  }, []);

  useEffect(() => {
    // Scroll to the current ayah with an offset of 200px from its current position
    const scrollToCurrentAyah = () => {
      const ayahElement = document.getElementById(`ayah-${currentAyah}`);
      if (ayahElement) {
        ayahElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
          inline: "nearest", // Align the top of the element with the top of the viewport
        });
        window.scrollBy(0, -260); // Scroll 200px up from the current position
      }
    };

    scrollToCurrentAyah();
  }, [currentAyah]);
  return (
    <Center>
      <VStack p={5}>
        <Box textAlign="center" py={8}>
          <Heading as="h2" size="xl" mb={4} color="yellow.900">
            القرآن الكريم
          </Heading>
        </Box>
        <Button
          position="sticky"
          top="252px"
          zIndex="10"
          onClick={toggleAncientMode}
        >
          {isAncientMode ? "Switch to Ancient" : "Switch to Modern"}
        </Button>

        <Box position="sticky" top="300px" zIndex="10">
          {/* Make the IconButton sticky */}
          <IconButton
            variant="outline"
            colorScheme="red"
            aria-label="Open menu"
            icon={<HamburgerIcon />}
            onClick={toggleMenu}
            background={"yellow.100"}
          />
        </Box>

        <Drawer placement="left" onClose={toggleMenu} isOpen={isMenuOpen}>
          <DrawerOverlay>
            <DrawerContent backgroundColor="gray.900">
              <DrawerBody>
                <Menu
                  toggleMenu={toggleMenu}
                  handleChange={handleChange}
                  handleTranslationChange={handleTranslationChange}
                  handleTranslationDelete={handleTranslationDelete}
                  state={state}
                  languages={languages}
                  editionsWithAudio={editionsWithAudio}
                  editionsWithTranslation={editionsWithTranslation}
                />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <Box
          w="100%"
          className={`main-content ${isMenuOpen ? "open" : "shift"}`}
          style={{
            paddingBottom:
              currentAyah === audioSurahData?.ayahs.length - 1 ? "1000px" : "0",
          }}
        >
          <SurahDetails audioSurahData={audioSurahData} />
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            audioSurahData &&
            audioSurahData.ayahs &&
            audioSurahData.ayahs.map((ayah, ayahIndex) => (
              <div
                className={isAncientMode ? "Modern_page" : "ancient_page"}
                id={`ayah-${ayahIndex}`} // Add an id to each ayah element
                key={ayahIndex}
              >
                <Ayah
                  ayah={ayah}
                  ayahIndex={ayahIndex}
                  state={state}
                  translationSurahData={translationSurahData}
                  audioRefs={audioRefs}
                  setCurrentAyah={setCurrentAyah}
                  selectedNumber={state.selectedNumber}
                />
              </div>
            ))
          )}
        </Box>
      </VStack>
    </Center>
  );
};

export default QuranPlayer;
