// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Image,
// } from "@chakra-ui/react";
// import icon from "../images/icons/donation-gf5437d159_1280.png";

// // PayPal Button
// const PayPalButton = () => {
//   return (
//     <form action="https://www.paypal.com/donate" method="post" target="_blank">
//       <input type="hidden" name="business" value="Inlinex7@gmail.com" />
//       <input type="hidden" name="currency_code" value="USD" />
//       <Text fontSize="xl" textAlign="center" color="black">
//         Support us:
//       </Text>
//       <Button variant="outline">
//         <Image src={icon} alt="Donate" boxSize="35%" />
//       </Button>
//       <Image
//         alt=""
//         src="https://www.paypal.com/en_US/i/scr/pixel.gif"
//         width="1"
//         height="1"
//       />
//     </form>
//   );
// };

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   languages,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   return (
//     <Box id="myMenu" className={`menu ${isMenuOpen ? "open" : ""}`} p={5}>
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <PayPalButton />
//       <FormControl variant="filled" my={2}>
//         <Text fontSize="md" color="black">
//           Select a Surah number:
//         </Text>
//         <Select
//           name="selectedNumber"
//           onChange={handleChange}
//           placeholder="Select option"
//         >
//           {Array.from({ length: 114 }, (_, i) => (
//             <option key={i} value={i + 1}>
//               {i + 1}
//             </option>
//           ))}
//         </Select>
//       </FormControl>
//       <FormControl variant="filled" my={2}>
//         <Text fontSize="md" color="black">
//           Select an audio edition:
//         </Text>
//         {editionsWithAudio.length > 0 && (
//           <Select
//             name="selectedAudio"
//             onChange={handleChange}
//             placeholder="Select option"
//           >
//             {editionsWithAudio.map((edition) => (
//               <option key={edition.identifier} value={edition.identifier}>
//                 {edition.name}
//               </option>
//             ))}
//           </Select>
//         )}
//       </FormControl>
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="black">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//             >
//               {languages.map((language, index) => (
//                 <option key={index} value={language}>
//                   {language}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <ListItem
//                     key={edition.identifier}
//                     className={`translation-option ${
//                       state.selectedTranslations.includes(edition.identifier)
//                         ? "selected"
//                         : ""
//                     }`}
//                     onClick={() => handleTranslationChange(edition.identifier)}
//                     _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
//                     px={2}
//                     py={1}
//                     rounded="md"
//                   >
//                     {edition.name}
//                   </ListItem>
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="black">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <ListItem
//                 key={selectedTranslation}
//                 className={`translation-option ${
//                   state.selectedTranslations.includes(selectedTranslation)
//                     ? "selected"
//                     : ""
//                 }`}
//                 onClick={() => handleTranslationDelete(selectedTranslation)}
//                 _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
//                 px={2}
//                 py={1}
//                 rounded="md"
//               >
//                 {edition.name} ({edition.language}){" "}
//                 <Button>
//                   <FontAwesomeIcon icon={faTrash} color="black" />
//                 </Button>
//               </ListItem>
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// // export default Menu;
// import React, { useCallback, memo } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Image,
// } from "@chakra-ui/react";
// import icon from "../images/icons/donation-gf5437d159_1280.png";

// const PayPalButton = memo(() => {
//   return (
//     <form action="https://www.paypal.com/donate" method="post" target="_blank">
//       <input type="hidden" name="business" value="Inlinex7@gmail.com" />
//       <input type="hidden" name="currency_code" value="USD" />
//       <Text fontSize="xl" textAlign="center" color="black">
//         Support us:
//       </Text>
//       <Button variant="outline">
//         <Image src={icon} alt="Donate" boxSize="35%" />
//       </Button>
//       <Image
//         alt=""
//         src="https://www.paypal.com/en_US/i/scr/pixel.gif"
//         width="1"
//         height="1"
//       />
//     </form>
//   );
// });

// const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
//   return (
//     <FormControl variant="filled" my={2}>
//       <Text fontSize="md" color="black">
//         {placeholder}:
//       </Text>
//       {editions.length > 0 && (
//         <Select name={name} onChange={onChange} placeholder="Select option">
//           {editions.map((edition) => (
//             <option key={edition.identifier} value={edition.identifier}>
//               {edition.name}
//             </option>
//           ))}
//         </Select>
//       )}
//     </FormControl>
//   );
// });

// const TranslationItem = memo(({ edition, onClick, selected }) => (
//   <ListItem
//     key={edition.identifier}
//     className={`translation-option ${selected ? "selected" : ""}`}
//     onClick={onClick}
//     _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
//     px={2}
//     py={1}
//     rounded="md"
//   >
//     {edition.name}
//   </ListItem>
// ));

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   languages,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   const handleTranslationClick = useCallback(
//     (identifier) => handleTranslationChange(identifier),
//     [handleTranslationChange]
//   );

//   const handleDeleteClick = useCallback(
//     (identifier) => handleTranslationDelete(identifier),
//     [handleTranslationDelete]
//   );

//   return (
//     <Box id="myMenu" className={`menu ${isMenuOpen ? "open" : ""}`} p={5}>
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <PayPalButton />
//       <EditionSelect
//         name="selectedNumber"
//         editions={Array.from({ length: 114 }, (_, i) => ({
//           identifier: i + 1,
//           name: (i + 1).toString(),
//         }))}
//         onChange={handleChange}
//         placeholder="Select a Surah number"
//       />
//       <EditionSelect
//         name="selectedAudio"
//         editions={editionsWithAudio}
//         onChange={handleChange}
//         placeholder="Select an audio edition"
//       />
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="black">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//             >
//               {languages.map((language, index) => (
//                 <option key={index} value={language}>
//                   {language}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <TranslationItem
//                     key={edition.identifier}
//                     edition={edition}
//                     onClick={() => handleTranslationClick(edition.identifier)}
//                     selected={state.selectedTranslations.includes(
//                       edition.identifier
//                     )}
//                   />
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="black">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <TranslationItem
//                 key={selectedTranslation}
//                 edition={edition}
//                 onClick={() => handleDeleteClick(selectedTranslation)}
//                 selected={state.selectedTranslations.includes(
//                   selectedTranslation
//                 )}
//               >
//                 {edition.name} ({edition.language}){" "}
//                 <Button>
//                   <FontAwesomeIcon icon={faTrash} color="black" />
//                 </Button>
//               </TranslationItem>
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// export default Menu;

// import React, { useCallback, memo, useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Image,
// } from "@chakra-ui/react";
// import icon from "../images/icons/donation-gf5437d159_1280.png";
// import axios from "axios";

// const PayPalButton = memo(() => {
//   return (
//     <form action="https://www.paypal.com/donate" method="post" target="_blank">
//       <input type="hidden" name="business" value="Inlinex7@gmail.com" />
//       <input type="hidden" name="currency_code" value="USD" />
//       <Text fontSize="xl" textAlign="center" color="black">
//         Support us:
//       </Text>
//       <Button variant="outline">
//         <Image src={icon} alt="Donate" boxSize="35%" />
//       </Button>
//       <Image
//         alt=""
//         src="https://www.paypal.com/en_US/i/scr/pixel.gif"
//         width="1"
//         height="1"
//       />
//     </form>
//   );
// });

// const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
//   return (
//     <FormControl variant="filled" my={2}>
//       <Text fontSize="md" color="black">
//         {placeholder}:
//       </Text>
//       {editions.length > 0 && (
//         <Select name={name} onChange={onChange} placeholder="Select option">
//           {editions.map((edition) => (
//             <option key={edition.identifier} value={edition.identifier}>
//               {edition.identifier}-{edition.name}-{edition.surahName}
//             </option>
//           ))}
//         </Select>
//       )}
//     </FormControl>
//   );
// });

// const TranslationItem = memo(({ edition, onClick, selected }) => (
//   <ListItem
//     key={edition.identifier}
//     className={`translation-option ${selected ? "selected" : ""}`}
//     onClick={onClick}
//     _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
//     px={2}
//     py={1}
//     rounded="md"
//   >
//     {edition.name}
//   </ListItem>
// ));

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   languages,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   const [surahs, setSurahs] = useState([]);

//   useEffect(() => {
//     // Fetch Surah data from the API
//     axios
//       .get("https://api.alquran.cloud/v1/surah")
//       .then((response) => {
//         // Extract relevant data from the API response
//         const surahData = response.data.data.map((surah) => ({
//           identifier: surah.number,
//           name: surah.name,
//           surahName: surah.englishNameTranslation,
//         }));
//         setSurahs(surahData);
//       })
//       .catch((error) => {
//         console.error("Error fetching Surah data:", error);
//       });
//   }, []);

//   const handleTranslationClick = useCallback(
//     (identifier) => handleTranslationChange(identifier),
//     [handleTranslationChange]
//   );

//   const handleDeleteClick = useCallback(
//     (identifier) => handleTranslationDelete(identifier),
//     [handleTranslationDelete]
//   );

//   return (
//     <Box id="myMenu" className={`menu ${isMenuOpen ? "open" : ""}`} p={5}>
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <PayPalButton />
//       <EditionSelect
//         name="selectedNumber"
//         editions={surahs}
//         onChange={handleChange}
//         placeholder="Select a Surah number"
//       />
//       <EditionSelect
//         name="selectedAudio"
//         editions={editionsWithAudio}
//         onChange={handleChange}
//         placeholder="Select an audio edition"
//       />
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="black">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//             >
//               {languageCodeToName.map((language, index) => (
//                 <option key={index} value={Object.keys(language)[0]}>
//                   {Object.keys(language)[0]}: {Object.values(language)[0]}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <TranslationItem
//                     key={edition.identifier}
//                     edition={edition}
//                     onClick={() => handleTranslationClick(edition.identifier)}
//                     selected={state.selectedTranslations.includes(
//                       edition.identifier
//                     )}
//                   />
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="black">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <TranslationItem
//                 key={selectedTranslation}
//                 edition={edition}
//                 onClick={() => handleDeleteClick(selectedTranslation)}
//                 selected={state.selectedTranslations.includes(
//                   selectedTranslation
//                 )}
//               >
//                 {edition.name} ({edition.language}){" "}
//                 <Button>
//                   <FontAwesomeIcon icon={faTrash} color="black" />
//                 </Button>
//               </TranslationItem>
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// export default Menu;

// import React, { useCallback, memo, useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Image,
// } from "@chakra-ui/react";
// import axios from "axios";
// import languageCodeToName from "./languageCodeToName";

// const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
//   return (
//     <FormControl variant="filled" my={2}>
//       <Text fontSize="md" color="black">
//         {placeholder}:
//       </Text>
//       {editions.length > 0 && (
//         <Select name={name} onChange={onChange} placeholder="Select option">
//           {editions.map((edition) => (
//             <option key={edition.identifier} value={edition.identifier}>
//               {edition.identifier}-{edition.name}-{edition.surahName}
//             </option>
//           ))}
//         </Select>
//       )}
//     </FormControl>
//   );
// });

// const TranslationItem = memo(({ edition, onClick, selected }) => (
//   <ListItem
//     key={edition.identifier}
//     className={`translation-option ${selected ? "selected" : ""}`}
//     onClick={onClick}
//     _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
//     px={2}
//     py={1}
//     rounded="md"
//   >
//     {edition.name}
//   </ListItem>
// ));

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   const [surahs, setSurahs] = useState([]);

//   useEffect(() => {
//     // Fetch Surah data from the API
//     axios
//       .get("https://api.alquran.cloud/v1/surah")
//       .then((response) => {
//         // Extract relevant data from the API response
//         const surahData = response.data.data.map((surah) => ({
//           identifier: surah.number,
//           name: surah.name,
//           surahName: surah.englishNameTranslation,
//         }));
//         setSurahs(surahData);
//       })
//       .catch((error) => {
//         console.error("Error fetching Surah data:", error);
//       });
//   }, []);

//   const handleTranslationClick = useCallback(
//     (identifier) => handleTranslationChange(identifier),
//     [handleTranslationChange]
//   );

//   const handleDeleteClick = useCallback(
//     (identifier) => handleTranslationDelete(identifier),
//     [handleTranslationDelete]
//   );

//   return (
//     <Box id="myMenu" className={`menu ${isMenuOpen ? "open" : ""}`} p={5}>
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <EditionSelect
//         name="selectedNumber"
//         editions={surahs}
//         onChange={handleChange}
//         placeholder="Select a Surah number"
//       />
//       <EditionSelect
//         name="selectedAudio"
//         editions={editionsWithAudio}
//         onChange={handleChange}
//         placeholder="Select an audio edition"
//       />
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="black">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//             >
//               {languageCodeToName.map((language, index) => (
//                 <option key={index} value={Object.keys(language)[0]}>
//                   {Object.keys(language)[0]}: {Object.values(language)[0]}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <TranslationItem
//                     key={edition.identifier}
//                     edition={edition}
//                     onClick={() => handleTranslationClick(edition.identifier)}
//                     selected={state.selectedTranslations.includes(
//                       edition.identifier
//                     )}
//                   />
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="black">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <TranslationItem
//                 key={selectedTranslation}
//                 edition={edition}
//                 onClick={() => handleDeleteClick(selectedTranslation)}
//                 selected={state.selectedTranslations.includes(
//                   selectedTranslation
//                 )}
//               >
//                 {edition.name} ({edition.language}){" "}
//                 <Button>
//                   <FontAwesomeIcon icon={faTrash} color="black" />
//                 </Button>
//               </TranslationItem>
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// export default Menu;

// import React, { useCallback, memo, useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Image,
// } from "@chakra-ui/react";
// import axios from "axios";
// import languageCodeToName from "./languageCodeToName";

// const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
//   return (
//     <FormControl variant="filled" my={2}>
//       <Text fontSize="md" color="black">
//         {placeholder}:
//       </Text>
//       {editions.length > 0 && (
//         <Select name={name} onChange={onChange} placeholder="Select option">
//           {editions.map((edition) => (
//             <option key={edition.identifier} value={edition.identifier}>
//               {edition.identifier}-{edition.name}-{edition.surahName}
//             </option>
//           ))}
//         </Select>
//       )}
//     </FormControl>
//   );
// });

// const TranslationItem = memo(
//   ({ edition, onClick, selected, onDeleteClick }) => (
//     <ListItem
//       key={edition.identifier}
//       className={`translation-option ${selected ? "selected" : ""}`}
//       onClick={onClick}
//       _hover={{ backgroundColor: "gray.200", cursor: "pointer" }}
//       px={2}
//       py={1}
//       rounded="md"
//     >
//       {edition.name} ({edition.language}){" "}
//       <Button onClick={onDeleteClick} ml={2}>
//         <FontAwesomeIcon icon={faTrash} color="black" />
//       </Button>
//     </ListItem>
//   )
// );

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   const [surahs, setSurahs] = useState([]);

//   useEffect(() => {
//     // Fetch Surah data from the API
//     axios
//       .get("https://api.alquran.cloud/v1/surah")
//       .then((response) => {
//         // Extract relevant data from the API response
//         const surahData = response.data.data.map((surah) => ({
//           identifier: surah.number,
//           name: surah.name,
//           surahName: surah.englishNameTranslation,
//         }));
//         setSurahs(surahData);
//       })
//       .catch((error) => {
//         console.error("Error fetching Surah data:", error);
//       });
//   }, []);

//   const handleTranslationClick = useCallback(
//     (identifier) => handleTranslationChange(identifier),
//     [handleTranslationChange]
//   );

//   const handleDeleteClick = useCallback(
//     (identifier) => handleTranslationDelete(identifier),
//     [handleTranslationDelete]
//   );

//   return (
//     <Box id="myMenu" className={`menu ${isMenuOpen ? "open" : ""}`} p={5}>
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <EditionSelect
//         name="selectedNumber"
//         editions={surahs}
//         onChange={handleChange}
//         placeholder="Select a Surah number"
//       />
//       <EditionSelect
//         name="selectedAudio"
//         editions={editionsWithAudio}
//         onChange={handleChange}
//         placeholder="Select an audio edition"
//       />
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="black">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//             >
//               {languageCodeToName.map((language, index) => (
//                 <option key={index} value={Object.keys(language)[0]}>
//                   {Object.keys(language)[0]}: {Object.values(language)[0]}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <TranslationItem
//                     key={edition.identifier}
//                     edition={edition}
//                     onClick={() => handleTranslationClick(edition.identifier)}
//                     onDeleteClick={() => handleDeleteClick(edition.identifier)}
//                     selected={state.selectedTranslations.includes(
//                       edition.identifier
//                     )}
//                   />
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="black">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <TranslationItem
//                 key={selectedTranslation}
//                 edition={edition}
//                 onClick={() => handleDeleteClick(selectedTranslation)}
//                 onDeleteClick={() => handleDeleteClick(selectedTranslation)}
//                 selected={state.selectedTranslations.includes(
//                   selectedTranslation
//                 )}
//               />
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// export default Menu;

// import React, { useCallback, memo, useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes, faDonate } from "@fortawesome/free-solid-svg-icons"; // Import the donate icon
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Image,
// } from "@chakra-ui/react";
// import axios from "axios";
// import languageCodeToName from "./languageCodeToName";

// const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
//   return (
//     <FormControl variant="filled" my={2}>
//       <Text fontSize="md" color="white">
//         {placeholder}:
//       </Text>
//       {editions.length > 0 && (
//         <Select
//           name={name}
//           onChange={onChange}
//           placeholder="Select option"
//           color="goldenrod" // Set text color to Goldenrod
//         >
//           {editions.map((edition) => (
//             <option key={edition.identifier} value={edition.identifier}>
//               {edition.identifier}-{edition.name}-{edition.surahName}
//             </option>
//           ))}
//         </Select>
//       )}
//     </FormControl>
//   );
// });

// // const TranslationItem = memo(
// //   ({ edition, onClick, selected, onDeleteClick }) => (
// //     <ListItem
// //       key={edition.identifier}
// //       className={`translation-option ${selected ? "selected" : ""}`}
// //       onClick={onClick}
// //       px={2}
// //       py={1}
// //       rounded="md"
// //       color="white"
// //     >
// //       {edition.name} ({edition.language}){" "}
// //       <Button
// //         onClick={onDeleteClick}
// //         ml={2}
// //         _hover={{
// //           backgroundColor: "goldenrod",
// //           opacity: "0.8",
// //           cursor: "pointer",
// //         }}
// //       ></Button>
// //     </ListItem>
// //   )
// // );

// const TranslationItem = memo(
//   ({ edition, onClick, onDeleteClick, selected }) => (
//     <ListItem
//       key={edition.identifier}
//       className={`translation-option ${selected ? "selected" : ""}`}
//       px={2}
//       py={3}
//       rounded="md"
//       color="white"
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//     >
//       <Flex alignItems="center">
//         <Text fontSize="md" mr={2}>
//           {edition.name} ({edition.language})
//         </Text>
//         {selected ? (
//           <Button
//             onClick={onDeleteClick}
//             ml={2}
//             colorScheme="red"
//             size="sm"
//             variant="ghost"
//           >
//             <FontAwesomeIcon icon={faTrash} color="black" />
//           </Button>
//         ) : (
//           <Button
//             onClick={onClick}
//             ml={2}
//             colorScheme="green"
//             size="sm"
//             variant="ghost"
//           >
//             <FontAwesomeIcon icon={faPlus} color="black" />
//           </Button>
//         )}
//       </Flex>
//     </ListItem>
//   )
// );

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   const [surahs, setSurahs] = useState([]);

//   useEffect(() => {
//     // Fetch Surah data from the API
//     axios
//       .get("https://api.alquran.cloud/v1/surah")
//       .then((response) => {
//         // Extract relevant data from the API response
//         const surahData = response.data.data.map((surah) => ({
//           identifier: surah.number,
//           name: surah.name,
//           surahName: surah.englishNameTranslation,
//         }));
//         setSurahs(surahData);
//       })
//       .catch((error) => {
//         console.error("Error fetching Surah data:", error);
//       });
//   }, []);

//   const handleTranslationClick = useCallback(
//     (identifier) => handleTranslationChange(identifier),
//     [handleTranslationChange]
//   );

//   const handleDeleteClick = useCallback(
//     (identifier) => handleTranslationDelete(identifier),
//     [handleTranslationDelete]
//   );

//   return (
//     <Box
//       id="myMenu"
//       className={`menu ${isMenuOpen ? "open" : ""}`}
//       p={5}
//       bg="gray.800"
//       color="white"
//       rounded="md"
//       boxShadow="md"
//     >
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <EditionSelect
//         name="selectedNumber"
//         editions={surahs}
//         onChange={handleChange}
//         placeholder="Select a Surah number"
//       />
//       <EditionSelect
//         name="selectedAudio"
//         editions={editionsWithAudio}
//         onChange={handleChange}
//         placeholder="Select an audio edition"
//       />
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="white">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//               color="goldenrod" // Set text color to Goldenrod
//               bg="gray.700" // Set background color to a dark shade
//             >
//               {languageCodeToName.map((language, index) => (
//                 <option key={index} value={Object.keys(language)[0]}>
//                   {Object.keys(language)[0]}: {Object.values(language)[0]}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <TranslationItem
//                     key={edition.identifier}
//                     edition={edition}
//                     onClick={() => handleTranslationClick(edition.identifier)}
//                     onDeleteClick={() => handleDeleteClick(edition.identifier)}
//                     selected={state.selectedTranslations.includes(
//                       edition.identifier
//                     )}
//                   />
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="white">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <TranslationItem
//                 key={selectedTranslation}
//                 edition={edition}
//                 onClick={() => handleDeleteClick(selectedTranslation)}
//                 onDeleteClick={() => handleDeleteClick(selectedTranslation)}
//                 selected={state.selectedTranslations.includes(
//                   selectedTranslation
//                 )}
//               />
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// export default Menu;

// import React, { useCallback, memo, useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons"; // Import icons
// import {
//   Box,
//   Button,
//   FormControl,
//   Select,
//   Text,
//   List,
//   ListItem,
//   Flex,
// } from "@chakra-ui/react";
// import axios from "axios";
// import languageCodeToName from "./languageCodeToName";

// const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
//   return (
//     <FormControl variant="filled" my={2}>
//       <Text fontSize="md" color="white">
//         {placeholder}:
//       </Text>
//       {editions.length > 0 && (
//         <Select
//           name={name}
//           onChange={onChange}
//           placeholder="Select option"
//           color="goldenrod" // Set text color to Goldenrod
//         >
//           {editions.map((edition) => (
//             <option key={edition.identifier} value={edition.identifier}>
//               {edition.identifier}-{edition.name}-{edition.surahName}
//             </option>
//           ))}
//         </Select>
//       )}
//     </FormControl>
//   );
// });

// const TranslationItem = memo(
//   ({ edition, onClick, onDeleteClick, selected }) => (
//     <ListItem
//       key={edition.identifier}
//       className={`translation-option ${selected ? "selected" : ""}`}
//       px={2}
//       py={3}
//       rounded="md"
//       color="white"
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//     >
//       <Flex alignItems="center">
//         <Text fontSize="lg" mr={2}>
//           {edition.name} ({edition.language})
//         </Text>
//         {selected ? (
//           <Button
//             onClick={onDeleteClick}
//             ml={2}
//             colorScheme="red"
//             size="lg"
//             variant="ghost"
//           >
//             <FontAwesomeIcon icon={faTrash} color="golden" />
//           </Button>
//         ) : (
//           <Button
//             onClick={onClick}
//             ml={2}
//             colorScheme="green"
//             size="lg"
//             variant="ghost"
//           >
//             <FontAwesomeIcon icon={faPlus} color="golden" />
//           </Button>
//         )}
//       </Flex>
//     </ListItem>
//   )
// );

// const Menu = ({
//   isMenuOpen,
//   toggleMenu,
//   handleChange,
//   handleTranslationChange,
//   handleTranslationDelete,
//   state,
//   editionsWithAudio,
//   editionsWithTranslation,
// }) => {
//   const [surahs, setSurahs] = useState([]);

//   useEffect(() => {
//     // Fetch Surah data from the API
//     axios
//       .get("https://api.alquran.cloud/v1/surah")
//       .then((response) => {
//         // Extract relevant data from the API response
//         const surahData = response.data.data.map((surah) => ({
//           identifier: surah.number,
//           name: surah.name,
//           surahName: surah.englishNameTranslation,
//         }));
//         setSurahs(surahData);
//       })
//       .catch((error) => {
//         console.error("Error fetching Surah data:", error);
//       });
//   }, []);

//   const handleTranslationClick = useCallback(
//     (identifier) => handleTranslationChange(identifier),
//     [handleTranslationChange]
//   );

//   const handleDeleteClick = useCallback(
//     (identifier) => handleTranslationDelete(identifier),
//     [handleTranslationDelete]
//   );

//   return (
//     <Box
//       id="myMenu"
//       className={`menu ${isMenuOpen ? "open" : ""}`}
//       p={5}
//       bg="gray.800"
//       color="white"
//       rounded="md"
//       boxShadow="md"
//     >
//       <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
//         <FontAwesomeIcon icon={faTimes} color="black" />
//       </Button>
//       <EditionSelect
//         name="selectedNumber"
//         editions={surahs}
//         onChange={handleChange}
//         placeholder="Select a Surah number"
//       />
//       <EditionSelect
//         name="selectedAudio"
//         editions={editionsWithAudio}
//         onChange={handleChange}
//         placeholder="Select an audio edition"
//       />
//       <FormControl variant="filled" my={2}>
//         {editionsWithTranslation.length > 0 && (
//           <Box>
//             <Text fontSize="md" color="white">
//               Select translation editions:
//             </Text>
//             <Select
//               name="selectedLanguage"
//               onChange={handleChange}
//               placeholder="Select option"
//               color="goldenrod" // Set text color to Goldenrod
//               bg="gray.700" // Set background color to a dark shade
//             >
//               {languageCodeToName.map((language, index) => (
//                 <option key={index} value={Object.keys(language)[0]}>
//                   {Object.keys(language)[0]}: {Object.values(language)[0]}
//                 </option>
//               ))}
//             </Select>
//             <List spacing={3}>
//               {editionsWithTranslation
//                 .filter(
//                   (edition) => edition.language === state.selectedLanguage
//                 )
//                 .map((edition) => (
//                   <TranslationItem
//                     key={edition.identifier}
//                     edition={edition}
//                     onClick={() => handleTranslationClick(edition.identifier)}
//                     onDeleteClick={() => handleDeleteClick(edition.identifier)}
//                     selected={state.selectedTranslations.includes(
//                       edition.identifier
//                     )}
//                   />
//                 ))}
//             </List>
//           </Box>
//         )}
//       </FormControl>
//       <Text fontSize="xl" color="white">
//         Selected Translations:
//       </Text>
//       <List spacing={3}>
//         {editionsWithTranslation.length > 0 &&
//           state.selectedTranslations.map((selectedTranslation) => {
//             const edition = editionsWithTranslation.find(
//               (edition) => edition.identifier === selectedTranslation
//             );
//             return (
//               <TranslationItem
//                 key={selectedTranslation}
//                 edition={edition}
//                 onClick={() => handleDeleteClick(selectedTranslation)}
//                 onDeleteClick={() => handleDeleteClick(selectedTranslation)}
//                 selected={state.selectedTranslations.includes(
//                   selectedTranslation
//                 )}
//               />
//             );
//           })}
//       </List>
//     </Box>
//   );
// };

// export default Menu;
import React, { useCallback, memo, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons"; // Import icons
import {
  Box,
  Button,
  FormControl,
  Select,
  Text,
  List,
  ListItem,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import languageCodeToName from "./languageCodeToName";

const EditionSelect = memo(({ name, editions, onChange, placeholder }) => {
  return (
    <FormControl variant="filled" my={2}>
      <Text fontSize="md" color="white">
        {placeholder}:
      </Text>
      {editions.length > 0 && (
        <Select
          name={name}
          onChange={onChange}
          placeholder="Select option"
          color="goldenrod" // Set text color to Goldenrod
        >
          {editions.map((edition) => (
            <option key={edition.identifier} value={edition.identifier}>
              {edition.identifier}-{edition.name}-{edition.surahName}
            </option>
          ))}
        </Select>
      )}
    </FormControl>
  );
});

const TranslationItem = memo(
  ({ edition, onClick, onDeleteClick, selected }) => (
    <ListItem
      key={edition.identifier}
      className={`translation-option ${selected ? "selected" : ""}`}
      px={2}
      py={3}
      rounded="md"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Text fontSize="lg" mr={2}>
          {edition.name} ({edition.language})
        </Text>
        {selected ? (
          <Button
            onClick={onDeleteClick}
            ml={2}
            colorScheme="red"
            size="lg"
            variant="ghost"
          >
            <FontAwesomeIcon icon={faTrash} color="golden" />
          </Button>
        ) : (
          <Button
            onClick={onClick}
            ml={2}
            colorScheme="green"
            size="lg"
            variant="ghost"
          >
            <FontAwesomeIcon icon={faPlus} color="golden" />
          </Button>
        )}
      </Flex>
    </ListItem>
  )
);

const Menu = ({
  isMenuOpen,
  toggleMenu,
  handleChange,
  handleTranslationChange,
  handleTranslationDelete,
  state,
  editionsWithAudio,
  editionsWithTranslation,
}) => {
  const [surahs, setSurahs] = useState([]);

  useEffect(() => {
    // Fetch Surah data from the API
    axios
      .get("https://api.alquran.cloud/v1/surah")
      .then((response) => {
        // Extract relevant data from the API response
        const surahData = response.data.data.map((surah) => ({
          identifier: surah.number,
          name: surah.name,
          surahName: surah.englishNameTranslation,
        }));
        setSurahs(surahData);
      })
      .catch((error) => {
        console.error("Error fetching Surah data:", error);
      });
  }, []);

  const handleTranslationClick = useCallback(
    (identifier) => handleTranslationChange(identifier),
    [handleTranslationChange]
  );

  const handleDeleteClick = useCallback(
    (identifier) => handleTranslationDelete(identifier),
    [handleTranslationDelete]
  );

  return (
    <Box
      id="myMenu"
      className={`menu ${isMenuOpen ? "open" : ""}`}
      p={5}
      bg="gray.800"
      color="white"
      rounded="md"
      boxShadow="md"
    >
      <Button variant="solid" colorScheme="red" onClick={toggleMenu} my={2}>
        <FontAwesomeIcon icon={faTimes} color="black" />
      </Button>
      <EditionSelect
        name="selectedNumber"
        editions={surahs}
        onChange={handleChange}
        placeholder="Select a Surah number"
      />
      <EditionSelect
        name="selectedAudio"
        editions={editionsWithAudio}
        onChange={handleChange}
        placeholder="Select an audio edition"
      />
      <FormControl variant="filled" my={2}>
        {editionsWithTranslation.length > 0 && (
          <Box>
            <Text fontSize="md" color="white">
              Select translation editions:
            </Text>
            <Select
              name="selectedLanguage"
              onChange={handleChange}
              placeholder="Select option"
              color="goldenrod" // Set text color to Goldenrod
              bg="gray.700" // Set background color to a dark shade
            >
              {languageCodeToName.map((language, index) => (
                <option key={index} value={Object.keys(language)[0]}>
                  {Object.keys(language)[0]}: {Object.values(language)[0]}
                </option>
              ))}
            </Select>
            <List spacing={3}>
              {editionsWithTranslation
                .filter(
                  (edition) => edition.language === state.selectedLanguage
                )
                .map((edition) => (
                  <TranslationItem
                    key={edition.identifier}
                    edition={edition}
                    onClick={() => handleTranslationClick(edition.identifier)}
                    onDeleteClick={() => handleDeleteClick(edition.identifier)}
                    selected={state.selectedTranslations.includes(
                      edition.identifier
                    )}
                  />
                ))}
            </List>
          </Box>
        )}
      </FormControl>
      <Text fontSize="xl" color="white">
        Selected Translations:
      </Text>
      <List spacing={3}>
        {editionsWithTranslation.length > 0 &&
          state.selectedTranslations.map((selectedTranslation) => {
            const edition = editionsWithTranslation.find(
              (edition) => edition.identifier === selectedTranslation
            );
            return (
              <TranslationItem
                key={selectedTranslation}
                edition={edition}
                onClick={() => handleDeleteClick(selectedTranslation)}
                onDeleteClick={() => handleDeleteClick(selectedTranslation)}
                selected={state.selectedTranslations.includes(
                  selectedTranslation
                )}
              />
            );
          })}
      </List>
    </Box>
  );
};

export default Menu;
