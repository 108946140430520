// import React, { useEffect, useRef } from "react";
// import { VStack, Heading, Text, useColorMode } from "@chakra-ui/react";

// const Donate = () => {
//   const { colorMode } = useColorMode();
//   const scriptRef = useRef(null);

//   useEffect(() => {
//     // Check if the script has already been added
//     if (!scriptRef.current) {
//       const script = document.createElement("script");
//       script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
//       script.charset = "UTF-8";
//       script.onerror = handleScriptError; // Handle script loading error

//       // Append the script to the container
//       document.getElementById("donate-button-container").appendChild(script);
//       scriptRef.current = script;

//       // Define the function to handle script loading
//       const handleScriptLoad = () => {
//         if (window.PayPal && window.PayPal.Donation) {
//           window.PayPal.Donation.Button({
//             env: "production",
//             hosted_button_id: "ZJNZ9DTSCJRFG",
//             image: {
//               src: "https://pics.paypal.com/00/s/MDAzZWVkNDUtYjQ5My00NmY4LTk2OTYtOTk2YzkxN2I5ZWY3/file.PNG",
//               alt: "Donate with PayPal button",
//               title: "PayPal - The safer, easier way to pay online!",
//             },
//           }).render("#donate-button");
//         } else {
//           handleScriptError("PayPal Donation Button SDK not loaded");
//         }
//       };

//       // Call the function to handle script loading after the script is fully loaded
//       script.onload = handleScriptLoad;
//     }
//   }, []);

//   const handleScriptError = (error) => {
//     console.error("Script loading error:", error);
//     // You can handle the error here, such as displaying a message to the user
//   };

//   return (
//     <VStack spacing={4} alignItems="center">
//       <Heading
//         as="h1"
//         size="lg"
//         color={colorMode === "light" ? "gray.700" : "yellow.400"}
//         style={{ color: colorMode === "light" ? "#4A5568" : "#D69E2E" }}
//       >
//         Support Us
//       </Heading>
//       <Text
//         fontSize="md"
//         textAlign="center"
//         style={{ color: colorMode === "light" ? "#4A5568" : "#D69E2E" }}
//       >
//         Your donation helps us continue our work and improve our services.
//       </Text>
//       <Text
//         fontSize="md"
//         textAlign="center"
//         style={{ color: colorMode === "light" ? "#4A5568" : "#D69E2E" }}
//       >
//         تبرعك يساعدنا على مواصلة عملنا وتحسين خدماتنا.
//       </Text>
//       <div id="donate-button-container">
//         <div id="donate-button"></div>
//       </div>
//     </VStack>
//   );
// };

// export default Donate;
import React, { useEffect, useRef } from "react";
import { VStack, Heading, Text, useColorMode } from "@chakra-ui/react";

const Donate = () => {
  const { colorMode } = useColorMode();
  const scriptRef = useRef(null);

  useEffect(() => {
    // Check if the script has already been added
    if (!scriptRef.current) {
      const script = document.createElement("script");
      script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
      script.charset = "UTF-8";
      script.onerror = handleScriptError; // Handle script loading error

      // Append the script to the container
      document.getElementById("donate-button-container").appendChild(script);
      scriptRef.current = script;

      // Define the function to handle script loading
      const handleScriptLoad = () => {
        if (window.PayPal && window.PayPal.Donation) {
          window.PayPal.Donation.Button({
            env: "production",
            hosted_button_id: "VWK9K7D26NMCQ",
            image: {
              src: "https://pics.paypal.com/00/s/ZmI5NzhlM2MtMmFiMC00MjI0LWI0ZDMtZGNmYzAzYmI1ZDBk/file.PNG",
              alt: "Donate with PayPal button",
              title: "PayPal - The safer, easier way to pay online!",
            },
          }).render("#donate-button");
        } else {
          handleScriptError("PayPal Donation Button SDK not loaded");
        }
      };

      // Call the function to handle script loading after the script is fully loaded
      script.onload = handleScriptLoad;
    }
  }, []);

  const handleScriptError = (error) => {
    console.error("Script loading error:", error);
    // You can handle the error here, such as displaying a message to the user
  };

  return (
    <VStack spacing={4} alignItems="center">
      <Heading
        as="h1"
        size="lg"
        color={colorMode === "light" ? "gray.700" : "yellow.400"}
        style={{ color: colorMode === "light" ? "#4A5568" : "#D69E2E" }}
      >
        Support Us
      </Heading>
      <Text
        fontSize="md"
        textAlign="center"
        style={{ color: colorMode === "light" ? "#4A5568" : "#D69E2E" }}
      >
        Your donation helps us continue our work and improve our services.
      </Text>
      <Text
        fontSize="md"
        textAlign="center"
        style={{ color: colorMode === "light" ? "#4A5568" : "#D69E2E" }}
      >
        تبرعك يساعدنا على مواصلة عملنا وتحسين خدماتنا.
      </Text>
      <div id="donate-button-container">
        <div id="donate-button"></div>
      </div>
    </VStack>
  );
};

export default Donate;
