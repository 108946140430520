// import React, { useState, useEffect } from "react";
// import { Box, Text, List, ListItem, VStack, Center } from "@chakra-ui/react";
// import Recorder from "../Recorder/Recorder";
// import UserRecordings from "../UserRecordings/UserRecordings";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const Ayah = ({
//   ayah,
//   ayahIndex,
//   state,
//   translationSurahData,
//   audioRefs,
//   setCurrentAyah,
//   selectedNumber,
// }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setIsAuthenticated(true);
//       } else {
//         setIsAuthenticated(false);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const stopAllAudios = (currentAudioId) => {
//     const audios = document.querySelectorAll("audio");
//     audios.forEach((audio) => {
//       if (audio.id !== currentAudioId) {
//         audio.pause();
//         audio.currentTime = 0; // Reset the audio
//       }
//     });
//   };

//   const handlePlay = () => {
//     stopAllAudios(`audio-${ayahIndex}`);
//     audioRefs.current[ayahIndex]
//       .play()
//       .catch((error) => console.error("Failed to play audio:", error));
//   };

//   return (
//     <Center>
//       <Box key={ayahIndex}>
//         <Text fontSize="xl" color="blue.500" textAlign="center">
//           Ayah Number: {ayah.number}
//         </Text>
//         <hr />
//         <Text my={3} fontSize="lg" textAlign="center">
//           {ayah.text}
//         </Text>
//         <List spacing={3}>
//           {state.selectedTranslations.map((translationIdentifier) => {
//             const translation =
//               translationSurahData[translationIdentifier]?.ayahs[ayahIndex];
//             return (
//               translation && (
//                 <ListItem key={translationIdentifier}>
//                   <VStack align="stretch">
//                     <Text fontSize="2xl" color="yellow.300">
//                       {translationIdentifier}
//                     </Text>
//                     <Text color="yellow.300">{translation.text}</Text>
//                   </VStack>
//                 </ListItem>
//               )
//             );
//           })}
//         </List>
//         <VStack>
//           <audio
//             controls
//             id={`audio-${ayahIndex}`}
//             key={ayah.audio}
//             ref={(ref) => (audioRefs.current[ayahIndex] = ref)}
//             onPlay={() => handlePlay(ayahIndex)}
//             onEnded={() => {
//               if (audioRefs.current[ayahIndex + 1]) {
//                 setCurrentAyah(ayahIndex + 1);
//                 audioRefs.current[ayahIndex + 1].play();
//               }
//             }}
//           >
//             <source src={ayah.audio} type="audio/mpeg" />
//             Your browser does not support the audio element.
//           </audio>

//           {isAuthenticated ? (
//             <Recorder ayah={ayah} selectedNumber={selectedNumber} />
//           ) : (
//             <Text>Please sign in to access the recorder.</Text>
//           )}
//           {isAuthenticated && (
//             <UserRecordings ayah={ayah} selectedNumber={selectedNumber} />
//           )}
//         </VStack>
//       </Box>
//     </Center>
//   );
// };
// export default Ayah;

// import React, { useState, useEffect, memo } from "react";
// import { Box, Text, List, ListItem, VStack, Center } from "@chakra-ui/react";
// import Recorder from "../Recorder/Recorder";
// import UserRecordings from "../UserRecordings/UserRecordings";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const Ayah = ({
//   ayah,
//   ayahIndex,
//   state,
//   translationSurahData,
//   audioRefs,
//   setCurrentAyah,
//   selectedNumber,
// }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setIsAuthenticated(true);
//       } else {
//         setIsAuthenticated(false);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

//   const stopAllAudios = (currentAudioId) => {
//     const audios = document.querySelectorAll("audio");
//     audios.forEach((audio) => {
//       if (audio.id !== currentAudioId) {
//         audio.pause();
//         audio.currentTime = 0; // Reset the audio
//       }
//     });
//   };
//   function removeSubstring(text, substringToRemove, condition) {
//     return condition ? text.replace(substringToRemove, "") : text;
//   }
//   const handlePlay = () => {
//     stopAllAudios(`audio-${ayahIndex}`);
//     audioRefs.current[ayahIndex]
//       .play()
//       .catch((error) => console.error("Failed to play audio:", error));
//   };
//   console.log(audioRefs && audioRefs?.current?.duration);
//   console.log(ayah.number);
//   return (
//     <Center>
//       <Box key={ayahIndex} textAlign="center">
//         <Text fontSize=".7rem" color="red" textAlign="center">
//           Ayah Number: {ayah.number}
//         </Text>
//         <hr />
//         {ayah.number !== 1 ? (
//           <Text my={3} fontSize="4.5rem" textAlign="center">
//             {removeSubstring(
//               ayah.text,
//               "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
//               ayahIndex !== 1
//             )}
//           </Text>
//         ) : ayah.number === 1 ? (
//           <Text my={3} fontSize="2.5rem" textAlign="center">
//             {ayah.text}
//           </Text>
//         ) : null}
//         <List spacing={3}>
//           {state.selectedTranslations.map((translationIdentifier) => {
//             const translation =
//               translationSurahData[translationIdentifier]?.ayahs[ayahIndex];
//             return (
//               translation && (
//                 <ListItem key={translationIdentifier}>
//                   <VStack align="stretch">
//                     <Text fontSize="2xl" color="green.300">
//                       {translationIdentifier}
//                     </Text>
//                     <Text color="yellow.300">{translation.text}</Text>
//                   </VStack>
//                 </ListItem>
//               )
//             );
//           })}
//         </List>
//         <VStack>
//           <audio
//             controls
//             id={`audio-${ayahIndex}`}
//             key={ayah.audio}
//             ref={(ref) => (audioRefs.current[ayahIndex] = ref)}
//             onPlay={() => handlePlay(ayahIndex)}
//             onEnded={() => {
//               if (audioRefs.current[ayahIndex + 1]) {
//                 setCurrentAyah(ayahIndex + 1);
//                 audioRefs.current[ayahIndex + 1].play();
//               }
//             }}
//           >
//             <source src={ayah.audio} type="audio/mpeg" />
//             Your browser does not support the audio element.
//           </audio>

//           {isAuthenticated ? (
//             <Recorder
//               ayah={
//                 ayah.number !== 1
//                   ? {
//                       ...ayah,
//                       text: removeSubstring(
//                         ayah.text,
//                         "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
//                         ayahIndex !== 1
//                       ),
//                     }
//                   : ayah.number === 1
//                   ? { ...ayah } // If ayah.number is 1, no modification is needed
//                   : null // Handle other cases if necessary
//               }
//               selectedNumber={selectedNumber}
//               originalAudioDuration={audioRefs && audioRefs?.current?.duration}
//             />
//           ) : (
//             <Text>Please sign in to access the recorder.</Text>
//           )}
//           {isAuthenticated && (
//             <UserRecordings ayah={ayah} selectedNumber={selectedNumber} />
//           )}
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default memo(Ayah);

// import React, { useState, useEffect, memo } from "react";
// import { Box, Text, List, ListItem, VStack, Center } from "@chakra-ui/react";
// import Recorder from "../Recorder/Recorder";
// import UserRecordings from "../UserRecordings/UserRecordings";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const Ayah = ({
//   ayah,
//   ayahIndex,
//   state,
//   translationSurahData,
//   audioRefs,
//   setCurrentAyah,
//   selectedNumber,
// }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setIsAuthenticated(true);
//       } else {
//         setIsAuthenticated(false);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

//   const stopAllAudios = (currentAudioId) => {
//     const audios = document.querySelectorAll("audio");
//     audios.forEach((audio) => {
//       if (audio.id !== currentAudioId) {
//         audio.pause();
//         audio.currentTime = 0; // Reset the audio
//       }
//     });
//   };

//   function removeSubstring(text, substringToRemove, condition) {
//     return condition ? text.replace(substringToRemove, "") : text;
//   }

//   const handlePlay = () => {
//     stopAllAudios(`audio-${ayahIndex}`);
//     audioRefs.current[ayahIndex]
//       .play()
//       .catch((error) => console.error("Failed to play audio:", error));
//   };

//   return (
//     <Center>
//       <Box
//         key={ayahIndex}
//         textAlign="center"
//         borderRadius="md"
//         p={4}
//         // boxShadow="dark-lg"
//         // bg="gray.800"
//         // color="black"
//         mx="auto"
//         my={6}
//         maxW="800px"
//       >
//         <Text fontSize=".7rem" color="red" textAlign="center">
//           Ayah Number: {ayah.number}
//         </Text>
//         <hr style={{ borderColor: "gray.600" }} />
//         {ayah.number !== 1 ? (
//           <Text my={3} fontSize="4rem" textAlign="center">
//             {removeSubstring(
//               ayah.text,
//               "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
//               ayahIndex !== 1
//             )}
//           </Text>
//         ) : ayah.number === 1 ? (
//           <Text my={3} fontSize="3rem" textAlign="center">
//             {ayah.text}
//           </Text>
//         ) : null}
//         <List spacing={3}>
//           {state.selectedTranslations.map((translationIdentifier) => {
//             const translation =
//               translationSurahData[translationIdentifier]?.ayahs[ayahIndex];
//             return (
//               translation && (
//                 <ListItem key={translationIdentifier}>
//                   <VStack align="stretch">
//                     <Text
//                       color="red.500"
//                       style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
//                       fontSize="xl"
//                     >
//                       {translationIdentifier}
//                     </Text>
//                     <Text
//                       color="purple"
//                       style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
//                       fontSize="xl"
//                     >
//                       {translation.text}
//                     </Text>
//                   </VStack>
//                 </ListItem>
//               )
//             );
//           })}
//         </List>
//         <VStack spacing={4}>
//           <audio
//             controls
//             id={`audio-${ayahIndex}`}
//             key={ayah.audio}
//             ref={(ref) => (audioRefs.current[ayahIndex] = ref)}
//             onPlay={() => handlePlay(ayahIndex)}
//             onEnded={() => {
//               if (audioRefs.current[ayahIndex + 1]) {
//                 setCurrentAyah(ayahIndex + 1);
//                 audioRefs.current[ayahIndex + 1].play();
//               }
//             }}
//           >
//             <source src={ayah.audio} type="audio/mpeg" />
//             Your browser does not support the audio element.
//           </audio>

//           {isAuthenticated ? (
//             <Recorder
//               ayah={
//                 ayah.number !== 1
//                   ? {
//                       ...ayah,
//                       text: removeSubstring(
//                         ayah.text,
//                         "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
//                         ayahIndex !== 1
//                       ),
//                     }
//                   : ayah.number === 1
//                   ? { ...ayah }
//                   : null
//               }
//               selectedNumber={selectedNumber}
//               originalAudioDuration={audioRefs && audioRefs?.current?.duration}
//             />
//           ) : (
//             <Text>Please sign in to access the recorder.</Text>
//           )}
//           {isAuthenticated && (
//             <UserRecordings ayah={ayah} selectedNumber={selectedNumber} />
//           )}
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// export default memo(Ayah);

// import React, { useState, useEffect, memo } from "react";
// import { Box, Text, List, ListItem, VStack, Center } from "@chakra-ui/react";
// import Recorder from "../Recorder/Recorder";
// import UserRecordings from "../UserRecordings/UserRecordings";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const Ayah = ({
//   ayah,
//   ayahIndex,
//   state,
//   translationSurahData,
//   audioRefs,
//   setCurrentAyah,
//   selectedNumber,
// }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setIsAuthenticated(!!user);
//     });
//     return unsubscribe;
//   }, []);

//   const stopAllAudios = (currentAudioId) => {
//     const audios = document.querySelectorAll("audio");
//     audios.forEach((audio) => {
//       if (audio.id !== currentAudioId) {
//         audio.pause();
//         audio.currentTime = 0; // Reset the audio
//       }
//     });
//   };

//   const handlePlay = () => {
//     stopAllAudios(`audio-${ayahIndex}`);
//     audioRefs.current[ayahIndex]
//       .play()
//       .catch((error) => console.error("Failed to play audio:", error));
//   };

//   const shouldRemoveBismillah = ayah.number !== 1;

//   return (
//     <Center>
//       <Box
//         key={ayahIndex}
//         textAlign="center"
//         borderRadius="md"
//         p={4}
//         my={6}
//         maxW="800px"
//       >
//         <Text fontSize=".7rem" color="red" textAlign="center">
//           Ayah Number: {ayah.number}
//         </Text>
//         <hr style={{ borderColor: "gray.600" }} />
//         <Text
//           my={3}
//           fontSize={shouldRemoveBismillah ? "4rem" : "3rem"}
//           textAlign="center"
//         >
//           {shouldRemoveBismillah
//             ? ayah.text.replace("بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ", "")
//             : ayah.text}
//         </Text>
//         <List spacing={3}>
//           {state.selectedTranslations.map((translationIdentifier) => {
//             const translation =
//               translationSurahData[translationIdentifier]?.ayahs[ayahIndex];
//             return (
//               translation && (
//                 <ListItem key={translationIdentifier}>
//                   <VStack align="stretch">
//                     <Text
//                       color="red.500"
//                       style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
//                       fontSize="xl"
//                     >
//                       {translationIdentifier}
//                     </Text>
//                     <Text
//                       color="purple"
//                       style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
//                       fontSize="xl"
//                     >
//                       {translation.text}
//                     </Text>
//                   </VStack>
//                 </ListItem>
//               )
//             );
//           })}
//         </List>
//         <VStack spacing={4}>
//           <audio
//             controls
//             id={`audio-${ayahIndex}`}
//             key={ayah.audio}
//             ref={(ref) => (audioRefs.current[ayahIndex] = ref)}
//             onPlay={handlePlay}
//             onEnded={() => {
//               if (audioRefs.current[ayahIndex + 1]) {
//                 setCurrentAyah(ayahIndex + 1);
//                 audioRefs.current[ayahIndex + 1].play();
//               }
//             }}
//           >
//             <source src={ayah.audio} type="audio/mpeg" />
//             Your browser does not support the audio element.
//           </audio>

//           {isAuthenticated ? (
//             <Recorder
//               ayah={{
//                 ...ayah,
//                 text: shouldRemoveBismillah
//                   ? ayah.text.replace(
//                       "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
//                       ""
//                     )
//                   : ayah.text,
//               }}
//               selectedNumber={selectedNumber}
//               originalAudioDuration={audioRefs?.current?.duration}
//             />
//           ) : (
//             <Text>Please sign in to access the recorder.</Text>
//           )}
//           {isAuthenticated && (
//             <UserRecordings ayah={ayah} selectedNumber={selectedNumber} />
//           )}
//         </VStack>
//       </Box>
//     </Center>
//   );
// };

// // export default memo(Ayah);
// import React, { useState, useEffect, memo } from "react";
// import { Box, Text, List, ListItem, VStack, Center } from "@chakra-ui/react";
// import Recorder from "../Recorder/Recorder";
// import UserRecordings from "../UserRecordings/UserRecordings";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const Ayah = memo(
//   ({
//     ayah,
//     ayahIndex,
//     state,
//     translationSurahData,
//     audioRefs,
//     setCurrentAyah,
//     selectedNumber,
//   }) => {
//     const [isAuthenticated, setIsAuthenticated] = useState(false);

//     useEffect(() => {
//       const auth = getAuth();
//       const unsubscribe = onAuthStateChanged(auth, (user) => {
//         setIsAuthenticated(!!user);
//       });
//       return unsubscribe;
//     }, []);

//     const stopAllAudios = (currentAudioId) => {
//       const audios = document.querySelectorAll("audio");
//       audios.forEach((audio) => {
//         if (audio.id !== currentAudioId) {
//           audio.pause();
//           audio.currentTime = 0; // Reset the audio
//         }
//       });
//     };

//     const handlePlay = () => {
//       stopAllAudios(`audio-${ayahIndex}`);
//       audioRefs.current[ayahIndex]
//         .play()
//         .catch((error) => console.error("Failed to play audio:", error));
//     };

//     const shouldRemoveBismillah = ayah.number !== 1;

//     return (
//       <Center>
//         <Box
//           key={ayahIndex}
//           textAlign="center"
//           borderRadius="md"
//           p={4}
//           my={6}
//           maxW="800px"
//         >
//           <Text fontSize=".7rem" color="red" textAlign="center">
//             Ayah Number: {ayah.number}
//           </Text>
//           <hr style={{ borderColor: "gray.600" }} />
//           <Text
//             my={3}
//             fontSize={shouldRemoveBismillah ? "4rem" : "3rem"}
//             textAlign="center"
//           >
//             {shouldRemoveBismillah
//               ? ayah.text.replace("بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ", "")
//               : ayah.text}
//           </Text>
//           <List spacing={3}>
//             {state.selectedTranslations.map((translationIdentifier) => {
//               const translation =
//                 translationSurahData[translationIdentifier]?.ayahs[ayahIndex];
//               return (
//                 translation && (
//                   <ListItem key={translationIdentifier}>
//                     <VStack align="stretch">
//                       <Text
//                         color="red.500"
//                         style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
//                         fontSize="xl"
//                       >
//                         {translationIdentifier}
//                       </Text>
//                       <Text
//                         color="purple"
//                         style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
//                         fontSize="xl"
//                       >
//                         {translation.text}
//                       </Text>
//                     </VStack>
//                   </ListItem>
//                 )
//               );
//             })}
//           </List>
//           <VStack spacing={4}>
//             <audio
//               controls
//               id={`audio-${ayahIndex}`}
//               key={ayah.audio}
//               ref={(ref) => (audioRefs.current[ayahIndex] = ref)}
//               onPlay={handlePlay}
//               onEnded={() => {
//                 if (audioRefs.current[ayahIndex + 1]) {
//                   setCurrentAyah(ayahIndex + 1);
//                   audioRefs.current[ayahIndex + 1].play();
//                 }
//               }}
//             >
//               <source src={ayah.audio} type="audio/mpeg" />
//               Your browser does not support the audio element.
//             </audio>

//             {isAuthenticated ? (
//               <Recorder
//                 ayah={{
//                   ...ayah,
//                   text: shouldRemoveBismillah
//                     ? ayah.text.replace(
//                         "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
//                         ""
//                       )
//                     : ayah.text,
//                 }}
//                 selectedNumber={selectedNumber}
//                 originalAudioDuration={audioRefs?.current?.duration}
//               />
//             ) : (
//               <Text>Please sign in to access the recorder.</Text>
//             )}
//             {isAuthenticated && (
//               <UserRecordings ayah={ayah} selectedNumber={selectedNumber} />
//             )}
//           </VStack>
//         </Box>
//       </Center>
//     );
//   }
// );

// export default Ayah;
import React, { useState, useEffect, memo } from "react";
import { Box, Text, List, ListItem, VStack, Center } from "@chakra-ui/react";
import Recorder from "../Recorder/Recorder";
import UserRecordings from "../UserRecordings/UserRecordings";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Ayah = memo(
  ({
    ayah,
    ayahIndex,
    state,
    translationSurahData,
    audioRefs,
    setCurrentAyah,
    selectedNumber,
  }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setIsAuthenticated(!!user);
      });
      return unsubscribe;
    }, []);

    const stopAllAudios = (currentAudioId) => {
      const audios = document.querySelectorAll("audio");
      audios.forEach((audio) => {
        if (audio.id !== currentAudioId) {
          audio.pause();
          audio.currentTime = 0; // Reset the audio
        }
      });
    };

    const handlePlay = () => {
      stopAllAudios(`audio-${ayahIndex}`);
      audioRefs.current[ayahIndex]
        .play()
        .catch((error) => console.error("Failed to play audio:", error));
    };

    const shouldRemoveBismillah = ayah.number !== 1;

    return (
      <Center>
        <Box
          key={ayahIndex}
          textAlign="center"
          borderRadius="md"
          p={4}
          my={6}
          maxW="800px"
        >
          <Text fontSize="1rem" color="black" textAlign="center">
            Ayah Number: {ayah.number}
          </Text>
          <hr style={{ borderColor: "gray.600" }} />
          <Text
            my={3}
            fontSize={shouldRemoveBismillah ? "4rem" : "3rem"}
            textAlign="center"
          >
            {shouldRemoveBismillah
              ? ayah.text.replace("بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ", "")
              : ayah.text}
          </Text>
          <List spacing={3}>
            {state.selectedTranslations.map((translationIdentifier) => {
              const translation =
                translationSurahData[translationIdentifier]?.ayahs[ayahIndex];
              return (
                translation && (
                  <ListItem key={translationIdentifier}>
                    <VStack align="stretch">
                      <Text
                        color="red"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
                        fontSize="xl"
                      >
                        {translationIdentifier}
                      </Text>
                      <Text
                        color="purple"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
                        fontSize="3xl" // Adjusted font size for translation
                      >
                        {translation.text}
                      </Text>
                    </VStack>
                  </ListItem>
                )
              );
            })}
          </List>
          <VStack spacing={4}>
            <audio
              controls
              id={`audio-${ayahIndex}`}
              key={ayah.audio}
              ref={(ref) => (audioRefs.current[ayahIndex] = ref)}
              onPlay={handlePlay}
              onEnded={() => {
                if (audioRefs.current[ayahIndex + 1]) {
                  setCurrentAyah(ayahIndex + 1);
                  audioRefs.current[ayahIndex + 1].play();
                }
              }}
            >
              <source src={ayah.audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>

            {isAuthenticated ? (
              <Recorder
                singleTextAyah={ayah.text}
                ayah={{
                  ...ayah,
                  text: shouldRemoveBismillah
                    ? ayah.text.replace(
                        "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
                        ""
                      )
                    : ayah.text,
                }}
                selectedNumber={selectedNumber}
                originalAudioDuration={audioRefs?.current?.duration}
              />
            ) : (
              <Text>Please sign in to access the recorder.</Text>
            )}
            {isAuthenticated && (
              <UserRecordings ayah={ayah} selectedNumber={selectedNumber} />
            )}
          </VStack>
        </Box>
      </Center>
    );
  }
);

export default Ayah;
