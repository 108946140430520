// import React, { useState, useContext } from "react";
// import {
//   Box,
//   Button,
//   ChakraProvider,
//   extendTheme,
//   Link as ChakraLink,
//   Flex,
// } from "@chakra-ui/react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Link as RouterLink,
//   Routes,
// } from "react-router-dom";
// import SignUp from "./SignUp/SignUp";
// import Login from "./Login/Login";
// import QuranPlayer from "./QuranPlayer/QuranPlayer";
// import { AuthContext } from "./AuthContext";
// import { getAuth, signOut } from "firebase/auth";
// import firebaseApp from "./firebase";

// const lightTheme = extendTheme({
//   styles: {
//     global: {
//       body: {
//         bg: "gray.400",
//         color: "gray.900",
//         transition: "background-color 0.3s, color 0.3s",
//       },
//     },
//   },
// });

// const darkTheme = extendTheme({
//   styles: {
//     global: {
//       body: {
//         bg: "gray.900",
//         color: "gray.200",
//         transition: "background-color 0.3s, color 0.3s",
//       },
//     },
//   },
// });

// const App = () => {
//   const [theme, setTheme] = useState(lightTheme);
//   const { currentUser, setCurrentUser } = useContext(AuthContext);

//   const isLightMode = theme === lightTheme;

//   const toggleTheme = () => {
//     if (isLightMode) {
//       setTheme(darkTheme);
//     } else {
//       setTheme(lightTheme);
//     }
//   };

//   const handleLogout = () => {
//     const auth = getAuth(firebaseApp);
//     signOut(auth)
//       .then(() => {
//         console.log("Logout successful!");
//         setCurrentUser(null);
//       })
//       .catch((error) => {
//         console.error("Error during logout:", error);
//       });
//   };

//   return (
//     <ChakraProvider theme={theme}>
//       <Router>
//         <Flex
//           as="nav"
//           align="center"
//           justify="space-between"
//           wrap="wrap"
//           padding={6}
//           bg={isLightMode ? "gray.300" : "gray.800"}
//           color={isLightMode ? "black" : "white"}
//           transition="background-color 0.3s, color 0.3s"
//         >
//           <Box>
//             <ChakraLink as={RouterLink} to="/" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Quran Player
//               </Button>
//             </ChakraLink>
//             <Button
//               onClick={toggleTheme}
//               m={3}
//               size="lg"
//               backgroundColor="gold"
//               opacity="0.7"
//               variant="outline"
//               borderColor="gold"
//               color="black"
//               _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//             >
//               Toggle {isLightMode ? "Dark" : "Light"} Mode
//             </Button>
//           </Box>

//           <Box>
//             {currentUser ? (
//               <Button
//                 onClick={handleLogout}
//                 m={3}
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Logout
//               </Button>
//             ) : (
//               <>
//                 <ChakraLink as={RouterLink} to="/signup" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Sign Up
//                   </Button>
//                 </ChakraLink>
//                 <ChakraLink as={RouterLink} to="/login" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Login
//                   </Button>
//                 </ChakraLink>
//               </>
//             )}
//           </Box>
//         </Flex>
//         <Routes>
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/" element={<QuranPlayer />} />
//           <Route path="*" element={<QuranPlayer />} />
//         </Routes>
//       </Router>
//     </ChakraProvider>
//   );
// };

// export default App;

// import React, { useState, useContext } from "react";
// import {
//   Box,
//   Button,
//   ChakraProvider,
//   extendTheme,
//   Link as ChakraLink,
//   Flex,
// } from "@chakra-ui/react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Link as RouterLink,
//   Routes,
// } from "react-router-dom";
// import SignUp from "./SignUp/SignUp";
// import Login from "./Login/Login";
// import QuranPlayer from "./QuranPlayer/QuranPlayer";
// import Donate from "./Donate/Donate"; // Add the Donate component import
// import { AuthContext } from "./AuthContext";
// import { getAuth, signOut } from "firebase/auth";
// import firebaseApp from "./firebase";
// import "./styles.css";

// const themes = {
//   light: extendTheme({
//     styles: {
//       global: {
//         body: {
//           bg: "gray.500", // Lighter background
//           color: "gray.700", // Darker text color
//           transition: "background-color 0.3s, color 0.3s",
//           fontFamily: "p1-v1, sans-serif",
//           fontSize: "md", // Adjusted font size
//           fontWeight: "3000", // Slightly lighter font weight
//         },
//       },
//     },
//   }),
//   dark: extendTheme({
//     styles: {
//       global: {
//         body: {
//           bg: "gray.800", // Darker background
//           transition: "background-color 0.3s, color 0.3s",
//           fontFamily: "p1-v1, sans-serif",
//           fontSize: "md", // Adjusted font size
//           fontWeight: "3000", // Slightly lighter font weight
//         },
//       },
//     },
//   }),
// };

// const App = () => {
//   const [theme, setTheme] = useState(themes.dark);
//   const { currentUser, setCurrentUser } = useContext(AuthContext);

//   const isLightMode = theme === themes.light;

//   const toggleTheme = () => setTheme(isLightMode ? themes.dark : themes.light);

//   const handleLogout = () => {
//     const auth = getAuth(firebaseApp);
//     signOut(auth)
//       .then(() => {
//         // console.log("Logout successful!");
//         setCurrentUser(null);
//       })
//       .catch((error) => {
//         console.error("Error during logout:", error);
//       });
//   };

//   return (
//     <ChakraProvider theme={theme}>
//       <Router>
//         <Flex
//           as="nav"
//           align="center"
//           justify="space-between"
//           wrap="wrap"
//           padding={6}
//           bg={isLightMode ? "gray.600" : "gray.800"}
//           color={isLightMode ? "black" : "white"}
//           transition="background-color 0.3s, color 0.3s"
//         >
//           <Box>
//             <ChakraLink as={RouterLink} to="/" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Quran Player
//               </Button>
//             </ChakraLink>
//             <Button
//               onClick={toggleTheme}
//               m={3}
//               size="lg"
//               backgroundColor="gold"
//               opacity="0.7"
//               variant="outline"
//               borderColor="gold"
//               color="black"
//               _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//             >
//               Toggle {isLightMode ? "Dark" : "Light"} Mode
//             </Button>
//             <ChakraLink as={RouterLink} to="/donate" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Donate
//               </Button>
//             </ChakraLink>
//           </Box>

//           <Box>
//             {currentUser ? (
//               <Button
//                 onClick={handleLogout}
//                 m={3}
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Logout
//               </Button>
//             ) : (
//               <>
//                 <ChakraLink as={RouterLink} to="/signup" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Sign Up
//                   </Button>
//                 </ChakraLink>
//                 <ChakraLink as={RouterLink} to="/login" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Login
//                   </Button>
//                 </ChakraLink>
//               </>
//             )}
//           </Box>
//         </Flex>
//         <Routes>
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/" element={<QuranPlayer />} />
//           <Route path="/donate" element={<Donate />} /> // Define route for the
//           Donate component
//           <Route path="*" element={<QuranPlayer />} />
//         </Routes>
//       </Router>
//     </ChakraProvider>
//   );
// };

// export default App;

// import React, { useState, useContext } from "react";
// import {
//   Box,
//   Button,
//   ChakraProvider,
//   extendTheme,
//   Link as ChakraLink,
//   Flex,
// } from "@chakra-ui/react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Link as RouterLink,
//   Routes,
// } from "react-router-dom";
// import SignUp from "./SignUp/SignUp";
// import Login from "./Login/Login";
// import QuranPlayer from "./QuranPlayer/QuranPlayer";
// import Donate from "./Donate/Donate"; // Add the Donate component import
// import { AuthContext } from "./AuthContext";
// import { getAuth, signOut } from "firebase/auth";
// import firebaseApp from "./firebase";
// import "./styles.css";

// const themes = {
//   light: extendTheme({
//     styles: {
//       global: {
//         body: {
//           bg: "gray.500", // Lighter background
//           color: "gray.700", // Darker text color
//           transition: "background-color 0.3s, color 0.3s",
//           fontFamily: "p1-v1, sans-serif",
//           fontSize: "md", // Adjusted font size
//           fontWeight: "3000", // Slightly lighter font weight
//         },
//       },
//     },
//   }),
//   dark: extendTheme({
//     styles: {
//       global: {
//         body: {
//           bg: "gray.800", // Darker background
//           transition: "background-color 0.3s, color 0.3s",
//           fontFamily: "p1-v1, sans-serif",
//           fontSize: "md", // Adjusted font size
//           fontWeight: "3000", // Slightly lighter font weight
//         },
//       },
//     },
//   }),
// };

// const App = () => {
//   const [theme, setTheme] = useState(themes.dark);
//   const { currentUser, setCurrentUser } = useContext(AuthContext);

//   const isLightMode = theme === themes.light;

//   const toggleTheme = () => setTheme(isLightMode ? themes.dark : themes.light);

//   const handleLogout = () => {
//     const auth = getAuth(firebaseApp);
//     signOut(auth)
//       .then(() => {
//         setCurrentUser(null);
//       })
//       .catch((error) => {
//         console.error("Error during logout:", error);
//       });
//   };

//   return (
//     <ChakraProvider theme={theme}>
//       <Router>
//         <Flex
//           as="nav"
//           align="center"
//           justify="center"
//           wrap="wrap"
//           padding={6}
//           bg={isLightMode ? "gray.600" : "gray.800"}
//           color={isLightMode ? "black" : "white"}
//           transition="background-color 0.3s, color 0.3s"
//           position="sticky"
//           top="0"
//           zIndex="10"
//         >
//           <Box>
//             <ChakraLink as={RouterLink} to="/" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Quran Player
//               </Button>
//             </ChakraLink>
//             <Button
//               onClick={toggleTheme}
//               m={3}
//               size="lg"
//               backgroundColor="gold"
//               opacity="0.7"
//               variant="outline"
//               borderColor="gold"
//               color="black"
//               _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//             >
//               Toggle {isLightMode ? "Dark" : "Light"} Mode
//             </Button>
//             <ChakraLink as={RouterLink} to="/donate" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Donate
//               </Button>
//             </ChakraLink>
//           </Box>

//           <Box>
//             {currentUser ? (
//               <Button
//                 onClick={handleLogout}
//                 m={3}
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Logout
//               </Button>
//             ) : (
//               <>
//                 <ChakraLink as={RouterLink} to="/signup" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Sign Up
//                   </Button>
//                 </ChakraLink>
//                 <ChakraLink as={RouterLink} to="/login" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Login
//                   </Button>
//                 </ChakraLink>
//               </>
//             )}
//           </Box>
//         </Flex>
//         <Routes>
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/donate" element={<Donate />} />
//           <Route path="/" element={<QuranPlayer />} />
//           <Route path="*" element={<QuranPlayer />} />
//         </Routes>
//       </Router>
//     </ChakraProvider>
//   );
// };

// export default App;

// import React, { useState, useContext } from "react";
// import {
//   Box,
//   Button,
//   ChakraProvider,
//   extendTheme,
//   Link as ChakraLink,
//   Flex,
// } from "@chakra-ui/react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Link as RouterLink,
//   Routes,
// } from "react-router-dom";
// import SignUp from "./SignUp/SignUp";
// import Login from "./Login/Login";
// import QuranPlayer from "./QuranPlayer/QuranPlayer";
// import Donate from "./Donate/Donate"; // Add the Donate component import
// import ShortMaker from "./ShortMaker/ShortMaker"; // Add the ShortMaker component import
// import { AuthContext } from "./AuthContext";
// import { getAuth, signOut } from "firebase/auth";
// import firebaseApp from "./firebase";
// import "./styles.css";

// const themes = {
//   light: extendTheme({
//     styles: {
//       global: {
//         body: {
//           bg: "gray.500", // Lighter background
//           color: "gray.700", // Darker text color
//           transition: "background-color 0.3s, color 0.3s",
//           fontFamily: "p1-v1, sans-serif",
//           fontSize: "md", // Adjusted font size
//           fontWeight: "3000", // Slightly lighter font weight
//         },
//       },
//     },
//   }),
//   dark: extendTheme({
//     styles: {
//       global: {
//         body: {
//           bg: "gray.800", // Darker background
//           transition: "background-color 0.3s, color 0.3s",
//           fontFamily: "p1-v1, sans-serif",
//           fontSize: "md", // Adjusted font size
//           fontWeight: "3000", // Slightly lighter font weight
//         },
//       },
//     },
//   }),
// };

// const App = () => {
//   const [theme, setTheme] = useState(themes.dark);
//   const { currentUser, setCurrentUser } = useContext(AuthContext);

//   const isLightMode = theme === themes.light;

//   const toggleTheme = () => setTheme(isLightMode ? themes.dark : themes.light);

//   const handleLogout = () => {
//     const auth = getAuth(firebaseApp);
//     signOut(auth)
//       .then(() => {
//         setCurrentUser(null);
//       })
//       .catch((error) => {
//         console.error("Error during logout:", error);
//       });
//   };

//   return (
//     <ChakraProvider theme={theme}>
//       <Router>
//         <Flex
//           as="nav"
//           align="center"
//           justify="center"
//           wrap="wrap"
//           padding={6}
//           bg={isLightMode ? "gray.600" : "gray.800"}
//           color={isLightMode ? "black" : "white"}
//           transition="background-color 0.3s, color 0.3s"
//           position="sticky"
//           top="0"
//           zIndex="10"
//         >
//           <Box>
//             <ChakraLink as={RouterLink} to="/" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Quran Player
//               </Button>
//             </ChakraLink>
//             <Button
//               onClick={toggleTheme}
//               m={3}
//               size="lg"
//               backgroundColor="gold"
//               opacity="0.7"
//               variant="outline"
//               borderColor="gold"
//               color="black"
//               _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//             >
//               Toggle {isLightMode ? "Dark" : "Light"} Mode
//             </Button>
//             <ChakraLink as={RouterLink} to="/donate" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Donate
//               </Button>
//             </ChakraLink>
//             <ChakraLink as={RouterLink} to="/short-maker" m={3}>
//               <Button
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Short Maker
//               </Button>
//             </ChakraLink>
//           </Box>

//           <Box>
//             {currentUser ? (
//               <Button
//                 onClick={handleLogout}
//                 m={3}
//                 size="lg"
//                 backgroundColor="gold"
//                 opacity="0.7"
//                 variant="outline"
//                 borderColor="gold"
//                 color="black"
//                 _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//               >
//                 Logout
//               </Button>
//             ) : (
//               <>
//                 <ChakraLink as={RouterLink} to="/signup" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Sign Up
//                   </Button>
//                 </ChakraLink>
//                 <ChakraLink as={RouterLink} to="/login" m={3}>
//                   <Button
//                     size="lg"
//                     backgroundColor="gold"
//                     opacity="0.7"
//                     variant="outline"
//                     borderColor="gold"
//                     color="black"
//                     _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
//                   >
//                     Login
//                   </Button>
//                 </ChakraLink>
//               </>
//             )}
//           </Box>
//         </Flex>
//         <Routes>
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/donate" element={<Donate />} />
//           <Route path="/short-maker" element={<ShortMaker />} /> // Add route
//           for ShortMaker component
//           <Route path="/" element={<QuranPlayer />} />
//           <Route path="*" element={<QuranPlayer />} />
//         </Routes>
//       </Router>
//     </ChakraProvider>
//   );
// };

// export default App;
import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  extendTheme,
  Link as ChakraLink,
  Flex,
} from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Routes,
} from "react-router-dom";
import SignUp from "./SignUp/SignUp";
import Login from "./Login/Login";
import QuranPlayer from "./QuranPlayer/QuranPlayer";
import Donate from "./Donate/Donate";
import ShortMaker from "./ShortMaker/ShortMaker";
import VideoMaker from "./VideoMaker/VideoMaker";
import { AuthContext } from "./AuthContext";
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from "./firebase";
import "./styles.css";

const themes = {
  light: extendTheme({
    styles: {
      global: {
        body: {
          bg: "gray.500",
          color: "gray.700",
          transition: "background-color 0.3s, color 0.3s",
          fontFamily: "p1-v1, sans-serif",
          fontSize: "md",
          fontWeight: "3000",
        },
      },
    },
  }),
  dark: extendTheme({
    styles: {
      global: {
        body: {
          bg: "gray.800",
          transition: "background-color 0.3s, color 0.3s",
          fontFamily: "p1-v1, sans-serif",
          fontSize: "md",
          fontWeight: "3000",
        },
      },
    },
  }),
};

const App = () => {
  const [theme, setTheme] = useState(themes.dark);
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const isLightMode = theme === themes.light;

  const toggleTheme = () => setTheme(isLightMode ? themes.dark : themes.light);

  const handleLogout = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth)
      .then(() => {
        setCurrentUser(null);
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  const isAdmin = currentUser && currentUser.email === "fastmangood@gmail.com";

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Flex
          as="nav"
          align="center"
          justify="center"
          wrap="wrap"
          padding={6}
          bg={isLightMode ? "gray.600" : "gray.800"}
          color={isLightMode ? "black" : "white"}
          transition="background-color 0.3s, color 0.3s"
          position="sticky"
          top="0"
          zIndex="10"
        >
          <Box>
            <ChakraLink as={RouterLink} to="/" m={3}>
              <Button
                size="lg"
                backgroundColor="gold"
                opacity="0.7"
                variant="outline"
                borderColor="gold"
                color="black"
                _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
              >
                Quran Player
              </Button>
            </ChakraLink>
            <Button
              onClick={toggleTheme}
              m={3}
              size="lg"
              backgroundColor="gold"
              opacity="0.7"
              variant="outline"
              borderColor="gold"
              color="black"
              _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
            >
              Toggle {isLightMode ? "Dark" : "Light"} Mode
            </Button>
            <ChakraLink as={RouterLink} to="/donate" m={3}>
              <Button
                size="lg"
                backgroundColor="gold"
                opacity="0.7"
                variant="outline"
                borderColor="gold"
                color="black"
                _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
              >
                Donate
              </Button>
            </ChakraLink>
            {isAdmin && (
              <ChakraLink as={RouterLink} to="/short-maker" m={3}>
                <Button
                  size="lg"
                  backgroundColor="gold"
                  opacity="0.7"
                  variant="outline"
                  borderColor="gold"
                  color="black"
                  _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
                >
                  Short Maker
                </Button>
              </ChakraLink>
            )}
          </Box>
          {isAdmin && (
            <ChakraLink as={RouterLink} to="/videos-maker" m={3}>
              <Button
                size="lg"
                backgroundColor="gold"
                opacity="0.7"
                variant="outline"
                borderColor="gold"
                color="black"
                _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
              >
                Video Maker
              </Button>
            </ChakraLink>
          )}
          <Box>
            {currentUser ? (
              <Button
                onClick={handleLogout}
                m={3}
                size="lg"
                backgroundColor="gold"
                opacity="0.7"
                variant="outline"
                borderColor="gold"
                color="black"
                _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
              >
                Logout
              </Button>
            ) : (
              <>
                <ChakraLink as={RouterLink} to="/signup" m={3}>
                  <Button
                    size="lg"
                    backgroundColor="gold"
                    opacity="0.7"
                    variant="outline"
                    borderColor="gold"
                    color="black"
                    _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
                  >
                    Sign Up
                  </Button>
                </ChakraLink>
                <ChakraLink as={RouterLink} to="/login" m={3}>
                  <Button
                    size="lg"
                    backgroundColor="gold"
                    opacity="0.7"
                    variant="outline"
                    borderColor="gold"
                    color="black"
                    _hover={{ backgroundColor: "goldenrod", opacity: "0.8" }}
                  >
                    Login
                  </Button>
                </ChakraLink>
              </>
            )}
          </Box>
        </Flex>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/donate" element={<Donate />} />
          {isAdmin && <Route path="/short-maker" element={<ShortMaker />} />}
          {isAdmin && <Route path="/videos-maker" element={<VideoMaker />} />}
          <Route path="/" element={<QuranPlayer />} />
          <Route path="*" element={<QuranPlayer />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
